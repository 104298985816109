<template>
  <iRow
    :width="width"
    class="text-edit-container"
    vertical-align="middle"
    wrap="nowrap"
  >
    <iText
      :ref="uniqueId"
      :contenteditable="isEditing"
      :variant="!isEditing && !modelValue ? 'subtle' : variant"
      :font-size="fontSize"
      :wrap="wrap"
      :width="width"
      :font-weight="fontWeight"
      :ellipsis="ellipsis"
      autofocus
      class="text-edit"
      @input="handleInput"
      @blur="save"
      @click="enableEdit"
      @keydown.enter="save"
      @keydown.esc="disableEdit"
    >
      {{ modelValue || placeholder }}
    </iText>
    <iIcon
      :class="{
        'edit-icon': true,
        'show-on-hover': showIconOnHover,
        'edit-icon-show': isEditing,
      }"
      icon="pencil"
    />
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";
import { uniqueId } from "lodash";
import { themeStructure } from "@/constants/style-guide-constants";

export default {
  name: "iTextEditInline",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error", "success", "branded"],
    },
    fontSize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", ...themeStructure.size.font],
    },
    wrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: [Enum, Number],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    fontWeight: {
      type: Enum,
      required: false,
      default: "default",
      options: ["default", ...themeStructure.font.weight],
    },
    ellipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Enter a value",
    },
    modelValue: {
      type: String,
      required: true,
    },
    showIconOnEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    showIconOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideBorderOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 1024,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isEditing: false,
      uniqueId: uniqueId(),
      previousValue: this.modelValue,
    };
  },
  computed: {
    myBorderWidth() {
      return this.$getStyles["borderWidth"];
    },
    myBorderColor() {
      return this.isEditing ? this.$getStyles["borderColor"] : "transparent";
    },
    myHoverBorderColor() {
      return this.hideBorderOnHover && !this.isEditing ? "transparent" : this.$getStyles["borderColor"];
    },
    myBorderRadius() {
      return this.isEditing ? this.$getStyles["borderRadius"] : "0";
    },
    myShowIconOnHover() {
      return this.showIconOnHover ? "visible" : "hidden";
    },
  },
  watch: {
    modelValue() {
      this.previousValue = this.modelValue;
      this.$refs[this.uniqueId].$el.innerText = this.modelValue;
    },
  },
  methods: {
    setValue(value) {
      this.$nextTick(() => {
        this.$refs[this.uniqueId].$el.innerText = value;
      });
    },
    enableEdit() {
      if (!this.isEditing) {
        this.isEditing = true;
        this.$nextTick(() => {
          if (this.$refs[this.uniqueId].$el.innerText === this.placeholder) {
            this.$refs[this.uniqueId].$el.innerText = "";
          }
          this.$refs[this.uniqueId].$el.focus();
          this.$refs[this.uniqueId].$el.click();
        });
      }
    },
    disableEdit() {
      this.isEditing = false;
    },
    setCursorToEnd() {
      const span = this.$refs[this.uniqueId].$el;
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(span);
      range.collapse(false); // Collapse the range to the end
      selection.removeAllRanges();
      selection.addRange(range);
    },
    handleInput() {
      const element = this.$refs[this.uniqueId].$el;
      if (element.innerText.length > this.maxLength) {
        element.innerText = this.previousValue;
        this.setCursorToEnd();
        return;
      }
      this.previousValue = element.innerText;
    },
    save() {
      const value = this.$refs[this.uniqueId].$el.innerText.trim();
      if (value === this.placeholder || !value) {
        this.$emit("update:modelValue", "");
        this.$refs[this.uniqueId].$el.innerText = this.placeholder;
      } else {
        this.$emit("update:modelValue", value);
      }
      this.isEditing = false;
    },
  },
  styleGuide: () => ({
    borderWidth: { "size.border": "thin" },
    borderColor: { "color.border": "dark" },
    borderRadius: { "size.borderRadius": "small" },
    padding: { "size.spacing": "extraSmall" },
  }),
};
</script>

<style lang="scss" scoped>

.text-edit-container {
  &:hover {
    cursor: text;
    .edit-icon {
      visibility: v-bind(myShowIconOnHover);
    }
  }
}

.text-edit {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  bottom: v-bind("$getStyles.padding");
  display: inline-block;
  border-radius: v-bind(myBorderRadius);
  outline: v-bind(myBorderWidth) solid v-bind(myBorderColor);
  outline-offset: v-bind('$getStyles.padding');
}

.edit-icon {
  position: relative;
  bottom: v-bind("$getStyles.padding");
  visibility: hidden;

  &-show {
    visibility: visible;
  }
}

.text-edit:hover {
  border-radius: v-bind("$getStyles.borderRadius");
  outline: v-bind("$getStyles.borderWidth") solid v-bind(myHoverBorderColor);
  outline-offset: v-bind('$getStyles.padding');
}
</style>
