<template>
  <iSubHeading>Heading</iSubHeading>
  <StoryBook
    :components="components"
    :prop-options="propOptions"
  />
</template>

<script>
import StoryBook from "@/components/storybook/StoryBook";
export default {
  name: "HeadingDocs",
  components: { StoryBook },
  data() {
    return {
      components: ["iHeading"],
      propOptions: {
      },
    };
  },
};
</script>
