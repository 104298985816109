<template>
  <div :class="`i-overlay--container`" @mouseover="hoverOver = true" @mouseleave="hoverOver = false">
    <slot />
    <Transition :name="`i-overlay--${animation}`">
      <div v-if="displayOverlay" :class="['i-overlay', animation]">
        <slot name="overlay" />
      </div>
    </Transition>
  </div>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iOverlay",
  props: {
    height: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "full"],
      default: "full",
    },
    width: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "full"],
      default: "full",
    },
    zIndexOverlay: {
      type: Number,
      required: false,
      default: 1,
    },
    showOverlayTrigger: {
      type: Enum,
      required: false,
      default: "always",
      options: ["hover", "always", "none"],
    },
    animation: {
      type: Enum,
      required: false,
      default: "fade",
      options: ["fade"],
    },
    transitionDuration: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  emits: ["mouseover", "mouseleave"],
  data() {
    return {
      hoverOver: false,
    };
  },
  computed: {
    myHeight() {
      if (this.height === "auto") {
        return "auto";
      }
      if (this.height === "full") {
        return "100%";
      }
      return `${this.height}px`;
    },
    myWidth() {
      if (this.width == "auto") {
        return "auto";
      }
      if (this.width === "full") {
        return "100%";
      }
      return `${this.width}px`;
    },
    myTransitionDuration() {
      return `${this.transitionDuration}ms`;
    },
    displayOverlay() {
      // only display if there is an overlay in the slot
      // if showOverlayTrigger is "always" then always show the overlay
      // if showOverlayTrigger is "none" then never show the overlay
      // if showOverlayTrigger is "hover" then only show the overlay if hoverOver is also true
      if (!this.$slots.overlay) {
        return false;
      }
      switch (this.showOverlayTrigger) {
        case "always": return true;
        case "hover": return this.hoverOver;
        default: return false;
      }
    },
  },
  styleGuide: () => ({
    overlayFontFamily: { "font.typeface": "standard" },
    overlayFontSize: { "size.font": "large" },
    overlayFontColor: { "color.font": "standard" },
    borderRadius: { "size.borderRadius": "small" },
  }),
  methods: {
    mouseOver(e) {
      this.hoverOver = true;
      this.$emit("mouseover", e);
    },
    mouseLeave(e) {
      this.hoverOver = false;
      this.$emit("mouseleave", e);
    },
  },
};
</script>

<style scoped lang="scss">
.i-overlay {
  position: absolute;
  text-align: center;
  color: v-bind('$getStyles.overlayFontColor');
  font-size: v-bind('$getStyles.overlayFontSize');
  font-family: v-bind('$getStyles.overlayFontFamily');
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:v-bind(zIndexOverlay);
  display: flex;

  &--container {
    width: v-bind("myWidth");
    height: v-bind("myHeight");
    position: relative;
  }

  &--fade{
    &-enter-from, &-leave-to{
      opacity: 0;
    }
    &-enter-active,
    &-leave-active {
      transition: v-bind(myTransitionDuration) ease-in-out all;
    }
  }
}
</style>
