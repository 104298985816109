
export default class SocialShare {
  constructor(postUrl, postTitle, postImage) {
    this.postUrl = postUrl;
    this.postTitle = postTitle;
    this.postImage = postImage;
  }

  shareOnFacebook() {
    const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.postUrl)}`;
    window.open(url, "_blank");
  }

  shareOnTwitter() {
    const url = `https://twitter.com/share?url=${encodeURIComponent(this.postUrl)}&text=${encodeURIComponent(this.postTitle)}`;
    window.open(url, "_blank");
  }

  shareOnPinterest() {
    /* eslint max-len: ["error", { "code": 196 }]*/
    const url = `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(this.postUrl)}&media=${encodeURIComponent(this.postImage)}&description=${encodeURIComponent(this.postTitle)}`;
    window.open(url, "_blank");
  }

  shareViaEmail() {
    /* eslint-disable-next-line vue/max-len */
    window.location.href = `mailto:?subject=${encodeURIComponent(this.postTitle)}&body=${encodeURIComponent(this.postUrl)}`;
  }
}
