<template>
  <iColumn :height="height" wrap="nowrap">
    <slot name="above-editor" />
    <component
      :is="editorVariant"
      ref="editor"
      :model-value="modelValue"
      :upload-post-image="uploadPostImage"
      :width="width"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @enter="$emit('enter', $event)"
      @update:model-value="$emit('update:modelValue', $event)"
    />
    <slot name="below-editor" />
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
import DefaultEditor from "@/components/editors/DefaultEditor";
import MediumEditor from "@/components/editors/MediumEditor";
import SlackEditor from "@/components/editors/SlackEditor";

export default {
  name: "iEditor",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "default",
      options: ["default", "legacy", "medium", "slack"],
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: [Enum, Number],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    height: {
      type: [Enum, Number],
      required: false,
      default: "hug",
      options: ["fill", "hug", "auto"],
    },
    uploadPostImage: {
      type: Function,
      required: false,
      default: () => {},
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:modelValue", "input", "change", "enter"],
  computed: {
    editorInstance() {
      return this.$refs?.editor?.editor;
    },
    editorRef() {
      return this.$refs?.editor;
    },
    editorVariant() {
      switch (this.variant) {
        case "default":
          return DefaultEditor;
        case "medium":
          return MediumEditor;
        case "slack":
          return SlackEditor;
        default:
          return DefaultEditor;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
