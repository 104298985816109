<template>
  <div :class="`menu ${orientation}`">
    <iMenuItem
      v-for="(item, index) in menuItems"
      :ref="`menuItem-${index}`"
      :key="index"
      :name="item.name"
      :label="item.label"
      :action-value="item.actionValue"
      :action-type="item.actionType"
      :icon="item.icon"
      :icon-position="item.iconPosition"
      :always-display-sub-item="item.alwaysDisplaySubItem"
      :sub-items="item.subItems"
      :active-item="activeItem"
      :is-active="isActive(item.name)"
      :style-overrides="menuItemStyleOverrides"
      :menu-item-style-overrides="menuItemStyleOverrides"
      :menu-style-overrides="menuStyleOverrides"
      @click="(value) => $emit('click', value)"
    />
  </div>
</template>

<script>
import Enum from "@/data-types/enum";
import iMenuItem from "@/components/widgets/SubComponents/iMenuItem";
import { ORIENTATION_HORIZONTAL, ORIENTATION_VERTICAL } from "@/constants/menu-constants";

export default {
  name: "iMenu",
  components: { iMenuItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String,
      required: false,
      default: ORIENTATION_HORIZONTAL,
      validator(value) {
        return [ORIENTATION_HORIZONTAL, ORIENTATION_VERTICAL].includes(value);
      },
    },
    activeItem: {
      type: String,
      required: false,
      default: "",
    },
    textAlign: {
      type: Enum,
      required: false,
      default: "start",
      options: ["start", "end", "center", "justify"],
    },
    isCollapsable: {
      type: Boolean,
      default: false,
      required: false,
    },
    collapseThreshold: {
      type: Number,
      default: 0,
      required: false,
    },
    hideCollapsedItems: {
      type: Boolean,
      default: false,
      required: false,
    },
    menuItemStyleOverrides: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    menuStyleOverrides: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["click", "collapsed"],
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "standard" },
    fontColor: { "color.font": "standard" },
    fontWeight: { "font.weight": "standard" },
    activeLinkFontColor: { "color.font": "standard" },
    activeFontWeight: { "font.weight": "standard" },
    paddingTop: { "size.spacing": "small" },
    paddingBottom: { "size.spacing": "small" },
    paddingLeft: { "size.spacing": "standard" },
    paddingRight: { "size.spacing": "standard" },
  }),
  data() {
    return {
      parentContainerWidth: 9999,
      visibleMenuElements: [],
    };
  },
  computed: {
    isActive() {
      return name => name === this.activeItem;
    },
    menuContainerWidth() {
      return this.getWidthOfMenuItems();
    },
    numberOfVisibleItems() {
      let numItemsToShow = this.items.length;
      let adjustedWidth = 0;
      while (numItemsToShow && this.parentContainerWidth < this.menuContainerWidth - adjustedWidth) {
        adjustedWidth += this.menuItemWidth(numItemsToShow - 1);
        numItemsToShow--;
      }
      this.$emit("collapsed", this.items.slice(numItemsToShow));
      return numItemsToShow;
    },
    menuItems() {
      if (!this.isCollapsable || this.orientation === "vertical") {
        return this.items;
      }
      return this.getVisibleMenu;
    },
    getVisibleMenu() {
      let numberOfVisibleItems = this.numberOfVisibleItems;
      if (this.hideCollapsedItems) {
        return this.items.slice(0, numberOfVisibleItems);
      }
      return this.items;
    },
  },
  watch: {
    collapseThreshold() {
      this.initializeMenu();
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.initializeMenu();
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.$nextTick(() => {
        this.parentContainerWidth = this.$parent.$el.clientWidth;
      });
    },
    initializeMenu() {
      this.$nextTick(() => {
        this.parentContainerWidth = this.$parent.$el.clientWidth - this.collapseThreshold;
        this.visibleMenuElements = Object.values(this.$refs).map(element => element[0].$el.clientWidth);
      });
    },
    menuItemWidth(index) {
      return this.visibleMenuElements[index];
    },
    getWidthOfMenuItems() {
      return this.visibleMenuElements.reduce((totalWidth, element) => {
        return totalWidth + element;
      }, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  text-align: v-bind("textAlign");
  overflow: hidden;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}
.vertical {
    :deep(a) {
        padding-bottom: v-bind('$getStyles.paddingBottom');
        padding-top: v-bind('$getStyles.paddingTop');
        padding-left: v-bind('$getStyles.paddingLeft');
        padding-right: v-bind('$getStyles.paddingRight');
    }
}
.horizontal {
    :deep(a) {
        padding-bottom: v-bind('$getStyles.paddingBottom');
        padding-top: v-bind('$getStyles.paddingTop');
        padding-left: v-bind('$getStyles.paddingLeft');
        padding-right: v-bind('$getStyles.paddingRight');
    }
}
</style>
