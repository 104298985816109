import { defineStore } from "pinia";

const BREAKPOINTS = {
  mobile: 768,
  tablet: 1365,
  desktop: 1366,
};
export const useWindowStore = defineStore("window-store", {
  state: () => ({
    $event: null,
    $vssWidth: null,
    $vssHeight: null,
    $vssMobile: false,
    $vssTablet: false,
    $vssDesktop: false,
  }),
  getters: {
    vssEvent() {
      return this.$event;
    },
    windowWidth() {
      return this.$vssWidth || this.$getScreenWidth();
    },
    windowHeight() {
      return this.$vssHeight || this.$getScreenHeight();
    },
  },
  actions: {
    $getScreenWidth() {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },
    $getScreenHeight() {
      return (
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      );
    },
    handleResize(event) {
      this.$event = event ?? this.$event;
      this.$vssWidth = this.$getScreenWidth();
      this.$vssHeight = this.$getScreenHeight();
      this.$vssMobile = this.$getScreenWidth() < BREAKPOINTS.mobile;
      this.$vssTablet = this.$getScreenWidth() > BREAKPOINTS.mobile && this.$getScreenWidth() <= BREAKPOINTS.tablet;
      this.$vssDesktop = this.$getScreenWidth() >= BREAKPOINTS.desktop;
    },
    init() {
      this.remove();
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    remove() {
      window.removeEventListener("resize", this.handleResize);
    },
  },
});
