import { Extension } from "@tiptap/core";

export const ImageResize = Extension.create({
  name: "imageResize",
  addCommands() {
    return {
      setImageWidth: (width, node, nodePos) => ({ tr, dispatch }) => {
        const $pos = tr.doc.resolve(nodePos);

        let targetPos = nodePos;
        let targetDepth = $pos.depth;

        // If the node is nested, we need to move its parent
        if (targetDepth > 0) {
          targetPos = $pos.before(targetDepth);
        }

        if (node && node.type.name === "CustomImage") {
          if (dispatch) {
            tr.setNodeMarkup(targetPos, null, {
              ...node.attrs,
              width,
            });
            dispatch(tr);
          }
          return true;
        }
        return false;
      },
    };
  },
});
