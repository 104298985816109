<template>
  <!-- This shallow copy is deliberately done to prevent using the svg reactively which is a Vue code smell-->
  <!-- If you can come up with a better way to make this work go for it -->
  <component
    :is="{...iconData}"
    v-if="iconData"
    :class="iconFullClass"
  />
</template>

<script>
import loadedIcons from "@/icons";

export default {
  name: "iUnThemedIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    disableHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "1em",
    },
    height: {
      type: String,
      required: false,
      default: "1em",
    },
  },
  data() {
    return {
      iconData: null,
    };
  },
  computed: {
    myWidth() {
      return this.width;
    },
    myHeight() {
      return this.height;
    },
    iconFullClass() {
      let hover = "";

      if (!this.disableHover) {
        hover = "i-icon--hover";
      }

      return `m-icon ${hover}`;
    },
  },
  watch: {
    icon: function () {
      this.loadFile();
    },
  },
  created() {
    this.loadFile();
  },
  methods: {
    async loadFile() {
      let icons = await loadedIcons;
      if (!icons[this.icon]) {
        throw new Error(`Icon [${this.icon}] could not be found`);
      }
      this.iconData = icons[this.icon];
    },
  },
};
</script>


<style scoped lang="scss">
.m-icon {
  width: v-bind(myWidth);
  height: v-bind(myHeight);
  &--hover:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
