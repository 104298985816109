<template>
  <iColumn :width="width" class="i-autocomplete" gap="extraSmall">
    <iTextInput
      :model-value="searchQuery"
      :placeholder="placeholder"
      :label="label"
      type="text"
      width="fill"
      :is-loading="loading"
      :predictive-text="predictiveText"
      :right-icon="searchQuery ? 'close' : ''"
      @blur="onBlur"
      @focus="showDropdown = showInitialResultsOnClick"
      @update:predictive-text="handlePredictiveText"
      @input="updateValue($event.target.value)"
      @keydown="onKeyDown"
      @click-icon-right.stop="clearValue"
    />

    <ul v-if="showDropdown" class="dropdown">
      <template v-if="(hasCreationHandler && creationPosition === 'top') || $slots['before-list']">
        <li @mousedown.prevent="emitCreate">
          <slot name="before-list">
            <iRow align="between" vertical-align="middle" wrap="nowrap">
              <iText>+ Create <span v-if="searchQuery">"{{ searchQuery }}"</span><span v-else>New Item</span></iText>
            </iRow>
          </slot>
        </li>
      </template>
      <template v-if="limitedFilteredItems.length > 0">
        <li
          v-for="(item, index) in limitedFilteredItems"
          :key="index"
          :class="{active: index === activeIndex}"
          @mouseenter="activeIndex = index"
          @mousedown.prevent="selectItem(item)"
        >
          <iRow align="between" vertical-align="middle" wrap="nowrap">
            <slot :item="item" :name="`item(${itemsAreObjects ? item[itemValueKey] : item})`">
              <!--eslint-disable-next-line-->
              <iText v-html="highlightMatch(itemsAreObjects ? item[itemLabelKey] : item)"/>
            </slot>
          </iRow>
        </li>
      </template>
      <slot v-else name="no-results">
        <li class="no-results">
          <span>No results found</span>
        </li>
      </slot>

      <template v-if="(hasCreationHandler && creationPosition === 'bottom') || $slots['after-list']">
        <li @mousedown.prevent="emitCreate">
          <slot name="after-list">
            <iRow align="between" vertical-align="middle" wrap="nowrap">
              <iText>+ Create <span v-if="searchQuery">"{{ searchQuery }}"</span><span v-else>New Item</span></iText>
            </iRow>
          </slot>
        </li>
      </template>
    </ul>
  </iColumn>
</template>

<script>
import { debounce } from "lodash";
import Enum from "@/data-types/enum";
import PropValidationError from "@/errors/prop-validation-error";

export default {
  name: "iAsyncAutoComplete",
  props: {
    label: {
      type: String,
      default: "",
      required: false,
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    modelValue: {
      type: [String, Number],
      default: "",
      required: true,
    },
    placeholder: {
      type: String,
      default: "Type to search...",
      required: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    maxResults: {
      type: Number,
      default: Infinity,
      required: false,
    },
    showInitialResultsOnClick: {
      type: Boolean,
      default: true,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    itemValueKey: {
      type: String,
      default: "value",
      required: false,
    },
    itemLabelKey: {
      type: String,
      default: "label",
      required: false,
    },
    debounceTime: {
      type: Number,
      default: 300,
      required: false,
    },
    creationPosition: {
      type: Enum,
      default: "bottom",
      required: false,
      options: ["top", "bottom"],
    },
  },
  emits: ["update:modelValue", "itemSelected", "search"],
  data() {
    return {
      searchQuery: "",
      showDropdown: false,
      activeIndex: -1,
    };
  },
  computed: {
    hasCreationHandler() {
      return !!this.$attrs["onCreateItem"];
    },
    itemsAreObjects() {
      let hasObjects = false;
      let hasStrings = false;

      if (!this.items.length) {
        return true;
      }

      for (let i = 0; i < this.items.length; i++) {
        if (typeof this.items[i] === "object") {
          hasObjects = true;
        } else if (typeof this.items[i] === "string") {
          hasStrings = true;
        }
      }

      if (hasObjects && !hasStrings) {
        return true;
      } else if (!hasObjects && hasStrings) {
        return false;
      } else {
        throw new PropValidationError("Items must be all objects or all strings");
      }
    },
    limitedFilteredItems() {
      return this.items.slice(0, this.maxResults);
    },
    debouncedEmitSearch() {
      if (this.debounceTime === 0) {
        return this.emitSearch;
      }
      return debounce(this.emitSearch, this.debounceTime);
    },
    predictiveText() {
      if (!this.searchQuery) {
        return "";
      }

      let prediction = "";
      if (this.itemsAreObjects) {
        prediction =
          this.items.find(item => item[this.itemLabelKey].startsWith(this.searchQuery))?.[this.itemLabelKey] ||
          "";
      } else {
        prediction = this.items.filter(item => item.startsWith(this.searchQuery))?.[0] || "";
      }

      return prediction === this.searchQuery ? "" : prediction;
    },
  },
  watch: {
    modelValue(newValue) {
      this.searchQuery = this.getLabelById(newValue);
    },
  },
  created() {
    this.searchQuery = this.getLabelById(this.modelValue);
  },
  methods: {
    getLabel(compareLabel) {
      if (typeof compareLabel === "object") {
        compareLabel = compareLabel[this.itemLabelKey] || "";
      }
      if (this.itemsAreObjects) {
        return this.items.find(item => item[this.itemLabelKey] === compareLabel)?.[this.itemLabelKey] || "";
      } else {
        return compareLabel;
      }
    },
    getLabelById(compareId) {
      if (typeof compareId === "object") {
        compareId = compareId[this.itemValueKey] || "";
      }
      if (this.itemsAreObjects) {
        return this.items.find(item => item[this.itemValueKey] === compareId)?.[this.itemLabelKey] || "";
      } else {
        return compareId;
      }
    },
    getValue(compareLabel) {
      if (typeof compareLabel === "object") {
        compareLabel = compareLabel[this.itemLabelKey] || "";
      }
      if (this.itemsAreObjects) {
        return this.items.find(item => item[this.itemLabelKey] === compareLabel)?.[this.itemValueKey] || "";
      } else {
        return compareLabel;
      }
    },
    emitCreate() {
      const createHandler = "create-item";
      this.$emit(createHandler, { query: this.searchQuery });
      this.showDropdown = false;
    },
    highlightMatch(text) {
      if (!this.searchQuery) {
        return text;
      }
      const regex = new RegExp(`(${this.escapeRegExp(this.searchQuery)})`, "gi");
      return text.replace(regex, "<b>$1</b>");
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
    clearValue() {
      this.searchQuery = "";
      this.showDropdown = false;
      this.activeIndex = -1;
      this.emitSearch("");
      this.$emit("update:modelValue", "");
      this.$emit("itemSelected", "");
      this.emitSearch("");
    },
    updateValue(value) {
      if (value === "") {
        return this.clearValue();
      }
      this.searchQuery = value;
      this.showDropdown = true;
      this.activeIndex = -1;
      this.debouncedEmitSearch(value);
    },
    handlePredictiveText(value) {
      this.updateValue(value);
      if (this.itemsAreObjects) {
        this.selectItem(this.items.find(item => item[this.itemLabelKey] === value));
      } else {
        this.selectItem(value);
      }
    },
    emitSearch(value) {
      this.$emit("search", value);
    },
    selectItem(item) {
      this.searchQuery = this.getLabel(item);
      this.showDropdown = false;
      this.$emit("update:modelValue", this.getValue(item));
      this.$emit("itemSelected", item);
    },
    onKeyDown(event) {
      if (!this.showDropdown) {
        return;
      }

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          this.activeIndex = (this.activeIndex + 1) % this.limitedFilteredItems.length;
          break;
        case "ArrowUp":
          event.preventDefault();
          this.activeIndex = (this.activeIndex - 1 + this.limitedFilteredItems.length) % this.limitedFilteredItems.length;
          break;
        case "Enter":
          event.preventDefault();
          if (this.activeIndex !== -1) {
            this.selectItem(this.limitedFilteredItems[this.activeIndex]);
          }
          break;
        case "Escape":
          this.showDropdown = false;
          break;
      }
    },
    onBlur() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200);
    },
  },
  styleGuide: () => ({
    dropdownBorderWidth: { "size.border": "standard" },
    dropdownBorderColor: { "color.border": "dark" },
    dropDownBackgroundColor: { "color.background": "paper" },
    dropDownBorderRadius: { "size.borderRadius": "large" },
    listItemPadding: { "size.spacing": "standard" },
    activeItemBackgroundColor: { "color.background": "standard" },
    activeItemFontColor: { "color.font": "standard" },
  }),
};
</script>

<style scoped>
.i-autocomplete {
  position: relative;
}

.dropdown {
  box-sizing: border-box;
  top: calc(100% - 1px);
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: v-bind('$getStyles.dropdownBorderWidth') solid v-bind('$getStyles.dropdownBorderColor');
  background-color: v-bind('$getStyles.dropDownBackgroundColor');
  border-radius: v-bind('$getStyles.dropDownBorderRadius');
}

.dropdown li {
  padding: v-bind('$getStyles.listItemPadding');
  cursor: pointer;
}

.dropdown li.active {
  background-color: v-bind('$getStyles.activeItemBackgroundColor');
  color: v-bind('$getStyles.activeItemFontColor');
}

.no-results {
  pointer-events: none;
}
</style>
