import iButton from "@/components/forms/iButton";
import iCheckbox from "@/components/forms/iCheckbox";
import iCheckList from "@/components/forms/iCheckList";
import iDatePicker from "@/components/forms/iDatePicker";
import iSelect from "@/components/forms/iSelect";
import iChip from "@/components/forms/iChip";
import iForm from "@/components/forms/iForm";
import iFormQuestion from "@/components/forms/iFormQuestion";
import iLabel from "@/components/forms/iLabel.vue";
import iRadioButton from "@/components/forms/iRadioButton";
import iTextArea from "@/components/forms/iTextArea";
import iTextInput from "@/components/forms/iTextInput";
import iTextEdit from "@/components/forms/iTextEdit";
import iToggleSwitch from "@/components/forms/iToggleSwitch";
import iAutoComplete from "@/components/forms/iAutoComplete";
import iAsyncAutoComplete from "@/components/forms/iAsyncAutoComplete";
import iTextEditInline from "@/components/forms/iTextEditInline";
import iReactionPicker from "@/components/forms/iReactionPicker";

import iEditor from "@/components/editors/iEditor";

import iCarousel from "@/components/layout/iCarousel";
import iCard from "@/components/layout/iCard";
import iStyledCard from "@/components/layout/iStyledCard";
import iCode from "@/components/widgets/iCode";
import iCodeTag from "@/components/widgets/iCodeTag";
import iCollapsableCard from "@/components/layout/iCollapsableCard";
import iCollapsablePanel from "@/components/layout/iCollapsablePanel";
import iColumn from "@/components/layout/iColumn";
import iMasonry from "@/components/layout/iMasonry";
import iDivider from "@/components/layout/iDivider";
import iFlex from "@/components/layout/iFlex";
import iNote from "@/components/layout/iNote";
import iSpace from "@/components/layout/iSpace";
import iRow from "@/components/layout/iRow";

import iAside from "@/components/layout/semantics/iAside";
import iFooter from "@/components/layout/semantics/iFooter";
import iHeader from "@/components/layout/semantics/iHeader";
import iMain from "@/components/layout/semantics/iMain";
import iNav from "@/components/layout/semantics/iNav";
import iSection from "@/components/layout/semantics/iSection";

import iBreadcrumbs from "@/components/widgets/iBreadcrumbs";
import iDataTable from "@/components/widgets/iDataTable";
import iImageContainer from "@/components/widgets/iImageContainer.vue";
import iInfiniteScroll from "@/components/widgets/iInfiniteScroll";
import iLink from "@/components/widgets/iLink";
import iMenu from "@/components/widgets/iMenu";
import iMenuNew from "@/components/widgets/iMenuNew";
import iModal from "@/components/widgets/iModal";
import iPromptModal from "@/components/widgets/iPromptModal";
import iOverlay from "@/components/widgets/iOverlay.vue";
import iStatus from "@/components/widgets/iStatus.vue";
import iAlert from "@/components/widgets/iAlert.vue";
import iHashtagInput from "@/components/widgets/iHashtagInput";
import iRichTextEditor from "@/components/widgets/iRichTextEditor";
import iRichTextEditorNew from "@/components/widgets/iRichTextEditorNew";

import iIcon from "@/components/icons/iIcon";
import iLoadingSpinner from "@/components/icons/iLoadingSpinner";
import iCopyIcon from "@/components/icons/iCopyIcon";

import iDisplayText from "@/components/text/iDisplayText.vue";
import iHeading from "@/components/text/iHeading";
import iParagraph from "@/components/text/iParagraph";
import iSubHeading from "@/components/text/iSubHeading";
import iTitle from "@/components/text/iTitle";
import iText from "@/components/text/iText";

import iStoryBook from "@/components/storybook/iStoryBook";

import iCopyText from "@/components/widgets/iCopyText";
import iUploader from "@/components/widgets/iUploader";
import iImageUploader from "@/components/widgets/iImageUploader";
import iDropdown from "@/components/layout/iDropdown";
import iDropdownMenu from "@/components/layout/iDropdownMenu";
import iDraggable from "@/components/widgets/iDraggable";
import iSortable from "@/components/widgets/iSortable";
import iTimeLine from "@/components/widgets/iTimeLine";
import iReactionViewer from "@/components/widgets/iReactionViewer";

export default {
  iStoryBook,

  // forms
  iButton,
  iCheckbox,
  iCheckList,
  iDatePicker,
  iSelect,
  iChip,
  iForm,
  iFormQuestion,
  iLabel,
  iRadioButton,
  iTextInput,
  iTextEdit,
  iTextArea,
  iToggleSwitch,
  iAutoComplete,
  iAsyncAutoComplete,
  iTextEditInline,
  iReactionPicker,

  // editors
  iEditor,

  // layout
  iCarousel,
  iCard,
  iStyledCard,
  iCode,
  iCodeTag,
  iCollapsableCard,
  iCollapsablePanel,
  iColumn,
  iMasonry,
  iDivider,
  iDropdown,
  iDropdownMenu,
  iFlex,
  iNote,
  iSpace,
  iRow,

  // semantics
  iAside,
  iFooter,
  iHeader,
  iMain,
  iNav,
  iSection,

  // widgets
  iBreadcrumbs,
  iDataTable,
  iDraggable,
  iImageContainer,
  iInfiniteScroll,
  iLink,
  iMenu,
  iMenuNew,
  iModal,
  iPromptModal,
  iOverlay,
  iSortable,
  iImageUploader,
  iUploader,
  iStatus,
  iAlert,
  iHashtagInput,
  iRichTextEditor,
  iRichTextEditorNew,
  iTimeLine,
  iReactionViewer,

  // icons
  iIcon,
  iLoadingSpinner,
  iCopyIcon,

  // text
  iCopyText,
  iDisplayText,
  iHeading,
  iParagraph,
  iSubHeading,
  iTitle,
  iText,
};
