<template>
  <component :is="rowOrColumn" vertical-align="top">
    <MenuItem
      v-for="(item, index) in items"
      :key="index"
      :text="item.text"
      :on-click="item.onClick"
      :url="item.url"
      :target="item.target"
      :items="item.items"
    />
  </component>
</template>

<script>
import MenuItem from "@/components/widgets/SubComponents/iMenuItemNew";
import Enum from "@/data-types/enum";
export default {
  name: "iMenuNew",
  components: { MenuItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    orientation: {
      type: Enum,
      required: false,
      default: "column",
      options: ["row", "column"],
    },
  },
  computed: {
    rowOrColumn() {
      if (this.orientation === "row") {
        return "iRow";
      }
      return "iColumn";
    },
  },
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "standard" },
    fontColor: { "color.font": "standard" },
    fontWeight: { "font.weight": "standard" },
    activeLinkFontColor: { "color.font": "standard" },
    activeFontWeight: { "font.weight": "standard" },
    paddingTop: { "size.spacing": "small" },
    paddingBottom: { "size.spacing": "small" },
    paddingLeft: { "size.spacing": "standard" },
    paddingRight: { "size.spacing": "standard" },
  }),
};
</script>

<style scoped lang="scss">
</style>
