<template>
  <iColumn>
    <iRow v-if="title">
      <iSubHeading>{{ title }}</iSubHeading>
      <iIcon :icon="optionsExpanded ? 'caret-down' : 'caret-right'" @click="toggleOptionVisibility" />
    </iRow>
    <iRow
      v-if="optionsExpanded"
    >
      <iCard
        :width="width"
        :height="height"
      >
        <template #body>
          <slot />
        </template>
      </iCard>
    </iRow>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iCollapsableCard",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
  },
  data() {
    return {
      optionsExpanded: false,
    };
  },
  methods: {
    toggleOptionVisibility() {
      this.optionsExpanded = !this.optionsExpanded;
    },
  },
};
</script>
