<template>
  <h1 class="i-display-text">
    <slot />
  </h1>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iDisplayText",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["verySmall", "small", "standard", "large"],
    },
    align: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "center", "right"],
    },
  },
  computed: {
    getFontSize() {
      let fontSize = "displaySmall";
      switch (this.variant) {
        case "verySmall":
          fontSize = "displayVerySmall";
          break;
        case "small":
          fontSize = "displaySmall";
          break;
        case "standard":
          fontSize = "displayMedium";
          break;
        case "large":
          fontSize = "displayLarge";
          break;
      }

      return this.$getStyleValuePropertiesFromTheme("size.font")[fontSize];
    },
  },
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "displayMedium" },
    fontColor: { "color.font": "standard" },
    fontWeight: { "font.weight": "standard" },
    lineHeight: { "size.lineHeight": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
  h1 {
    font-family: v-bind("$getStyles.fontFamily");
    font-size: v-bind("getFontSize");
    font-weight: v-bind("$getStyles.fontWeight");
    line-height: v-bind('$getStyles.lineHeight');
    color: v-bind("$getStyles.fontColor");

    margin-top: v-bind("$getStyles.marginTop");
    margin-bottom: v-bind("$getStyles.marginBottom");
    margin-left: v-bind("$getStyles.marginLeft");
    margin-right: v-bind("$getStyles.marginRight");

    text-align: v-bind("align");
  }
</style>
