import { mapState } from "pinia";
import { getStylesForTheme, getStyleValuePropertiesFromTheme } from "@/helpers/style-helpers.js";
import { useStyleStore } from "@/stores/style-store.js";

const StyleMixin = {
  props: {
    styleOverrides: {
      type: Object,
      default: {},
      required: false,
    },
  },
  computed: {
    ...mapState(useStyleStore, ["myTheme"]),
    $getStyleGuide() {
      if (this?.$?.type?.styleGuide) {
        if (typeof this.$.type.styleGuide !== "function") {
          throw new Error(
            `styleGuide must be defined as a function which takes self and returns an object in ${this.$options.name}`,
          );
        }
        return this.$.type.styleGuide.bind(this)();
      }
      return {};
    },
    $getStyleOverrides() {
      return this.styleOverrides ?? {};
    },
    $getStyles() {
      return getStylesForTheme(this.$getStyleGuide, this.$getStyleOverrides, this.myTheme);
    },
  },
  methods: {
    $getStyleValuePropertiesFromTheme(styleVarName) {
      const properties = getStyleValuePropertiesFromTheme(styleVarName, this.myTheme);

      if (!properties) {
        throw new Error(`Style properties for value ${styleVarName} not found in theme`);
      }

      return properties;
    },
    $elementProps() {
      const props = this.$props;

      const propOptions = this.$options.props;

      return Object.keys(propOptions).map(propName => {
        const propDefinition = propOptions[propName];
        let propTypes = [];

        if (Array.isArray(propDefinition.type)) {
          propTypes = propDefinition.type.map(type => {
            const validTypes = ["String", "Number", "Boolean", "Object", "Array"];
            return validTypes.includes(type.name) ? type.name : "Enum";
          });
        } else {
          propTypes = [propDefinition.type.name];
        }

        return {
          name: propName,
          type: propTypes, // Get the type name if defined
          options: propDefinition.options || [], // Get the options if defined
          value: props[propName], // Get the current value of the prop
          required: propDefinition.required || false, // Get the required status of the prop
        };
      });
    },
    $exposeStyleGuide(prepend) {
      const exposedValues = Object.keys(this.$getStyleGuide).map(key => {
        const itemKeys = Object.keys(this.$getStyleGuide[key]);
        const stylePathKey = itemKeys[0];
        return {
          [key]: this.$getStyleValuePropertiesFromTheme(stylePathKey)[this.$getStyleGuide[key][stylePathKey]],
        };
      });

      if (exposedValues.length > 0) {

        const styleScript = document.getElementById(`${prepend}-style-script`);
        if (styleScript) {
          styleScript.remove();
        }

        const script = document.createElement("style");
        script.id = `${prepend}-style-script`;

        // transform the exposedValues array into a string of css vars
        const cssVars = exposedValues.map(value => {
          const key = Object.keys(value)[0];
          if (!key) {
            throw new Error("Empty object found in exposedValues array");
          }

          return `--${prepend}-${key}: ${value[key]};`;
        }).join("\n");

        script.innerHTML = `:root {
          ${cssVars}
        }`;

        document.head.appendChild(script);
      }
    },
  },
};

export default StyleMixin;
