import { SCROLL_SNAP_TYPES } from "@/constants/effect-constants";

const calculateSnapIndex = (el, itemWidth, snapIndex) => {
  const calculatedLeft = itemWidth * snapIndex;
  const currentLeft = el.scrollLeft;
  const diff = calculatedLeft - currentLeft;
  // eslint-disable-next-line
  const newSnapIndex = snapIndex + Math.floor((diff < 0 ? diff * -1 : diff) / itemWidth);

  return newSnapIndex + 1;
};

const shouldResetScroll = (snaps, snapIndex) => {
  if (snapIndex % snaps === 1 && snapIndex > snaps * 2) {
    return true;
  }
  return false;
};
export const scrollX = {
  options: {
    duration: 15000,
    snapType: SCROLL_SNAP_TYPES.SNAP,
    pauseOnHover: true,
    snaps: 12,
    itemWidth: 416,
  },
  beforeAnimationHandler() {
    return true;
  },
  animationHandler: (el, options ) => {
    const { duration, snaps, pauseOnHover, itemWidth } = options;
    let interval = null;

    if (options.snapType === SCROLL_SNAP_TYPES.SNAP) {
      let snapIndex = 0;

      const intervalMethod = () => {
        snapIndex = calculateSnapIndex(el, itemWidth, snapIndex);
        el.scroll({
          left: itemWidth * snapIndex,
          behavior: "smooth",
        });
        if (shouldResetScroll(snaps, snapIndex)) {
          el.scrollLeft = 0;
          snapIndex = 0;
        }
      };
      const intervalDuration = duration / snaps;

      clearInterval(interval);
      interval = setInterval(intervalMethod, intervalDuration);

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          clearInterval(interval);
          interval = setInterval(intervalMethod, intervalDuration);
        } else {
          clearInterval(interval);
        }
      });

      if (pauseOnHover) {
        el.addEventListener("mouseenter", () => {
          clearInterval(interval);
        });
        el.addEventListener("mouseleave", () => {
          clearInterval(interval);
          interval = setInterval(intervalMethod, intervalDuration);
        });
        el.addEventListener("touchstart", () => {
          clearInterval(interval);
        });
        el.addEventListener("touchend", () => {
          intervalMethod();
          clearInterval(interval);
          interval = setInterval(intervalMethod, intervalDuration);
        });
      }

      el._onDestroy = () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  },
  afterAnimationHandler: el => {
    el.scroll({
      left: 0,
      behavior: "smooth",
    });
  },
};
