<template>
  <div :class="myClasses">
    <slot />
  </div>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";

export default {
  name: "iColumn",
  props: {
    overflow: {
      type: Enum,
      required: false,
      default: "none",
      options: ["auto", "scroll", "hidden", "visible", "none"],
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    maxWidth: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    minWidth: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    maxHeight: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    minHeight: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    align: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "right", "center"],
    },
    verticalAlign: {
      type: Enum,
      required: false,
      default: "top",
      options: ["top", "bottom", "middle", "around", "between", "evenly"],
    },
    gap: {
      type: [Number, Enum],
      required: false,
      default: "standard",
      options: [...themeStructure.size.spacing],
    },
    wrap: {
      type: Enum,
      required: false,
      default: "wrap",
      options: ["wrap", "nowrap", "wrap-reverse", "inherit", "unset"],
    },
  },
  computed: {
    myFixedWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }
      return "unset";
    },
    myFixedHeight() {
      if (typeof this.height === "number") {
        return `${this.height}px`;
      }
      return "unset";
    },
    myMinHeight() {
      if (typeof this.minHeight === "number") {
        return `${this.minHeight}px`;
      }
      return "unset";
    },
    myMinWidth() {
      if (typeof this.minWidth === "number") {
        return `${this.minWidth}px`;
      }
      return "unset";
    },
    myMaxHeight() {
      if (typeof this.maxHeight === "number") {
        return `${this.maxHeight}px`;
      }
      return "unset";
    },
    myMaxWidth() {
      if (typeof this.maxWidth === "number") {
        return `${this.maxWidth}px`;
      }
      return "unset";
    },
    myClasses() {
      const classes = ["col"];

      if (this.disabled) {
        classes.push("disabled");
      }

      if (this.width === "hug") {
        classes.push("width-fit-content");
      } else if (this.width === "fill") {
        if (this.height === "fill") {
          classes.push("width-flex-grow");
          classes.push("width-full");
        } else {
          classes.push("width-full");
        }
      } else {
        classes.push("width-fixed");
      }

      if (this.height === "hug") {
        classes.push("height-fit-content");
      } else if (this.height === "fill") {
        if (this.width === "fill") {
          classes.push("height-flex-grow");
          classes.push("height-full");
        } else {
          classes.push("height-full");
        }
      } else {
        classes.push("height-fixed");
      }

      if (this.overflow !== "none") {
        classes.push("overflow");
      }

      if (this.myMinWidth !== "unset") {
        classes.push("width-min");
      }

      if (this.myMaxWidth !== "unset") {
        classes.push("width-max");
      }

      if (this.myMinHeight !== "unset") {
        classes.push("height-min");
      }

      if (this.myMaxHeight !== "unset") {
        classes.push("height-max");
      }

      return classes;
    },
    myGap() {
      if (typeof this.gap === "number") {
        return `${this.gap}px`;
      }
      return this.$getStyleValuePropertiesFromTheme("size.spacing")[this.gap];
    },
    myOverflow() {
      return this.overflow;
    },
    myJustifyContent() {
      switch (this.verticalAlign) {
        case "top":
          return "flex-start";
        case "bottom":
          return "flex-end";
        case "middle":
          return "center";
        case "around":
          return "space-around";
        case "between":
          return "space-between";
        case "evenly":
          return "space-evenly";
      }
      throw new Error(`unsupported value '${this.verticalAlign}' for prop 'verticalAlign' in component iColumn`);
      // eslint-disable-next-line vue/return-in-computed-property
    },
    myAlignItems() {
      switch (this.align) {
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
        case "center":
          return "center";
      }
      throw new Error(`unsupported value '${this.align}' for prop 'align' in component iColumn`);
      // eslint-disable-next-line vue/return-in-computed-property
    },
  },
  styleGuide: () => ({
    borderWidth: { "size.border": "none" },
    borderRadius: { "size.borderRadius": "none" },
    borderColor: { "color.border": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },

    backgroundColor: { "color.border": "none" },
  }),
};
</script>
<style lang="scss" scoped>

.col {
  display: flex;
  flex-direction: column;
  gap: v-bind(myGap);
  justify-content: v-bind(myJustifyContent);
  align-items: v-bind(myAlignItems);
  flex-wrap: v-bind(wrap);
  box-sizing: border-box;
  margin: v-bind("$getStyles.marginTop") v-bind("$getStyles.marginRight") v-bind("$getStyles.marginBottom") v-bind("$getStyles.marginLeft");
  padding: v-bind("$getStyles.paddingTop") v-bind("$getStyles.paddingRight") v-bind("$getStyles.paddingBottom") v-bind("$getStyles.paddingLeft");
  border: v-bind("$getStyles.borderWidth") solid v-bind("$getStyles.borderColor") !important;
  border-radius: v-bind("$getStyles.borderRadius");
  background-color: v-bind("$getStyles.backgroundColor");

  &.height-flex-grow {
    flex-grow: 1;
    flex-basis: 0;
  }

  &.height-full {
    height: 100%;
  }

  &.overflow {
    overflow: v-bind(myOverflow);
  }

  &.width-fixed {
    width: v-bind(myFixedWidth);
  }

  &.width-max {
    max-width: v-bind(myMaxWidth);
  }

  &.width-min {
    min-width: v-bind(myMinWidth);
  }

  &.height-max {
    max-height: v-bind(myMaxHeight);
  }

  &.height-min {
    min-height: v-bind(myMinHeight);
  }

  &.width-flex-grow {
    flex-grow: 1;
    flex-basis: 0;
  }

  &.width-full {
    width: 100%;
  }

  &.width-fit-content {
    width: fit-content;
  }

  &.height-fixed {
    height: v-bind(myFixedHeight);
  }

  &.height-fit-content {
    height: fit-content;
  }
}

:deep(.col > .height-fit-content.width-flex-grow) {
  height: fit-content;
}

:deep(.col > .row.height-fixed.width-flex-grow) {
  height: v-bind(myFixedHeight);
}

:deep(.col > .height-flex-grow.width-fit-content) {
  width: fit-content;
  flex-grow: 1;
  flex-basis: 0;
}

.disabled {
  opacity: 0.75;
  pointer-events: none;
}

</style>
