import { defineStore } from "pinia";
import { themeStructure } from "@/constants/style-guide-constants";

export const useStyleStore = defineStore("style-store", {
  state: () => ({
    myTheme: {},
    themeStructure: themeStructure,
    themePalette: {},
    myThemeRaw: {},
    themeId: "",
    themePaletteId: "",
  }),
  actions: {
    setTheme(theme) {
      this.myTheme = theme;
    },
    setThemeData(data) {
      this.myTheme = data.themeData;
      this.themePalette = data.themePalette;
      this.myThemeRaw = data.myThemeRaw;
      this.themeId = data.themeId;
      this.themePaletteId = data.themePaletteId;
    },
  },
});
