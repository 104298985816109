<template>
  <!-- This shallow copy is deliberately done to prevent using the svg reactively which is a Vue code smell-->
  <!-- If you can come up with a better way to make this work go for it -->
  <component
    :is="{...iconData}"
    v-if="!isLoading && iconData"
    :class="iconFullClass"
  />
  <iLoadingSpinner
    v-else
    :size="size"
  />
</template>

<script>
import loadedIcons from "@/icons";
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants.js";

export default {
  name: "iIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Enum,
      required: false,
      default: "standard",
      options: [...themeStructure.size.icon],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: Enum,
      required: false,
      default: "primary",
      options: ["primary", "secondary", "error", "warning", "light", "branded", "subtle"],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      iconData: null,
    };
  },
  computed: {
    myHeight() {
      return this.$getStyleValuePropertiesFromTheme("size.icon")[this.size];
    },
    myWidth() {
      if (typeof this.size === "number") {
        return `${this.size}px`;
      }
      return this.$getStyleValuePropertiesFromTheme("size.icon")[this.size];
    },

    iconFullClass() {
      let hover = "";
      let variant = "";

      if (!this.disableHover) {
        hover = "i-icon--hover";
      }
      if (this.disabled) {
        hover = "";
        variant = "disabled";
      } else {
        variant = this.variant;
      }

      return `i-icon ${hover} i-icon--${variant}`;
    },
  },

  styleGuide: () => ({
    height: { "size.icon": "standard" },
    width: { "size.icon": "standard" },
    primaryVariantColor: { "color.icon": "standard" },
    secondaryVariantColor: { "color.icon": "informational" },
    errorVariantColor: { "color.icon": "striking" },
    warningVariantColor: { "color.icon": "notice" },
    lightVariantColor: { "color.font": "light" },
    brandedVariantColor: { "color.font": "branded" },
    subtleVariantColor: { "color.font": "subtle" },
    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    hoverColor: { "color.icon": "light" },
    disabledColor: { "color.icon": "light" },
    borderColor: { "color.border": "light" },
    borderWidth: { "size.border": "none" },
    borderRadius: { "size.borderRadius": "small" },
  }),
  watch: {
    icon: function () {
      this.loadFile();
    },
  },
  created() {
    this.loadFile();
  },
  methods: {
    async loadFile() {
      let icons = await loadedIcons;
      if (!icons[this.icon]) {
        throw new Error(`Icon [${this.icon}] could not be found`);
      }
      this.iconData = icons[this.icon];
    },
  },
};
</script>


<style scoped lang="scss">
.i-icon {
  width: v-bind(myWidth);
  min-width: v-bind(myWidth);
  height: v-bind(myHeight);
  min-height: v-bind(myHeight);
  opacity: 1;

  margin-top: v-bind('$getStyles.marginTop');;
  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-right: v-bind('$getStyles.marginRight');
  margin-left: v-bind('$getStyles.marginLeft');

  border-style: solid;
  border-color: v-bind("$getStyles.borderColor");
  border-width: v-bind("$getStyles.borderWidth");
  border-radius: v-bind("$getStyles.borderRadius");

  &--primary {
    fill: v-bind("$getStyles.primaryVariantColor");
    stroke: v-bind("$getStyles.primaryVariantColor");
  }

  &--secondary {
    fill: v-bind("$getStyles.secondaryVariantColor");
    stroke: v-bind("$getStyles.secondaryVariantColor");
  }

  &--subtle {
    fill: v-bind("$getStyles.subtleVariantColor");
    stroke: v-bind("$getStyles.subtleVariantColor");
  }

  &--warning {
    fill: v-bind("$getStyles.warningVariantColor");
    stroke: v-bind("$getStyles.warningVariantColor");
  }

  &--error {
    fill: v-bind("$getStyles.errorVariantColor");
    stroke: v-bind("$getStyles.errorVariantColor");
  }

  &--light {
    fill: v-bind("$getStyles.lightVariantColor");
    stroke: v-bind("$getStyles.lightVariantColor");
  }
  &--branded {
    fill: v-bind("$getStyles.brandedVariantColor");
    stroke: v-bind("$getStyles.brandedVariantColor");
  }

  &--hover:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    fill: v-bind("$getStyles.disabledColor");
  }
}
</style>
