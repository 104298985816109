<template>
  <div @click="$refs.file.click()">
    <slot />
  </div>
  <input
    ref="file"
    type="file"
    class="hide-input"
    :accept="acceptableTypesString"
    @change="uploadFile"
  >
</template>

<script>

export default {
  name: "iUploader",
  props: {
    acceptableFileTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ["uploadFile"],
  computed: {
    acceptableTypesString() {
      const acceptableTypesString = this.acceptableFileTypes.join(", ");
      if (acceptableTypesString === "") {
        return null;
      }
      return acceptableTypesString;
    },
  },
  methods: {
    async uploadFile() {
      this.$emit("uploadFile", this.$refs.file.files[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.hide-input {
    display: none;
}
</style>
