<template>
  <iSubHeading>Button</iSubHeading>
  <StoryBook
    :components="components"
    :prop-options="propOptions"
  />
</template>

<script>
import StoryBook from "@/components/storybook/StoryBook";
export default {
  name: "ButtonDocs",
  components: { StoryBook },
  data() {
    return {
      components: ["iButton"],
      propOptions: {
        slot: ["Click Me"],
        variant: ["tertiary", "primary", "secondary"],
        width: ["fill", "hug", 250],
        disabled: [true, false],
        isLoading: [true, false],
        loadingText: ["please wait"],
        loadingTextPosition: ["inside", "after"],
        loadingSpinnerPosition: ["inside", "after", "between"],
      },
    };
  },
};
</script>
