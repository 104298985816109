export const clickOutside = {
  beforeMount(el, binding) {
    el._clickOutsideHandler = event => {
      const path = event.composedPath();
      if (!path.includes(el) && el.style.display !== "none") {
        binding.value(event);
      }
    };
  },
  mounted(el) {
    document.addEventListener("click", el._clickOutsideHandler);
    document.addEventListener("touchstart", el._clickOutsideHandler);
  },
  unmounted(el) {
    document.removeEventListener("click", el._clickOutsideHandler);
    document.removeEventListener("touchstart", el._clickOutsideHandler);
  },
};
