<template>
  <div>
    <h1>SlackEditor</h1>
  </div>
</template>

<script>
export default {
  name: "SlackEditor",
};
</script>

<style scoped lang="scss">

</style>
