<template>
  <iRow gap="large" vertical-align="middle">
    <iColumn v-if="showPreview" width="hug">
      <iImageContainer
        :src="imageSrc"
        :height="previewImageHeight"
        :width="previewImageWidth"
        :object-fit="objectFit"
      />
    </iColumn>
    <iColumn height="fill" vertical-align="middle">
      <iUploader @upload-file="uploadImage">
        <iButton :variant="buttonVariant">
          {{ image || uploadedImage ? changeImageText : selectImageText }}
        </iButton>
      </iUploader>
    </iColumn>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";

import iImageContainer from "@/components/widgets/iImageContainer.vue";
import iUploader from "@/components/widgets/iUploader.vue";
export default {
  name: "iImageUploader",
  components: {
    iImageContainer,
    iUploader,
  },
  props: {
    image: {
      type: String,
      required: false,
      default: "",
    },
    previewImageWidth: {
      type: [Number, Enum],
      required: false,
      options: ["auto"],
      default: 100,
    },
    previewImageHeight: {
      type: [Number, Enum],
      required: false,
      options: ["auto"],
      default: 100,
    },
    objectFit: {
      type: Enum,
      required: false,
      default: "contain",
      options: ["none", "scale-down", "fill", "cover", "contain"],
    },
    changeImageText: {
      type: String,
      required: false,
      default: "Change Image",
    },
    selectImageText: {
      type: String,
      required: false,
      default: "Select Image",
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonVariant: {
      type: Enum,
      required: false,
      default: "primary",
      options: ["primary", "secondary", "tertiary", "striking", "branded"],
    },
  },
  emits: ["uploadImage"],
  data() {
    return {
      uploadedImage: null,
      uploadedFilestream: null,
    };
  },
  computed: {
    imageSrc() {
      if (this.uploadedImage) {
        return this.uploadedImage;
      } else {
        return this.image;
      }
    },
  },
  methods: {
    uploadImage(filestream) {
      this.uploadedFilestream = filestream;
      this.parseUploadedImage();
      this.$emit("uploadImage", this.uploadedFilestream);
    },
    parseUploadedImage() {
      var reader = new FileReader();
      reader.onload = e => {
        this.uploadedImage = e.target.result;
        return this.uploadedImage;
      };
      reader.readAsDataURL(this.uploadedFilestream);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
