<template>
  <iColumn>
    <iLabel>
      {{ label }}
    </iLabel>
    <iTextInput
      v-model="date"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :type="type"
      @blur="emitBlur"
      @input="updateDate"
      @focus="emitFocus"
    />
    <iRow v-if="withTime">
      <input
        type="number"
        min="1"
        max="12"
        @input="updateHours"
      >:<input
        type="number"
        min="0"
        max="59"
        @input="updateMinutes"
      >
      <iSelect
        v-model="meridiem"
        :items="[{name:'AM', value:'AM'}, {name:'PM', value:'PM'}]"
        value-field="value"
        text-field="name"
        @update:model-value="updateMeridiem"
      />
    </iRow>
  </iColumn>
</template>
<script>
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Enum from "@/data-types/enum";

dayjs.extend(utc);

export default {
  name: "iDatePicker",
  props: {
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: [Number, Date],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    pattern: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: Enum,
      required: false,
      default: "date",
      options: ["date", "datetime-local", "month", "week", "time"],
    },
    withTime: {
      type: Boolean,
      required: false,
    },
  },
  styleGuide: () => ({
    gap: { "size.spacing": "extraSmall" },
    inputFontSize: { "size.font": "extraLarge" },
    inputBorderWidth: { "size.border": "thin" },
    inputBorderColor: { "color.border": "standard" },
    inputPaddingTop: { "size.spacing": "small" },
    inputPaddingBottom: { "size.spacing": "small" },
    inputPaddingLeft: { "size.spacing": "small" },
    inputPaddingRight: { "size.spacing": "small" },
    timeInputPaddingTop: { "size.spacing": "small" },
    timeInputPaddingBottom: { "size.spacing": "small" },
    containerPaddingTop: { "size.spacing": "small" },
    containerPaddingBottom: { "size.spacing": "small" },
    containerPaddingLeft: { "size.spacing": "none" },
    containerPaddingRight: { "size.spacing": "none" },
    containerMarginTop: { "size.spacing": "none" },
    containerMarginBottom: { "size.spacing": "none" },
    containerMarginLeft: { "size.spacing": "small" },
    containerMarginRight: { "size.spacing": "small" },
  }),
  emits: ["blur", "focus", "update:modelValue"],
  data() {
    return {
      date: 0,
      hours: 0,
      minutes: 0,
      meridiem: "AM",
    };
  },
  created: function() {
    this.date = this.modelValue;
    this.emitUpdate = _.debounce(() => {
      let date = dayjs(`${this.date} ${this.hours}:${this.minutes} ${this.meridiem}`);
      this.$emit("update:modelValue", date.unix());
    }, this.debounce);
  },
  methods: {
    // including this so the linter does not complain
    // about the function being defined in the created hook
    emitUpdate: function() {
    },
    emitBlur(event) {
      this.$emit("blur", event);
    },
    emitFocus(event) {
      this.$emit("focus", event);
    },
    updateDate(e) {
      this.date = dayjs(e.target.value).format("YYYY-MM-DD");
      this.emitUpdate();
    },
    updateHours(e) {
      this.hours = e.target.value;
      this.emitUpdate();
    },
    updateMinutes(e) {
      this.minutes = e.target.value;
      this.emitUpdate();
    },
    updateMeridiem() {
      this.emitUpdate();
    },
  },
};
</script>
