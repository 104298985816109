export const ACTION_TYPE_LINK_IN_SAME_WINDOW = "action_type_link_same_window";
export const ACTION_TYPE_EXPAND_SUBMENU = "action_type_expand_submenu";
export const ACTION_TYPE_BUTTON_CLICK = "action_type_button_click";

export const ORIENTATION_VERTICAL = "vertical";
export const ORIENTATION_HORIZONTAL = "horizontal";


export default {
  ACTION_TYPE_LINK_IN_SAME_WINDOW,
  ACTION_TYPE_BUTTON_CLICK,
  ORIENTATION_VERTICAL,
  ORIENTATION_HORIZONTAL,
};
