import { vTooltip } from "@/directives/v-tooltip";
import { clickOutside } from "@/directives/v-click-outside";
import { followParent } from "@/directives/v-follow-parent";

export default [
  {
    name: "tooltip",
    directive: vTooltip,
  },
  {
    name: "click-outside",
    directive: clickOutside,
  },
  {
    name: "follow-parent",
    directive: followParent,
  },
];
