import StyleMixin from "@/mixins/style-mixin";
import PropValidationMixin from "@/mixins/prop-validation-mixin";
import EffectMixin from "@/mixins/effect-mixin";
import { VclComponentMixin } from "@/mixins/vcl-component-mixin";
export default [{
  mixin: VclComponentMixin,
  library: true,
},
{
  mixin: StyleMixin,
},
{
  mixin: PropValidationMixin,
},
{
  mixin: EffectMixin,
}];
