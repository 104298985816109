import { EFFECT_ON_ACTION } from "@/constants/effect-constants";
import effects from "@/effects";

export default {
  props: {
    animations: {
      type: [Array, Object],
      default: () => [],
      required: false,
    },
  },
  data: () => ({
    internalAnimations: effects,
    internalAnimationHandlers: {},
  }),
  mounted() {
    this._initAnimationHandlers();
  },
  methods: {
    $_uniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    $_cleanup() {
      Object.keys(this.internalAnimationHandlers).forEach(key => {
        const handler = this.internalAnimationHandlers[key];
        handler.element.removeEventListener(handler.type, handler.handler);
      });
    },
    $_cleanedQuerySelector(querySelector) {
      let cleanedQuerySelector = querySelector;
      if (querySelector.includes(":hover")) {
        cleanedQuerySelector = querySelector.replace(":hover", "");
      }
      if (querySelector.includes(":click")) {
        cleanedQuerySelector = querySelector.replace(":click", "");
      }
      return cleanedQuerySelector;
    },
    $_runBeforeAnimationHandler(element, animation) {
      const effect = this.internalAnimations[animation.effect];
      if (effect.beforeAnimationHandler) {
        if (animation.target) {
          const targets = element.querySelectorAll(this.$_cleanedQuerySelector(animation.target));
          targets.forEach(target => {
            effect.beforeAnimationHandler(target, animation.options);
          });
        } else {
          effect.beforeAnimationHandler(element, animation.options);
        }
      }
    },
    _initAnimationHandlers(el, animationsHandlers) {
      const element = el || this.$el;
      const animationHandlersComputed = animationsHandlers || this.animations;

      let animationArray = [];
      if (!Array.isArray(animationHandlersComputed)) {
        animationArray.push(animationHandlersComputed);
      } else {
        animationArray = animationHandlersComputed;
      }

      animationArray.forEach(animation => {
        const effect = this.internalAnimations[animation.effect];
        const options = { ...effect.options, ...animation.options || {} };
        if (!effect) {
          throw new Error(`Effect ${animation.effect} not found`);
        }

        this.$_runBeforeAnimationHandler(element, animation);

        if (animation.onAction === EFFECT_ON_ACTION.PAGE_LOAD) {
          if (animation.target) {
            const targets = element.querySelectorAll(animation.target);
            targets.forEach(target => {
              effect.animationHandler(target, options);
            });
          } else {
            effect.animationHandler(element, options);
          }
        }

        if (animation.onAction === EFFECT_ON_ACTION.HOVER) {
          if (animation.target && animation.target.includes(":hover")) {
            const targets = element.querySelectorAll(this.$_cleanedQuerySelector(animation.target));
            targets.forEach(target => {
              this.internalAnimationHandlers[this.$_uniqueId()] = {
                type: "mouseenter",
                element: target,
                "handler": () => {
                  effect.animationHandler(target, options);
                },
              };
              this.internalAnimationHandlers[this.$_uniqueId()] = {
                type: "mouseleave",
                element: target,
                "handler": () => {
                  effect.afterAnimationHandler(target, options);
                },
              };
            });
          } else {
            this.internalAnimationHandlers[this.$_uniqueId()] = {
              type: "mouseenter",
              element: element,
              "handler": () => {
                if (animation.target) {
                  const targets = element.querySelectorAll(animation.target);
                  targets.forEach(target => {
                    effect.animationHandler(target, options);
                  });
                } else {
                  effect.animationHandler(element, options);
                }
              },
            };
            this.internalAnimationHandlers[this.$_uniqueId()] = {
              type: "mouseleave",
              element: element,
              "handler": () => {
                if (animation.target) {
                  const targets = element.querySelectorAll(animation.target);
                  targets.forEach(target => {
                    effect.afterAnimationHandler(target, options);
                  });
                } else {
                  effect.afterAnimationHandler(element, options);
                }
              },
            };
          }
        }

        if (animation.onAction === EFFECT_ON_ACTION.CLICK) {
          if (animation.target && animation.target.includes(":click")) {
            const targets = element.querySelectorAll(this.$_cleanedQuerySelector(animation.target));
            targets.forEach(target => {
              this.internalAnimationHandlers[this.$_uniqueId()] = {
                type: "click",
                element: target,
                "handler": () => effect.animationHandler(target, options),
              };
            });
          } else {
            this.internalAnimationHandlers[this.$_uniqueId()] = {
              type: "click",
              element: element,
              "handler": () => {
                if (animation.target) {
                  const targets = element.querySelectorAll(animation.target);
                  targets.forEach(target => {
                    effect.animationHandler(target, options);
                  });
                } else {
                  effect.animationHandler(element, options);
                }
              },
            };
          }
        }

        this.$_cleanup();
        Object.keys(this.internalAnimationHandlers).forEach(key => {
          const handler = this.internalAnimationHandlers[key];
          handler.element.addEventListener(handler.type, handler.handler);
        });

        element._onDestroy = () => {
          this.$_cleanup();
        };
      });
    },
  },
};
