<template>
  <iRow>
    <iColumn>
      <iSubHeading>iRow Default Props</iSubHeading>
      <iCodeTag
        tag="iRow"
        height="hug"
        width="fill"
        align="left"
        vertical-align="bottom"
        gap="standard"
      />
    </iColumn>
  </iRow>
  <iRow
    v-for="(iteration, index) in iterations"
    :key="index"
    :height="800"
    vertical-align="top"
  >
    <iRow>
      <iSubHeading>{{ iteration.title }}</iSubHeading>
    </iRow>
    <iRow>
      <iCodeTag
        tag="iRow"
        v-bind="iteration.leftProps"
        :self-closing="true"
      />
      <iCodeTag
        tag="iRow"
        v-bind="iteration.rightProps"
        :self-closing="true"
      />
    </iRow>
    <iRow vertical-align="top" height="fill">
      <iRow v-bind="iteration.leftProps" class="border">
        <iButton>one</iButton>
        <iButton>I am<br>a tall<br>button</iButton>
        <iButton>Many words that make a button wanting to be very wide</iButton>
        <iButton>thisbuttonhasaververververylongwordfortext</iButton>
      </iRow>
      <iRow v-bind="iteration.rightProps" class="border">
        <iButton>one</iButton>
        <iButton>i'm another<br>tall button</iButton>
        <iButton>three</iButton>
      </iRow>
    </iRow>
  </iRow>
</template>

<script>
export default {
  name: "RowDocs",
  data() {
    return {
      iterations: [
        {
          leftProps: { align: "left" },
          rightProps: { align: "right" },
        },
        {
          leftProps: { align: "center" },
          rightProps: { align: "evenly" },
        },
        {
          leftProps: { align: "around" },
          rightProps: { align: "between" },
        },
        {
          leftProps: { width: 300, verticalAlign: "middle" },
          rightProps: { width: 300, verticalAlign: "middle" },
        },
        {
          leftProps: { width: 300, verticalAlign: "middle" },
          rightProps: { width: 300, verticalAlign: "middle", height: "fill" },
        },
        {
          leftProps: { width: 300, verticalAlign: "middle" },
          rightProps: { width: 300, verticalAlign: "middle", height: "hug" },
        },
        {
          leftProps: { width: 300, verticalAlign: "middle" },
          rightProps: { width: 300, verticalAlign: "middle", height: 200 },
        },
        {
          leftProps: { width: 300, verticalAlign: "top" },
          rightProps: { width: 300, verticalAlign: "top" },
        },
        {
          leftProps: { width: 300 },
          rightProps: { width: 300 },
        },
        {
          leftProps: { width: 200 },
          rightProps: { width: 200 },
        },
        {
          leftProps: { width: 200, overflow: "hidden" },
          rightProps: { width: 200 },
        },
        {
          leftProps: { width: 200, overflow: "visible" },
          rightProps: { width: 200 },
        },
        {
          leftProps: { width: 200 },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { width: "hug" },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { width: "hug" },
          rightProps: { width: "hug" },
        },
        {
          leftProps: { width: "fill" },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { height: "fill" },
          rightProps: { height: "fill" },
        },
        {
          leftProps: { height: "hug" },
          rightProps: { height: "hug" },
        },
        {
          leftProps: { height: 300 },
          rightProps: { height: 200 },
        },
        {
          leftProps: { height: 300 },
          rightProps: { height: "fill" },
        },
        {
          leftProps: { height: "hug" },
          rightProps: { height: 400 },
        },
        {
          leftProps: { width: "fill", align: "center" },
          rightProps: { width: "fill", align: "right" },
        },
        {
          leftProps: { width: "fill", height: 400, align: "center", verticalAlign: "middle" },
          rightProps: { width: "fill", height: 400, align: "right", verticalAlign: "bottom" },
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
  .border {
    border: 1px solid red !important;
  }
</style>
