import Enum from "@/data-types/enum";
import PropDeprecationError from "@/errors/prop-deprecation-error.js";
import PropValidationError from "@/errors/prop-validation-error.js";
import PropDeclarationError from "@/errors/prop-declaration-error.js";
import BaseVclError from "@/errors/base-vcl-error.js";

const EXCLUDED_DEFAULT_COMPONENTS = [
  "BaseTransition",
  "KeepAlive",
  "Suspense",
  "Teleport",
  "Transition",
  "TransitionGroup",
  "BubbleMenu",
  "CustomImage",
];

const validateProp = function(propName, propDefinition, propValue, component) {
  const componentName = component.$options.name;
  if (EXCLUDED_DEFAULT_COMPONENTS.includes(componentName)) {
    return;
  }
  if (!componentName) {
    return;
  }
  if (propDefinition.deprecated) {
    throw new PropDeprecationError(
      "this prop is deprecated",
      {
        "component": componentName,
        "propName": propName,
        "propDefinition": propDefinition,
      },
    );
  }

  let propValueOrDefault = propValue;
  let usingDefaultPropValue = false;
  if (propValue === undefined && propDefinition.default !== undefined && typeof propDefinition.default === "function") {
    usingDefaultPropValue = true;
    propValueOrDefault = propDefinition.default();
  } else if (propValue === undefined && propDefinition.default !== undefined) {
    usingDefaultPropValue = true;
    propValueOrDefault = propDefinition.default;
  }

  if (Array.isArray(propDefinition.type)) {
    let passedAtLeastOne = false;
    propDefinition.type.forEach(allowedPropType => {
      try {
        const oldPropDefinitionType = propDefinition.type;
        propDefinition.type = null;

        const newPropDefinition = Object.assign({}, propDefinition);
        propDefinition.type = oldPropDefinitionType;
        newPropDefinition.type = allowedPropType;
        validateProp(propName, newPropDefinition, propValueOrDefault, component);
        passedAtLeastOne = true;
      } catch (error) {
        if (!(error instanceof BaseVclError)) {
          throw error;
        }
      }
    });
    if (!passedAtLeastOne) {
      const valueType = typeof propValueOrDefault;
      if (usingDefaultPropValue) {
        throw new PropValidationError(
          "Prop default has a default value that is not one of the valid types",
          {
            propName,
            componentName,
            defaultValue: propValueOrDefault,
            valueType,
            allowedTypes: propDefinition.type,
          },
        );
      }
      if (valueType === "string" && propDefinition.type.includes(Number) && !isNaN(propValue)) {
        throw new PropValidationError(
          "Prop allows multiple types (Number being one of them), but the value failed to match any valid types. (perhaps you forgot to prefix your prop with a colon if the value is a hard coded number inside quotes)",
          {
            propName,
            componentName,
            defaultValue: propValueOrDefault,
            valueType,
            allowedTypes: propDefinition.type,
          },
        );
      }
      throw new PropValidationError(
        "Prop allows multiple types, but the value failed to match any valid types",
        {
          propName,
          componentName,
          defaultValue: propValueOrDefault,
          valueType,
          allowedTypes: propDefinition.type,
        },
      );
    }
    return;
  }

  if (propDefinition.type === Enum) {
    const propEnumOptions = propDefinition.options;
    if (!Array.isArray(propEnumOptions)) {
      throw new PropDeclarationError(
        "Prop ENUM options must be provided as an array",
        {
          componentName: componentName,
          propName: propName,
          expectedType: "array",
          actualType: typeof propEnumOptions,
          options: propEnumOptions,
          propDefinition: propDefinition,
        },
      );
    }

    if (!propEnumOptions.includes(propValueOrDefault)) {
      if (typeof propValueOrDefault === "object") {
        if (usingDefaultPropValue) {
          throw new PropValidationError(
            "Prop default value is not a valid ENUM option. Found object but was not expecting one",
            {
              propName: propName,
              componentName: componentName,
              defaultValue: propValueOrDefault,
              options: propEnumOptions,
            },
          );
        }
        throw new PropValidationError(
          "Prop value is not a valid ENUM option. Found object but was not expecting one",
          {
            propName: propName,
            componentName: componentName,
            value: propValueOrDefault,
            options: propEnumOptions,
          },
        );
      } else {
        if (usingDefaultPropValue) {
          throw new PropValidationError(
            "Prop default value is not a valid ENUM option",
            {
              propName,
              componentName,
              defaultValue: propValueOrDefault,
              options: propEnumOptions,
            },
          );
        }
        throw new PropValidationError(
          "Prop default value is not a valid ENUM option",
          {
            propName,
            componentName,
            value: propValueOrDefault,
            options: propEnumOptions,
          },
        );
      }
    }
  } else {
    if (!propDefinition.type || typeof propDefinition.type !== "function") {
      throw new PropDeclarationError(
        "Prop does not have type declaration in definition",
        {
          propName,
          componentName,
          propDefinition,
        },
      );
    }
    if (typeof propValueOrDefault !== typeof propDefinition.type()) {
      const trace = component.$getComponentStackTrace();
      const traceComponents = [];
      trace.forEach(step => traceComponents.push(step.name));
      if (usingDefaultPropValue) {
        throw new PropDeclarationError(
          `Prop has a default value that is not a valid type (here: ${traceComponents.join("->")})`,
          {
            propName: propName,
            componentName: componentName,
            propDefinition: propDefinition,
            defaultValue: propValueOrDefault,
          },
        );
      }
      throw new PropDeclarationError(
        `Prop "${propName}" in component "${componentName}" has a value "${propValueOrDefault}" that is not a valid type (here: ${traceComponents.join("->")})`,
        {
          propName: propName,
          componentName: componentName,
          propDefinition: propDefinition,
          value: propValueOrDefault,
        },
      );
    }
  }

  if (propDefinition.validator && typeof propDefinition.validator === "function") {
    if (propDefinition.validator(propValueOrDefault, component.$props) === false) {

      throw new PropDeclarationError(
        "Prop validator failed to validate prop value",
        {
          propName: propName,
          componentName: componentName,
          propDefinition: propDefinition,
          value: propValueOrDefault,
        },
      );
    }
  } else if (propDefinition.validator) {
    throw new PropDeclarationError(
      "Prop validator must be a function",
      {
        propName: propName,
        componentName: componentName,
        propDefinition: propDefinition,
        value: propValueOrDefault,
      },
    );
  }
};

const PropValidationMixin = {
  methods: {
    $getComponentStackTrace() {
      const stackTrace = [];
      let component = this;
      do {
        stackTrace.push({
          name: component.$options.name,
          file: component.$options.__file,
          scopeId: component.$options.__scopeId,
        });
        component = component.$parent;
      } while (component);
      stackTrace.reverse();
      return stackTrace;
    },
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        const componentProps = this.$options.props;
        Object.keys(componentProps).forEach(propName => {
          const propDefinition = componentProps[propName];
          const propValue = this[propName];
          validateProp(propName, propDefinition, propValue, this);
        });
      },
    },
  },
};

export default PropValidationMixin;
