<template>
  <node-view-wrapper
    class="image-container"
    draggable="true"
    data-drag-handle
    :style="{width: $props.node.attrs.width}"
  >
    <div v-if="!isReadonly" class="actions">
      <button @click="onUp">
        <iIcon icon="arrow-up" disable-hover />
      </button>
      <button @click="onDown">
        <iIcon icon="arrow-down" disable-hover />
      </button>
      <button @click="onDelete">
        <iIcon icon="trash" disable-hover />
      </button>
      <button
        v-for="(size, index) in widthOptions"
        :key="index"
        :class="{'active-size': $props.node.attrs.width === size}"
        @click="() => setWidth(size)"
      >
        {{ size }}
      </button>
    </div>
    <img :src="$props.node.attrs.src">
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";

export default {
  name: "CustomImage",
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      widthOptions: ["25%", "50%", "100%"],
    };
  },
  computed: {
    isReadonly() {
      return !this.$props.editor.isEditable;
    },
  },
  methods: {
    setWidth(width) {
      const { node, getPos } = this.$props;
      try {
        this.$props.editor.commands.setImageWidth(width, node, getPos());
      } catch (e) {
        // DO NOTHING
        // This is here to prevent the console from throwing an error when prose-mirror tries to move the node up
        // which happens because of some vue reactivity issues
      }
    },

    onUp() {
      const { editor, node, getPos } = this.$props;
      try {
        this.$props.editor.commands.moveNodeUp(editor, node, getPos());
      } catch (e) {
        // DO NOTHING
        // This is here to prevent the console from throwing an error when prose-mirror tries to move the node up
        // which happens because of some vue reactivity issues
      }
    },
    onDown() {
      const { editor, node, getPos } = this.$props;
      try {
        this.$props.editor.commands.moveNodeDown(editor, node, getPos());
      } catch (e) {
        // DO NOTHING
        // This is here to prevent the console from throwing an error when prose-mirror tries to move the node up
        // which happens because of some vue reactivity issues
      }
    },
    onDelete() {
      const { deleteNode } = this.$props;
      deleteNode();
    },
  },
};
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
.tiptap {
  .image-container {
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    position: relative;

    .actions {
      display: none;
    }

    &:hover .actions {
      display: flex;
      position: absolute;
      top: 4px;
      right: 4px;
      gap: 4px;

      button {
        padding: 8px;
        background-color: #ffffff;

        border: 1px solid #E7E5EB;
        border-radius: 8px;

        &:hover {
          cursor: pointer;
          background-color: #E7E5EB;
        }

        .active-size {
          background-color: #E7E5EB;
        }
      }
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }

  img {
    &.ProseMirror-selectednode {
      outline: 3px solid var(--purple);
    }
  }
}
</style>
