<template>
  <p class="i-paragraph">
    <slot />
  </p>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";
export default {
  name: "iParagraph",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error"],
    },
    maxWidth: {
      type: [Number, Enum],
      required: false,
      default: 800,
      options: ["fill", "hug"],
    },
    fontSize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", ...themeStructure.size.font],
    },
    textAlign: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "center", "right"],
    },
  },
  computed: {
    getFontFamily() {
      return this.$getStyles[`${this.variant}FontFamily`];
    },
    getFontSize() {
      if (this.fontSize !== "none") {
        return this.$getStyleValuePropertiesFromTheme("size.font")[this.fontSize];
      }

      return this.$getStyles[`${this.variant}FontSize`];
    },
    getFontColor() {
      return this.$getStyles[`${this.variant}FontColor`];
    },
    getFontWeight() {
      return this.$getStyles[`${this.variant}FontWeight`];
    },
    myWidth() {
      if (this.maxWidth === "fill") {
        return "100%";
      }
      if (this.maxWidth === "hug") {
        return "fit-content";
      }
      return `${this.maxWidth}px`;
    },
  },
  styleGuide: () => ({
    lineHeight: { "size.lineHeight": "standard" },

    subtleFontFamily: { "font.typeface": "standard" },
    subtleFontSize: { "size.font": "standard" },
    subtleFontColor: { "color.font": "subtle" },
    subtleFontWeight: { "font.weight": "standard" },

    accentFontFamily: { "font.typeface": "standard" },
    accentFontSize: { "size.font": "standard" },
    accentFontColor: { "color.font": "subtle" },
    accentFontWeight: { "font.weight": "standard" },

    errorFontFamily: { "font.typeface": "standard" },
    errorFontSize: { "size.font": "standard" },
    errorFontColor: { "color.font": "subtle" },
    errorFontWeight: { "font.weight": "standard" },

    standardFontFamily: { "font.typeface": "standard" },
    standardFontSize: { "size.font": "standard" },
    standardFontColor: { "color.font": "standard" },
    standardFontWeight: { "font.weight": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "standard" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
  .i-paragraph {
    font-family: v-bind(getFontFamily);
    font-size: v-bind(getFontSize);
    font-weight: v-bind(getFontWeight);
    line-height: v-bind('$getStyles.lineHeight');
    color: v-bind(getFontColor);
    text-align: v-bind(textAlign);

    margin-top: v-bind("$getStyles.marginTop");
    margin-bottom: v-bind("$getStyles.marginBottom");
    margin-left: v-bind("$getStyles.marginLeft");
    margin-right: v-bind("$getStyles.marginRight");

    max-width: v-bind('`${maxWidth}px`');
  }
</style>
