import {
  INVALID_STRING_ERROR, INVALID_INPUT_MAX_LENGTH_ERROR,
  INVALID_NUMBER_INPUT_GREATER_THAN_ZERO,
  INVALID_INPUT_MIN_LENGTH_ERROR,
} from "@/constants/error-constants";

export function validateRegex(string, pattern, error) {
  let test = string.match(pattern);
  let resultError = null;
  if (!test) {
    resultError = error;
  }
  return resultError;
}

export function validateStringInput(stringInput, minLength = null, maxLength = null) {
  if (!stringInput) {
    return INVALID_STRING_ERROR;
  }

  if (minLength && !isNaN(minLength) && stringInput.length < minLength) {
    return INVALID_INPUT_MIN_LENGTH_ERROR;
  }

  if (maxLength && !isNaN(maxLength) && stringInput.length > maxLength) {
    return INVALID_INPUT_MAX_LENGTH_ERROR;
  }
}

export function validateNumberInputMinZero(numberInput) {
  if (numberInput === "" || numberInput === 0) {
    return INVALID_NUMBER_INPUT_GREATER_THAN_ZERO;
  }
  return null;
}

export function validateEmail(email) {
  const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  if (email.length < 6 || email.length > 128) {
    return false;
  }
  return emailRegex.test(email);
}
