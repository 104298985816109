<template>
  <div class="menu-item">
    <iLink
      v-if="actionType === ACTION_TYPE_LINK_IN_SAME_WINDOW"
      :href="actionValue"
      :class="[ 'nav-link' , isActive ? 'active' : '']"
    >
      {{ label }}
    </iLink>
    <iText
      v-if="actionType === ACTION_TYPE_EXPAND_SUBMENU"
      :href="actionValue"
      :class="[ 'nav-link' , isActive ? 'active' : '']"
    >
      {{ label }}
    </iText>
    <iText
      v-if="actionType === ACTION_TYPE_BUTTON_CLICK"
      class="nav-link"
      @click.stop="$emit('click', actionValue)"
    >
      {{ label }}
    </iText>
    <iMenu
      v-if="subItems.length > 0 && (isActive || shouldDisplaySubMenu)"
      class="submenu"
      :items="subItems"
      orientation="vertical"
      :active-item="activeItem"
      :style-overrides="menuStyleOverrides"
      :menu-item-style-overrides="menuItemStyleOverrides"
      :menu-style-overrides="menuStyleOverrides"
    />
  </div>
</template>

<script>
import Enum from "@/data-types/enum";
import {
  ACTION_TYPE_LINK_IN_SAME_WINDOW,
  ACTION_TYPE_EXPAND_SUBMENU,
  ACTION_TYPE_BUTTON_CLICK,
} from "@/constants/menu-constants";

export default {
  name: "iMenuItem",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    actionValue: {
      type: String,
      required: true,
    },
    actionType: {
      type: [String, Enum],
      required: false,
      default: ACTION_TYPE_LINK_IN_SAME_WINDOW,
      options: [ACTION_TYPE_LINK_IN_SAME_WINDOW, ACTION_TYPE_EXPAND_SUBMENU, ACTION_TYPE_BUTTON_CLICK],
    },
    icon: { // discuss
      type: String,
      required: false,
      default: "",
    },
    iconPosition: { // left or right with default of left
      type: [String, Enum],
      required: false,
      default: "left",
      options: ["left", "right"],
    },
    alwaysDisplaySubItems: {
      type: Boolean,
      required: false,
      default: false,
    },
    subItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeItem: {
      type: String,
      required: false,
      default: "",
    },
    menuItemStyleOverrides: {
      type: Object,
      required: true,
    },
    menuStyleOverrides: {
      type: Object,
      required: true,
    },
  },
  emits: ["click"],
  styleGuide: () => ({
    navLinkFontFamily: { "font.typeface": "standard" },
    navLinkFontSize: { "size.font": "standard" },
    navLinkFontColor: { "color.font": "standard" },
    navLinkFontWeight: { "font.weight": "standard" },
    activeNavLinkFontColor: { "color.font": "standard" },
    activeFontWeight: { "font.weight": "dark" },
    menuItemPaddingTop: { "size.spacing": "none" },
    menuItemPaddingBottom: { "size.spacing": "none" },
    menuItemPaddingLeft: { "size.spacing": "small" },
    menuItemPaddingRight: { "size.spacing": "small" },
    subMenuPaddingTop: { "size.spacing": "none" },
    subMenuPaddingBottom: { "size.spacing": "none" },
    subMenuPaddingLeft: { "size.spacing": "small" },
    subMenuPaddingRight: { "size.spacing": "small" },
  }),
  data() {
    return {
      ACTION_TYPE_LINK_IN_SAME_WINDOW,
      ACTION_TYPE_EXPAND_SUBMENU,
      ACTION_TYPE_BUTTON_CLICK,
    };
  },
  computed: {
    shouldDisplaySubMenu() {
      if (this.subItems && this.subItems.length > 0) {
        if (this.isActive || this.alwaysDisplaySubItems) {
          return true;
        } else {
          return this.subMenuHasActiveItem(this.subItems);
        }
      } else {
        return false;
      }
    },

    isLinkInSameWindow() {
      return this.actionType === ACTION_TYPE_LINK_IN_SAME_WINDOW;
    },
  },
  methods: {
    subMenuHasActiveItem(subItems) {
      return subItems.length > 0 && subItems.find(item => {
        if (item.name === this.activeItem) {
          return true;
        }
        if (item.subItems && item.subItems.length > 0) {
          return this.subMenuHasActiveItem(item.subItems);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.nav-link {
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  // width: 100%;
  color: v-bind('$getStyles.navLinkFontColor');
  font-size: v-bind("$getStyles.navLinkFontSize");
  font-family: v-bind("$getStyles.navLinkFontFamily");
  font-weight: v-bind("$getStyles.navLinkFontWeight");
}

.nav-link.active {
  color: v-bind('$getStyles.activeNavLinkFontColor');
  font-weight: v-bind("$getStyles.activeFontWeight");
}

.submenu {
  padding-top: v-bind('$getStyles.subMenuPaddingTop');
  padding-bottom: v-bind('$getStyles.subMenuPaddingBottom');
  padding-left: v-bind('$getStyles.subMenuPaddingLeft');
  padding-right: v-bind('$getStyles.subMenuPaddingRight');
}

.menu-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: v-bind('$getStyles.menuItemPaddingTop');
  padding-bottom: v-bind('$getStyles.menuItemPaddingBottom');
  padding-left: v-bind('$getStyles.menuItemPaddingLeft');
  padding-right: v-bind('$getStyles.menuItemPaddingRight');

    // a {
    //     width: 100%;
    // }
}
</style>
