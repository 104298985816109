<template>
  <iColumn width="hug">
    <iText class="reaction-picker-button" font-size="large" @click="toggleModal">
      {{ buttonText }}
    </iText>
  </iColumn>

  <iModal
    :show-footer="false"
    :show-header="false"
    :visible="showModal"
    :width="400"
    scrollable
    @close="toggleModal"
  >
    <template #body>
      <iTextInput
        v-model="searchTerm"
        class="reaction-search-input"
        placeholder="Search reactions..."
        width="fill"
      />

      <iRow gap="large" align="center">
        <iColumn
          v-for="reaction in filteredReactions"
          :key="reaction.icon"
          :animations="[scaleAnimation]"
          width="hug"
        >
          <iButton variant="tertiary" @click="selectReaction(reaction.icon)">
            <iText font-size="large">
              {{ reaction.icon }}
            </iText>
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
export default {
  name: "iReactionPicker",
  props: {
    buttonText: {
      type: String,
      required: false,
      default: "😊",
    },
  },
  emits: ["reaction-selected"],
  data() {
    return {
      showModal: false,
      searchTerm: "",
      scaleAnimation: {
        effect: "scale",
        options: {
          duration: 200,
          to: 1.2,
        },
        onAction: "hover",
      },
      reactions: [
        { icon: "😊", name: "smiling face with smiling eyes" },
        { icon: "😍", name: "smiling face with heart-eyes" },
        { icon: "😂", name: "face with tears of joy" },
        { icon: "🥺", name: "pleading face" },
        { icon: "🤣", name: "rolling on the floor laughing" },
        { icon: "❤️", name: "red heart" },
        { icon: "😎", name: "smiling face with sunglasses" },
        { icon: "👍", name: "thumbs up" },
        { icon: "🔥", name: "fire" },
        { icon: "💯", name: "hundred points" },
        { icon: "🎉", name: "party popper" },
        { icon: "😢", name: "crying face" },
        { icon: "👏", name: "clapping hands" },
        { icon: "😡", name: "pouting face" },
        { icon: "🙌", name: "raising hands" },
        { icon: "🙏", name: "folded hands" },
        { icon: "💪", name: "flexed biceps" },
        { icon: "😇", name: "smiling face with halo" },
        { icon: "🌟", name: "glowing star" },
        { icon: "✨", name: "sparkles" },
        { icon: "💥", name: "collision" },
        { icon: "💔", name: "broken heart" },
        { icon: "🎶", name: "musical notes" },
        { icon: "💤", name: "zzz" },
        { icon: "😴", name: "sleeping face" },
        { icon: "😋", name: "face savoring food" },
        { icon: "😜", name: "winking face with tongue" },
        { icon: "🤔", name: "thinking face" },
        { icon: "😱", name: "face screaming in fear" },
        { icon: "😤", name: "face with steam from nose" },
        { icon: "🤯", name: "exploding head" },
        { icon: "🤩", name: "star-struck" },
        { icon: "💡", name: "light bulb" },
        { icon: "🎁", name: "wrapped gift" },
        { icon: "🥳", name: "partying face" },
        { icon: "😏", name: "smirking face" },
        { icon: "👀", name: "eyes" },
        { icon: "🚀", name: "rocket" },
        { icon: "🎈", name: "balloon" },
        { icon: "🍕", name: "pizza" },
        { icon: "🌈", name: "rainbow" },
        { icon: "⚡", name: "high voltage" },
        { icon: "⛄", name: "snowman" },
        { icon: "🌸", name: "cherry blossom" },
        { icon: "🍀", name: "four-leaf clover" },
        { icon: "🌞", name: "sun with face" },
        { icon: "🌙", name: "crescent moon" },
        { icon: "🧠", name: "brain" },
        { icon: "🦄", name: "unicorn" },
        { icon: "💻", name: "laptop" },
        { icon: "📱", name: "mobile phone" },
        { icon: "🎮", name: "video game" },
        { icon: "🎤", name: "microphone" },
        { icon: "🏆", name: "trophy" },
        { icon: "📚", name: "books" },
        { icon: "🍔", name: "hamburger" },
        { icon: "🍩", name: "doughnut" },
        { icon: "🚴", name: "person biking" },
        { icon: "🏖️", name: "beach with umbrella" },
        { icon: "🎸", name: "guitar" },
        { icon: "⚽", name: "soccer ball" },
        { icon: "🏀", name: "basketball" },
        { icon: "🥇", name: "1st place medal" },
        { icon: "💎", name: "gem stone" },
      ],
    };
  },
  computed: {
    filteredReactions() {
      if (!this.searchTerm) {
        return this.reactions;
      }
      return this.reactions.filter(
        reaction => reaction.icon.includes(this.searchTerm) ||
          reaction.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectReaction(reactionIcon) {
      this.$emit("reaction-selected", reactionIcon);
      this.toggleModal();
    },
  },
};
</script>

<style scoped>
.reaction-picker-button {
  cursor: pointer;
  position: relative;
  bottom: 1px;
}
</style>
