<template>
  <iColumn
    :class="{
      card: true,
      disabled: disabled,
      'card-wrapper': true,
      focused: focused,
      'focused-on-hover': focusedOnHover,
    }"
    :height="height"
    :max-width="maxWidth"
    :width="width"
    class="i-styled-card"
    gap="extraSmall"
  >
    <slot name="header">
      <component
        :is="isComponent('splash-image')"
        v-if="shouldDisplayHeader"
        :class="hasClickEvent('splash-image') ? 'cursor-pointer' : ''"
        :href="splashImageHref || defaultHref"
        class="card-header v-component"
        draggable="false"
        @click="triggerEmit($event, 'click:splash-image')"
      >
        <div class="card-splash-container card-header-container">
          <slot name="splash-container">
            <img
              v-if="shouldDisplaySplashImage"
              :src="splashImage"
              alt="Card Header"
              class="card-header-image"
            >
          </slot>
        </div>
        <component
          :is="isComponent('badge')"
          v-if="shouldDisplayBadge"
          :class="hasClickEvent('badge') ? 'cursor-pointer' : ''"
          :href="badgeHref || defaultHref"
          class="card-badge v-component"
          draggable="false"
          @click.stop="triggerEmit($event, 'click:badge')"
        >
          <iRow vertical-align="middle" wrap="nowrap">
            <iIcon icon="info-circle" disable-hover />
            <span class="card-badge-text">
              {{ badge }}
            </span>
          </iRow>
        </component>
      </component>
    </slot>
    <iColumn class="card-body" gap="extraSmall">
      <slot name="title-section">
        <iColumn v-if="shouldDisplayTitleDetails" class="card-title-section" gap="extraSmall">
          <component
            :is="isComponent('title')"
            v-if="shouldDisplayTitle"
            :class="hasClickEvent('title') ? 'cursor-pointer' : ''"
            :href="titleHref || defaultHref"
            class="card-title v-component"
            draggable="false"
            @click="triggerEmit($event,'click:title')"
          >
            <slot name="title">
              <iRow vertical-align="middle">
                <slot name="before-title" />
                <span class="card-title-text">
                  <slot name="prepend-title" />
                  {{ title }}
                  <slot name="append-title" />
                </span>
                <slot name="after-title" />
              </iRow>
            </slot>
          </component>
          <component
            :is="isComponent('subtitle')"
            v-if="shouldDisplaySubtitle"
            :class="hasClickEvent('subtitle') ? 'cursor-pointer' : 'no-pointer'"
            :href="subtitleHref || defaultHref"
            class="card-subtitle v-component"
            draggable="false"
            @click="triggerEmit($event,'click:subtitle')"
          >
            <slot name="subtitle">
              <span class="card-subtitle-text">
                {{ subtitle }}
              </span>
            </slot>
          </component>
        </iColumn>
      </slot>
      <slot name="author">
        <iRow v-if="shouldDisplayAuthor" wrap="nowrap">
          <iRow
            class="card-author-section"
            gap="extraSmall"
            vertical-align="middle"
            wrap="nowrap"
          >
            <iColumn v-if="shouldDisplayAuthorAvatar" class="card-author-avatar" width="hug">
              <component
                :is="isComponent('author-avatar')"
                :class="hasClickEvent('author-avatar') ? 'cursor-pointer' : ''"
                :href="authorAvatarHref || defaultHref"
                class="card-avatar-wrapper v-component"
                draggable="false"
                @click="triggerEmit($event,'click:author-avatar')"
              >
                <img :src="authorAvatar" alt="Card Author" class="card-author-avatar-img">
              </component>
            </iColumn>
            <iColumn v-if="shouldDisplayAuthorDetails" class="card-author-info" gap="extraSmall">
              <component
                :is="isComponent('author-name')"
                v-if="shouldDisplayAuthorName"
                :class="hasClickEvent('author-name') ? 'cursor-pointer' : ''"
                :href="authorNameHref || defaultHref"
                class="card-author-name v-component"
                draggable="false"
                @click="triggerEmit($event,'click:author-name')"
              >
                <span class="card-author-name-text">
                  {{ authorName }}
                </span>
              </component>
              <component
                :is="isComponent('author-description')"
                v-if="shouldDisplayAuthorDescription"
                :class="hasClickEvent('author-description') ? 'cursor-pointer' : ''"
                :href="authorDescriptionHref || defaultHref"
                class="card-author-description v-component"
                draggable="false"
                @click="triggerEmit($event,'click:author-description')"
              >
                <span class="card-author-description-text">
                  {{ authorDescription }}
                </span>
              </component>
            </iColumn>
          </iRow>
          <slot name="author-tag">
            <iColumn v-if="shouldDisplayAuthorAuthorTag" width="hug">
              <component
                :is="isComponent('author-tag')"
                :class="hasClickEvent('author-tag') ? 'cursor-pointer' : ''"
                :href="authorTagHref || defaultHref"
                class="card-author-author-tag v-component"
                draggable="false"
                @click="triggerEmit($event,'click:author-tag')"
              >
                <span class="card-author-author-tag-text">
                  {{ authorTag }}
                </span>
              </component>
            </iColumn>
          </slot>
        </iRow>
      </slot>
      <slot name="description">
        <component
          :is="isComponent('description')"
          v-if="shouldDisplayDescription"
          :class="hasClickEvent('description') ? 'cursor-pointer' : ''"
          :href="descriptionHref || defaultHref"
          class="card-description v-component"
          draggable="false"
          @click="triggerEmit($event,'click:description')"
        >
          <p class="card-description-text">
            {{ truncated(description) }}
          </p>
        </component>
      </slot>
      <slot name="tags">
        <iRow v-if="shouldDisplayTags" class="card-tags" gap="extraSmall">
          <component
            :is="isComponent('tag', tag.href || defaultHref)"
            v-for="tag in tags"
            :key="tag.name"
            :class="hasClickEvent('tag') ? 'cursor-pointer' : ''"
            :href="tag.href || defaultHref"
            class="card-tag-item v-component"
            draggable="false"
            @click="triggerEmit($event,'click:tag', tag)"
          >
            <span class="card-tag-text">
              {{ tag.name }}
            </span>
          </component>
        </iRow>
      </slot>
      <slot name="metrics">
        <div v-if="shouldDisplayMetrics" class="card-metrics">
          <component
            :is="isComponent('metric', metric.href || defaultHref)"
            v-for="metric in metrics"
            :key="metric.name"
            :class="hasClickEvent('metric') ? 'cursor-pointer' : ''"
            :href="metric.href || defaultHref"
            class="card-metric-item v-component"
            draggable="false"
            @click="triggerEmit($event,'click:metric', metric)"
          >
            <iRow gap="extraSmall" vertical-align="middle" wrap="nowrap">
              <iIcon v-if="metric.icon" :icon="metric.icon" :variant="metric.iconVariant || 'primary'" />
              <span class="card-metric-value">
                {{ metric.value }}
              </span>
              <span class="card-metric-name">
                {{ metric.name }}
              </span>
            </iRow>
          </component>
        </div>
      </slot>
      <slot name="footer">
        <!-- Footer content here -->
      </slot>
    </iColumn>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
import PropValidationError from "@/errors/prop-validation-error";
import { PROP_MAP } from "@/constants/styled-card-prop-map";

export default {
  name: "iStyledCard",
  props: {
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    splashImage: {
      type: String,
      required: false,
      default: "",
    },
    splashImageHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    badge: {
      type: String,
      required: false,
      default: "",
    },
    badgeHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    titleHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    subtitleHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    authorTag: {
      type: String,
      required: false,
      default: "",
    },
    authorTagHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    descriptionHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    authorAvatar: {
      type: String,
      required: false,
      default: "",
    },
    authorAvatarHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    authorName: {
      type: String,
      required: false,
      default: "",
    },
    authorNameHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    authorDescription: {
      type: String,
      required: false,
      default: "",
    },
    authorDescriptionHref: {
      type: [String, Enum],
      required: false,
      default: "",
      options: ["none"],
    },
    metrics: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultHref: {
      type: [String, Enum],
      required: false,
      default: "none",
      options: ["none"],
    },
    descriptionLength: {
      type: Number,
      required: false,
      default: 100,
    },
    splashImageMaxHeight: {
      type: Number,
      required: false,
      default: 300,
    },
    splashImageMinHeight: {
      type: Number,
      required: false,
      default: 100,
    },
    authorAvatarSize: {
      type: Number,
      required: false,
      default: 40,
    },
    focused: {
      type: Boolean,
      required: false,
      default: false,
    },
    pointerCursorOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    focusedOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
    singleColumnMetrics: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myHoverCursor() {
      return this.pointerCursorOnHover ? "pointer" : "inherit";
    },
    shouldDisplayHeader() {
      return this.splashImage || this.badge || this.$slots?.["splash-container"];
    },
    shouldDisplaySplashImage() {
      return this.splashImage;
    },
    shouldDisplayAuthor() {
      return this.authorAvatar || this.authorName || this.authorDescription || this.authorTag;
    },
    shouldDisplayAuthorAvatar() {
      return this.authorAvatar;
    },
    shouldDisplayAuthorDetails() {
      return this.authorName || this.authorDescription;
    },
    shouldDisplayAuthorName() {
      return this.authorName;
    },
    shouldDisplayAuthorDescription() {
      return this.authorDescription;
    },
    shouldDisplayAuthorAuthorTag() {
      return this.authorTag;
    },
    shouldDisplayDescription() {
      return this.description;
    },
    shouldDisplayTitleDetails() {
      return !!(this.title || this.subtitle || this.$slots.title || this.$slots.subtitle);
    },
    shouldDisplayTitle() {
      return !!(this.title || this.$slots.title);
    },
    shouldDisplaySubtitle() {
      return !!(this.subtitle || this.$slots.subtitle);
    },
    shouldDisplayMetrics() {
      return this.metrics.length > 0;
    },
    shouldDisplayTags() {
      return this.tags.length > 0;
    },
    shouldDisplayBadge() {
      return this.badge;
    },
    mySplashImageMaxHeight() {
      return `${this.splashImageMaxHeight}px`;
    },
    mySplashImageMinHeight() {
      return `${this.splashImageMinHeight}px`;
    },
    myAuthorAvatarSize() {
      return `${this.authorAvatarSize}px`;
    },
    myCardHeaderBottomMargin() {
      if (this.shouldDisplaySplashImage) {
        return 0;
      }
      return this.$getStyleValuePropertiesFromTheme("size.spacing")["small"];
    },
    myMetricColumns() {
      return this.singleColumnMetrics ? 1 : 2;
    },
  },
  watch: {
    $props: {
      handler() {
        this.validateProps();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    validateProps() {
      const hrefProps = Object.keys(this.$props).filter(prop => prop.includes("Href"));
      hrefProps.forEach(prop => {
        if (this[prop] && PROP_MAP[prop] && this.$attrs[PROP_MAP[prop]]) {
          throw new PropValidationError("iStyledCard", `Cannot have both a ${prop} and ${PROP_MAP[prop]}`);
        }
      });
    },
    snakeToCamel(str) {
      return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
    },
    hasClickEvent(key) {
      return this.$attrs && this.$attrs[`onClick:${this.snakeToCamel(key)}`];
    },
    isComponent(key, href) {
      href = href || this?.[`${this.snakeToCamel(key)}Href`];
      if (this.$attrs && this.$attrs[`onClick:${this.snakeToCamel(key)}`]) {
        return "div";
      }
      if (href === "none") {
        return "div";
      }
      if (!href && this.defaultHref === "none") {
        return "div";
      }

      return "a";
    },
    truncated(text) {
      if (text.length > this.descriptionLength) {
        return text.slice(0, this.descriptionLength) + "...";
      }
      return text;
    },
    triggerEmit(event, name, data) {
      this.$emit(name, { data, event });
    },
  },
  styleGuide: () => ({
    linkDecoration: { "font.decorations": "none" },

    cardHeaderBorderRadius: { "size.borderRadius": "large" },

    badgeBorderRadius: { "size.borderRadius": "large" },
    badgeBorderSize: { "size.border": "thin" },
    badgeBorderColor: { "color.border": "light" },
    badgePaddingTop: { "size.spacing": "small" },
    badgePaddingRight: { "size.spacing": "small" },
    badgePaddingBottom: { "size.spacing": "small" },
    badgePaddingLeft: { "size.spacing": "small" },
    badgeFontSize: { "size.font": "standard" },
    badgeFontColor: { "color.font": "standard" },
    badgeBackgroundColor: { "color.background": "paper" },
    badgeBackgroundHighlightColor: { "color.background": "subtle" },

    cardPaddingLeft: { "size.spacing": "standard" },
    cardPaddingRight: { "size.spacing": "standard" },
    cardPaddingTop: { "size.spacing": "none" },
    cardPaddingBottom: { "size.spacing": "small" },

    cardTitleMarginBottom: { "size.spacing": "extraSmall" },
    cardTitleFontSize: { "size.font": "extraLarge" },
    cardTitleFontWeight: { "font.weight": "dark" },
    cardTitleFontColor: { "color.font": "standard" },
    cardTitleHoverDecoration: { "font.decorations": "none" },

    cardSubtitleFontSize: { "size.font": "standard" },
    cardSubtitleFontColor: { "color.font": "subtle" },

    cardAuthorMarginBottom: { "size.spacing": "small" },

    cardAuthorMarginLeft: { "size.spacing": "standard" },

    cardAuthorNameFontSize: { "size.font": "small" },
    cardAuthorNameFontWeight: { "font.weight": "dark" },
    cardAuthorNameFontColor: { "color.font": "standard" },

    cardAuthorDescriptionFontSize: { "size.font": "verySmall" },
    cardAuthorDescriptionFontColor: { "color.font": "subtle" },

    cardAuthorAuthorTagFontSize: { "size.font": "small" },
    cardAuthorAuthorTagFontColor: { "color.font": "subtle" },

    cardDescriptionFontSize: { "size.font": "standard" },
    cardDescriptionFontColor: { "color.font": "standard" },

    cardDescriptionMarginBottom: { "size.spacing": "small" },
    cardDescriptionLineHeight: { "size.lineHeight": "standard" },

    cardTagBackgroundColor: { "color.background": "light" },
    cardTagBorderRadius: { "size.borderRadius": "large" },
    cardTagPaddingLeft: { "size.spacing": "standard" },
    cardTagPaddingRight: { "size.spacing": "standard" },
    cardTagPaddingTop: { "size.spacing": "extraSmall" },
    cardTagPaddingBottom: { "size.spacing": "extraSmall" },
    cardTagMarginRight: { "size.spacing": "extraSmall" },
    cardTagMarginBottom: { "size.spacing": "extraSmall" },
    cardTagFontSize: { "size.font": "small" },
    cardTagFontColor: { "color.font": "standard" },
    cardTagBackgroundHoverColor: { "color.background": "subtle" },

    cardMetricsMarginBottom: { "size.spacing": "extraSmall" },
    cardMetricsColumnGap: { "size.spacing": "standard" },
    cardMetricValueFontSize: { "size.font": "standard" },
    cardMetricValueFontWeight: { "font.weight": "standard" },
    cardMetricValueFontColor: { "color.font": "standard" },
    cardMetricNameFontSize: { "size.font": "standard" },
    cardMetricNameFontColor: { "color.font": "standard" },

    cardFocusBorderColor: { "color.font": "branded" },
    cardFocusBorderWidth: { "size.border": "standard" },
    cardFocusBorderRadius: { "size.borderRadius": "large" },

    cardBorderColor: { "color.border": "none" },
    cardBorderWidth: { "size.border": "standard" },
    cardBorderRadius: { "size.borderRadius": "large" },
  }),
};
</script>

<style lang="scss" scoped>
.v-component {
  cursor: v-bind(myHoverCursor);
  -webkit-user-drag: none;
}

.cursor-pointer {
  cursor: pointer;
}
.no-pointer {
  cursor: unset;
}


p {
  margin: 0;
}

a {
  text-decoration: v-bind("$getStyles.linkDecoration") !important;
  color: inherit;
}

.card-wrapper {
  width: 100%;
  height: auto;
  border-color: v-bind("$getStyles.cardBorderColor") !important;
  border-width: v-bind("$getStyles.cardBorderWidth") !important;
  border-radius: v-bind("$getStyles.cardBorderRadius") !important;
  word-break: break-word;

  &.card {
  }

  .card-header {
    width: 100%;
    height: auto;
    min-height: 40px;
    position: relative;
    margin-bottom: v-bind(myCardHeaderBottomMargin);

    .card-header-container {
      width: 100%;
      height: auto;
      border-radius: v-bind("$getStyles.cardHeaderBorderRadius");
      overflow: visible;
    }

    img.card-header-image {
      width: 100%;
      height: auto;
      max-height: v-bind(mySplashImageMaxHeight);
      min-height: v-bind(mySplashImageMinHeight);
      border-radius: v-bind("$getStyles.cardHeaderBorderRadius");
      object-fit: cover;
    }

    .card-badge {
      position: absolute;
      top: 8px;
      left: 8px;
      background-color: v-bind("$getStyles.badgeBackgroundColor");
      border-radius: v-bind("$getStyles.badgeBorderRadius");
      border-width: v-bind("$getStyles.badgeBorderSize");
      border-style: solid;
      border-color: v-bind("$getStyles.badgeBorderColor");
      padding-left: v-bind("$getStyles.badgePaddingLeft");
      padding-right: v-bind("$getStyles.badgePaddingRight");
      padding-top: v-bind("$getStyles.badgePaddingTop");
      padding-bottom: v-bind("$getStyles.badgePaddingBottom");
      z-index: v-bind(zIndex);

      .card-badge-text {
        font-size: v-bind("$getStyles.badgeFontSize");
        color: v-bind("$getStyles.badgeFontColor");
      }

      &:hover {
        background-color: v-bind("$getStyles.badgeBackgroundHighlightColor");
      }
    }
  }

  .card-body {
    padding-left: v-bind("$getStyles.cardPaddingLeft");
    padding-right: v-bind("$getStyles.cardPaddingRight");
    padding-top: v-bind("$getStyles.cardPaddingTop");
    padding-bottom: v-bind("$getStyles.cardPaddingBottom");
  }

  .card-title-section {
    .card-title {
      width: 100%;
      font-size: v-bind("$getStyles.cardTitleFontSize");
      font-weight: v-bind("$getStyles.cardTitleFontWeight");
      color: v-bind("$getStyles.cardTitleFontColor");

      &:hover {
        text-decoration: v-bind("$getStyles.cardTitleHoverDecoration");
      }

      .card-title-text {
        display: inline-block;
      }
    }

    .card-subtitle {
      font-size: v-bind("$getStyles.cardSubtitleFontSize");
      color: v-bind("$getStyles.cardSubtitleFontColor");

      .card-subtitle-text {
        display: inline-block;
      }
    }
  }

  .card-author-section {
    margin-bottom: v-bind("$getStyles.cardAuthorMarginBottom");

    .card-author-avatar {

      img.card-author-avatar-img {
        width: v-bind(myAuthorAvatarSize);
        height: v-bind(myAuthorAvatarSize);
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .card-author-info {
      margin-left: v-bind("$getStyles.cardAuthorMarginLeft");

      .card-author-name {
        font-size: v-bind("$getStyles.cardAuthorNameFontSize");
        font-weight: v-bind("$getStyles.cardAuthorNameFontWeight");
        color: v-bind("$getStyles.cardAuthorNameFontColor");

        .card-author-name-text {
          display: inline-block;
        }
      }

      .card-author-description {
        font-size: v-bind("$getStyles.cardAuthorDescriptionFontSize");
        color: v-bind("$getStyles.cardAuthorDescriptionFontColor");

        .card-author-description-text {
          display: inline-block;
        }
      }
    }
  }

  .card-author-author-tag {
    font-size: v-bind("$getStyles.cardAuthorAuthorTagFontSize");
    color: v-bind("$getStyles.cardAuthorAuthorTagFontColor");

    .card-author-author-tag-text {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .card-description {
    font-size: v-bind("$getStyles.cardDescriptionFontSize");
    color: v-bind("$getStyles.cardDescriptionFontColor");
    margin-bottom: v-bind("$getStyles.cardDescriptionMarginBottom");

    .card-description-text {
      line-height: v-bind("$getStyles.cardDescriptionLineHeight");
      word-break: break-word;
    }
  }

  .card-tags {
    margin-bottom: v-bind("$getStyles.cardDescriptionMarginBottom");

    .card-tag-item {
      background-color: v-bind("$getStyles.cardTagBackgroundColor");
      border-radius: v-bind("$getStyles.cardTagBorderRadius");
      padding-left: v-bind("$getStyles.cardTagPaddingLeft");
      padding-right: v-bind("$getStyles.cardTagPaddingRight");
      padding-top: v-bind("$getStyles.cardTagPaddingTop");
      padding-bottom: v-bind("$getStyles.cardTagPaddingBottom");
      margin-right: v-bind("$getStyles.cardTagMarginRight");
      margin-bottom: v-bind("$getStyles.cardTagMarginBottom");

      .card-tag-text {
        font-size: v-bind("$getStyles.cardTagFontSize");
        color: v-bind("$getStyles.cardTagFontColor");
      }

      &:hover {
        background-color: v-bind("$getStyles.cardTagBackgroundHoverColor");
      }
    }
  }

  .card-metrics {
    display: grid;
    grid-template-columns: repeat(v-bind(myMetricColumns), 1fr);
    margin-bottom: v-bind("$getStyles.cardMetricsMarginBottom");
    column-gap: v-bind("$getStyles.cardMetricsColumnGap");
    width: 100%;

    .card-metric-item {
      justify-content: space-between;
      width: fit-content;
      margin-bottom: v-bind("$getStyles.cardMetricsMarginBottom");

      .card-metric-value {
        font-size: v-bind("$getStyles.cardMetricValueFontSize");
        font-weight: v-bind("$getStyles.cardMetricValueFontWeight");
        color: v-bind("$getStyles.cardMetricValueFontColor");
        white-space: nowrap;
      }

      .card-metric-name {
        font-size: v-bind("$getStyles.cardMetricNameFontSize");
        color: v-bind("$getStyles.cardMetricNameFontColor");
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.card-wrapper.focused {
  border-color: v-bind("$getStyles.cardFocusBorderColor") !important;
  border-width: v-bind("$getStyles.cardFocusBorderWidth") !important;
  border-radius: v-bind("$getStyles.cardFocusBorderRadius") !important;
}

.card-wrapper.focused-on-hover:hover {
  border-color: v-bind("$getStyles.cardFocusBorderColor") !important;
  border-width: v-bind("$getStyles.cardFocusBorderWidth") !important;
  border-radius: v-bind("$getStyles.cardFocusBorderRadius") !important;
}


</style>
