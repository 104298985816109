<template>
  <iTextInput
    v-model="content"
    :name="name"
    :focus="focus"
    :placeholder="placeholder"
    class="default-editor"
    width="fill"
    @enter="$emit('enter')"
  />
</template>

<script>
export default {
  name: "DefaultEditor",
  props: {
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:modelValue", "enter"],
  data() {
    return {
      content: this.modelValue,
    };
  },
  watch: {
    modelValue(value) {
      this.content = value;
    },
    content(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
