<template>
  <iSubHeading>Select</iSubHeading>
  <iRow>
    <iSelect
      :id="1"
      v-model="selectedOne"
      :items="items"
      label="Select Single"
      text-field="name"
      value-field="id"
      :width="300"
      :multiple="false"
      show-empty-state
    />
  </iRow>

  <iRow>
    <iSelect
      :id="2"
      v-model="selectedTwo"
      :items="items"
      label="Select Single Chips"
      text-field="name"
      value-field="id"
      :width="300"
      :multiple="false"
      chips
      show-empty-state
    />
  </iRow>

  <iRow>
    <iSelect
      :id="3"
      v-model="selectedThree"
      :items="items"
      label="Select Multiple"
      text-field="name"
      value-field="id"
      multiple
      show-empty-state
    />
  </iRow>

  <iRow>
    <iSelect
      :id="4"
      v-model="selectedFour"
      :items="items"
      label="Select Multiple Chips"
      text-field="name"
      value-field="id"
      :width="450"
      chips
      multiple
      show-empty-state
    />
  </iRow>

  <iRow>
    <iSelect
      :id="3"
      v-model="selectedThree"
      :items="items"
      label="Select Multiple"
      text-field="name"
      value-field="id"
      :is-loading="true"
      multiple
      show-empty-state
    />
  </iRow>
</template>

<script>
import iRow from "@/components/layout/iRow";
import iSelect from "@/components/forms/iSelect";
import iSubHeading from "@/components/text/iSubHeading";

export default {
  name: "SelectDocs",
  components: {
    iSelect,
    iRow,
    iSubHeading,
  },
  data() {
    return {
      components: {
        iRow,
        iSelect,
      },
      items: [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
        { id: 4, name: "Item 4" },
        { id: 5, name: "Item 5" },
      ],
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      multiple: false,
      showEmptyState: true,
    };
  },
};
</script>
