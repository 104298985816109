import Image from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

import CustomImage from "@/components/widgets/SubComponents/post-editor/CustomImage";

// This is an extension on the Image extension from tiptap. It allows us to add the custom image
// container component to the editor and it's custom commands.
export default Image.extend({
  name: "CustomImage",

  content: "block*",

  // Required to make the container draggable
  draggable: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      width: {
        default: "100%",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "img[src]",
      },
    ];
  },

  // Allows the renderer to convert the container to an img tag with its custom attributes
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes, { "width": HTMLAttributes.width, "data-type": "draggable-item" }), 0];
  },

  // Required to render the custom Vue component
  addNodeView() {
    return VueNodeViewRenderer(CustomImage);
  },
});
