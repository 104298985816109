<template>
  <iColumn v-click-outside="() => showAddHashtag = false">
    <iRow vertical-align="middle">
      <iRow
        v-for="(item, index) in modelValue"
        :key="index"
        vertical-align="middle"
        gap="extraSmall"
        width="hug"
        wrap="nowrap"
      >
        <iText variant="branded">
          #{{ getHashTagValue(item) }}
        </iText>
        <iIcon
          v-if="edit"
          icon="close"
          size="small"
          variant="error"
          @click="removeHashtag(hashtag, index)"
        />
      </iRow>
      <iRow
        v-if="edit && !showAddHashtag"
        vertical-align="middle"
        gap="extraSmall"
        width="hug"
        wrap="nowrap"
      >
        <iButton class="add-hashtag-btn" variant="tertiary" @click="showAddHashtag = true">
          <iRow vertical-align="middle">
            <iIcon
              icon="plus"
              size="small"
            />
            <iText>Add a hashtag</iText>
          </iRow>
        </iButton>
      </iRow>
    </iRow>
    <iTextInput
      v-if="edit && showAddHashtag"
      v-model="hashtag"
      :placeholder="placeholder"
      focus
      width="fill"
      @input="handleHashtagInput"
      @enter="handleEnter"
    />
    <iText
      v-if="validationMessage"
      variant="error"
    >
      {{ validationMessage }}
    </iText>
  </iColumn>
</template>

<script>
export default {
  name: "iHashtagInput",
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: () => [],
    },
    hashtagValueKey: {
      type: String,
      default: "value",
      required: false,
    },
    splitHashTagsBy: {
      type: Array,
      default: () => [" ", ","],
      required: false,
    },
    placeholder: {
      type: String,
      default: "Add a hashtag",
      required: false,
    },
    allowDuplicates: {
      type: Boolean,
      default: false,
      required: false,
    },
    edit: {
      type: Boolean,
      default: true,
      required: false,
    },
    validation: {
      type: Function,
      required: false,
      default: () => true,
    },
  },
  emits: ["update:modelValue", "submit:hashtag", "delete:hashtag"],
  data() {
    return {
      hashtag: "",
      showAddHashtag: false,
      validationMessage: "",
    };
  },
  watch: {
    hashtag(newValue) {
      this.runValidation(this.formatHashtag(newValue));
    },
    showAddHashtag(newValue) {
      if (!newValue) {
        this.clearHashtag();
      }
    },
  },
  methods: {
    updateHashtag(value) {
      if (this.validationMessage) {
        return;
      }
      if (!value) {
        return;
      }

      const formattedValue = {
        [this.hashtagValueKey]: value,
      };

      this.$emit("update:modelValue", [...this.modelValue, formattedValue]);
      this.$emit("submit:hashtag", value);
    },
    checkForDuplicates(value) {
      const hashTags = this.modelValue.map(item => this.getHashTagValue(item));
      return hashTags.includes(value);
    },
    formatHashtag(value) {
      let hash = value;
      if (this.splitHashTagsBy.includes(this.hashtag.split("").at(-1))) {
        hash = value.substring(0, this.hashtag.length - 1);
      }
      if (hash.startsWith("#")) {
        hash = hash.substring(1);
      }
      return hash.trim().toLowerCase();
    },
    handleHashtagInput(e) {
      if (e.inputType === "insertFromPaste") {
        return;
      }
      if (!this.hashtag) {
        this.validationMessage = "";
        return;
      }
      if (this.validationMessage) {
        return;
      }
      if (this.splitHashTagsBy.includes(this.hashtag.split("").at(-1))) {
        this.updateHashtag(this.formatHashtag(this.hashtag));
        this.clearHashtag();
      }
    },
    runValidation(value) {
      if (!value && !this.hashtag) {
        this.validationMessage = "";
        return;
      }
      const validationResult = this.validation(value || this.hashtag);
      if (typeof validationResult === "string") {
        this.validationMessage = validationResult;
      } else {
        this.validationMessage = "";
      }
      if (!this.allowDuplicates) {
        if (this.checkForDuplicates(value || this.hashtag)) {
          this.validationMessage = "Duplicate hashtags are not allowed";
        }
      }
    },
    // handleHashtagPaste(event) {
    //   const clipboardData = event.clipboardData || window.clipboardData;
    //   const pastedData = clipboardData.getData("Text");
    //   console.log(pastedData)
    // },
    clearHashtag() {
      this.hashtag = "";
      this.validationMessage = "";
    },
    removeHashtag(hashtag, index) {
      const hashtags = [...this.modelValue];
      const removedElement = hashtags.splice(index, 1);
      this.$emit("update:modelValue", hashtags);
      this.$emit("delete:hashtag", {
        el: removedElement[0],
        index,
      });
    },
    handleEnter() {
      this.updateHashtag(this.hashtag);
      this.clearHashtag();
    },
    getHashTagValue(item) {
      if (typeof item === "string") {
        return item;
      }
      return item[this.hashtagValueKey];
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.add-hashtag-btn a) {
  padding-left: 0;
}
</style>
