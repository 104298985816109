<template>
  <label
    class="label"
    :for="myFor"
  >
    <slot>{{ text }}</slot>
  </label>
</template>

<script>
export default {
  name: "iLabel",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    for: {
      type: String,
      default: "",
    },
  },
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "standard" },
    fontColor: { "color.font": "standard" },
    fontWeight: { "font.weight": "standard" },

    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
  computed: {
    myFor() {
      if (this.for === "") {
        return null;
      }
      return this.for;
    },
  },
};
</script>

<style scoped>
  .label {
    font-family: v-bind("$getStyles.fontFamily");
    font-size: v-bind("$getStyles.fontSize");
    font-weight: v-bind("$getStyles.fontWeight");
    color: v-bind("$getStyles.fontColor");
    padding-bottom: v-bind('$getStyles.paddingBottom');
    padding-top: v-bind('$getStyles.paddingTop');
    padding-left: v-bind('$getStyles.paddingLeft');
    padding-right: v-bind('$getStyles.paddingRight');
    margin-bottom: v-bind('$getStyles.marginBottom');
    margin-top: v-bind('$getStyles.marginTop');
    margin-left: v-bind('$getStyles.marginLeft');
    margin-right: v-bind('$getStyles.marginRight');
  }
</style>
