
export const zPosition = {
  options: {
    from: 0,
    to: 1,
  },
  beforeAnimationHandler(el, options) {
    const { from } = options;
    el.style.zIndex = from;
  },
  animationHandler: (el, options) => {
    const { to } = options;
    setTimeout(() => {
      el.style.zIndex = to;
    }, 10);
  },
  afterAnimationHandler: (el, options) => {
    const { from } = options;
    el.style.zIndex = from;
  },
};
