import { defineStore } from "pinia";

export const useToastStore = defineStore("toast-store", {
  state: () => ({
    toasts: [],
  }),
  getters: {
  },
  actions: {
    addToast(toast) {
      this.toasts.push(toast);
    },
    removeToast(id) {
      this.toasts = this.toasts.filter(toast => toast.id !== id);
    },
  },
});
