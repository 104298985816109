import { EASING_FUNCTIONS } from "@/constants/effect-constants";

export const scale = {
  options: {
    duration: 1000,
    from: 1,
    to: 1,
    easing: "",
  },
  beforeAnimationHandler(el, options) {
    const { duration, from, easing = EASING_FUNCTIONS.EASE_IN_OUT } = options;
    const transition = `scale ${duration}ms ${easing}`;
    if (!el.style.transition) {
      el.style.transition = transition;
    } else {
      el.style.transition = [el.style.transition, transition].join(", ");
    }
    el.style.scale = from;
  },
  animationHandler: (el, options) => {
    const { to } = options;
    setTimeout(() => {
      el.style.scale = to;
    }, 10);
  },
  afterAnimationHandler: (el, options) => {
    const { from } = options;
    el.style.scale = from;
  },
};
