<template>
  <iTextInput
    v-if="question.input === 'textinput'"
    v-model="vModel"
    :label="getLabel"
    :placeholder="question.placeholder"
  />
  <iTextArea
    v-if="question.input === 'textarea'"
    v-model="vModel"
    :label="getLabel"
    :placeholder="question.placeholder"
  />
</template>

<script>
export default {
  name: "iFormQuestion",
  props: {
    numberQuestion: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      store: null,
    };
  },
  computed: {
    vModel: {
      get() {
        const keys = this.question.storeVar.split(".");
        if (keys.length > 1) {
          let value = null;
          keys.forEach(splitValue => {
            if (value === null) {
              value = this.store[splitValue];
            } else {
              value = value[splitValue];
            }
          });

          if (value === undefined) {
            return "";
          }
          return value;
        }
        return this.store[this.question.storeVar];
      },
      set(value) {
        const keys = this.question.storeVar.split(".");
        if (keys.length > 1) {
          let current = this.store;
          for (let index = 0; index < keys.length - 1; index++) {
            if (!current[keys[index]]) {
              throw new Error("key missing");
            }
            current = current[keys[index]];
          }
          current[keys[keys.length - 1]] = value;
        } else {
          this.store[this.question.storeVar] = value;
        }
      },
    },
    getLabel() {
      if (this.numberQuestion) {
        return `${this.index + 1}. ${this.question.label}`;
      }
      return this.question.label;
    },
  },
  created() {
    if (this.question.store) {
      this.store = this.question.store();
    }
  },
};
</script>
