<template>
  <iCard class="note">
    <slot />
  </iCard>
</template>

<script>
export default {
  name: "iNote",
  props: {
  },
};
</script>
<style scoped lang="scss">
  .note {
    border-color: #ffdd00;
    background-color: #ffff99;
  }
</style>
