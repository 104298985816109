<template>
  <div class="toggle-container" @click="toggleSwitch">
    <iLabel v-if="(label || $slots.default) && labelPosition === 'left'">
      <slot>{{ label }}</slot>
    </iLabel>
    <div :class="['toggle-switch', `toggle-switch-${isActive ? 'active' : 'inactive'}`]">
      <div :class="['toggle-switch-thumb', `toggle-switch-thumb-${isActive ? 'active' : 'inactive'}`]">
        {{ isActive ? trueText : falseText }}
      </div>
    </div>
    <iLabel v-if="(label || $slots.default) && labelPosition === 'right'">
      <slot>{{ label }}</slot>
    </iLabel>
  </div>
</template>

<script>
import _ from "lodash";
import { Enum } from "@";
import ComputedPropError from "@/errors/computed-prop-error";

export default {
  name: "iToggleSwitch",
  styleGuide: () => ({
    gap: { "size.spacing": "standard" },
    thumbBackgroundColor: { "color.background": "light" },
    padding: { "size.spacing": "small" },
    borderColor: { "color.border": "standard" },
    backgroundColorActive: { "color.font": "branded" },
    backgroundColorInactive: { "color.font": "subtle" },
    thumbSize: { "size.spacing": "extraLarge" },
  }),
  props: {
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    size: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "small", "large"],
    },
    width: {
      type: String,
      required: false,
      default: "50%",
    },
    height: {
      type: String,
      required: false,
      default: "100%",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    labelPosition: {
      type: Enum,
      required: false,
      default: "right",
      options: ["left", "right"],
    },
    modelValue: {
      type: [String, Boolean],
      required: true,
    },
    trueText: {
      type: String,
      default: "",
    },
    falseText: {
      type: String,
      default: "",
    },
    trueValue: {
      type: [String, Boolean],
      default: true,
    },
    falseValue: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      switchActive: this.modelValue === this.trueValue,
      toggleValue: this.modelValue === this.toggleValue ? this.trueValue : this.falseValue,
    };
  },
  computed: {
    isActive() {
      return this.switchActive;
      // return this.modelValue === this.switchActive || this.modelValue === this.trueValue || this.modelValue === true;
    },
    myHeight() {
      switch (this.size) {
        case "large":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["extraLarge"];
        case "standard":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["large"];
        case "small":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["standard"];
        default:
          throw new ComputedPropError("Invalid value returned in computed prop", { propName: "myHeight", component: this.$options.name });
      }
    },
    myWidth() {
      switch (this.size) {
        case "large":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["extraLarge"];
        case "standard":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["large"];
        case "small":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["standard"];
        default:
          throw new ComputedPropError("Invalid value returned in computed prop", { propName: "myWidth", component: this.$options.name });
      }
    },
    myBorderRadius() {
      switch (this.size) {
        case "large":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["extraLarge"];
        case "standard":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["large"];
        case "small":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["large"];
        default:
          throw new ComputedPropError("Invalid value returned in computed prop", { propName: "myBorderRadius", component: this.$options.name });
      }
    },
    myPadding() {
      switch (this.size) {
        case "large":
          return this.$getStyleValuePropertiesFromTheme("size.spacing")["small"];
        case "standard":
          return `6px ${this.$getStyleValuePropertiesFromTheme("size.spacing")["small"]}`;
        case "small":
          return `${this.$getStyleValuePropertiesFromTheme("size.spacing")["extraSmall"]}`;
        default:
          throw new ComputedPropError("Invalid value returned in computed prop", { propName: "myPadding", component: this.$options.name });
      }
    },
  },
  watch: {
    modelValue() {
      if ( this.modelValue !== this.switchActive ) {
        this.switchActive = this.modelValue === this.trueValue;
      }
    },
  },
  created: function () {
    this.emitUpdate = _.debounce(function () {
      this.$emit("update:modelValue", this.toggleValue);
      this.$emit("change", this.toggleValue);
    }, this.debounce);
  },
  methods: {
    emitUpdate: function () { },
    toggleSwitch() {
      this.switchActive = !this.switchActive;
      this.toggleValue = this.switchActive ? this.trueValue : this.falseValue;
      this.emitUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-container {
  display: flex;
  align-items: center;
  gap: v-bind('$getStyles.gap');
}

.toggle-switch {
  width: calc(v-bind(myWidth) * 2);
  height: v-bind(myHeight);
  border-radius: v-bind(myBorderRadius);
  padding: v-bind(myPadding);
  transition: all 0.2s ease-in-out;

  &-active {
    background-color: v-bind('$getStyles.backgroundColorActive');
  }

  &-inactive {
    background-color: v-bind('$getStyles.backgroundColorInactive');
  }

  &-thumb {
    position: relative;
    display: flex;
    box-sizing: border-box;
    transition: all .2s ease-in-out;
    width: v-bind(width);
    height: v-bind(height);
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: rgba(255 , 255 ,255 , 75%);

    &-active {
      left: 50%;
    }

    &-inactive {
      left: 0;
    }
  }
}
</style>
