import { v4 as uuidV4 } from "uuid";
import { useToastStore } from "@/stores/toast-store.js";
export default class ToastBuilder {

  static TOAST_TYPE_ERROR = "error";
  static TOAST_TYPE_STANDARD = "standard";
  static TOAST_TYPE_SUCCESS = "success";

  static TIMEOUT_DEFAULT = 3000;
  static TIMEOUT_NONE = null;

  id = uuidV4();
  text = null;
  title = null;
  toastType = ToastBuilder.TOAST_TYPE_STANDARD;
  timeout = ToastBuilder.TIMEOUT_DEFAULT;
  closable = false;

  setText(text) {
    this.text = text;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setToastType(toastType) {
    this.toastType = toastType;
    return this;
  }

  setTimeout(timeout) {
    this.timeout = timeout;
    return this;
  }

  setClosable(closable) {
    this.closable = closable;
    return this;
  }

  build() {
    const { ...object } = this;
    useToastStore().addToast(object);
  }
}
