<template>
  <iRow class="i-collapsable-panel" :width="width" :height="height">
    <iColumn>
      <iRow
        class="i-collapsable-panel-header"
        :height="headerHeight"
        vertical-align="middle"
        @click="toggleExpanded"
      >
        <iColumn width="fill">
          <slot name="header" />
        </iColumn>
        <iColumn width="hug">
          <iIcon
            :icon="isExpanded ? 'close' : 'plus'"
            :style-overrides="{'hoverColor': 'standard'}"
          />
        </iColumn>
      </iRow>
      <iRow v-if="isExpanded" class="i-collapsable-panel-content">
        <slot name="content" />
      </iRow>
    </iColumn>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iCollapsablePanel",
  props: {
    headerHeight: {
      type: [Number, Enum],
      required: false,
      default: 50,
      options: ["hug", "fill"],
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["hug", "fill"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["hug", "fill"],
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped lang="scss">
.i-collapsable-panel {
  &-header {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
