<template>
  <div
    class="draggable"
    :class="{'drag-over': dragOver}"
    :draggable="draggable"
    @dragstart="startDrag"
    @dragover.prevent="allowDrop"
    @dragleave.prevent="dragOverEnd"
    @drop.prevent="placeBelow"
  >
    <iRow vertical-align="middle">
      <iColumn width="hug" class="handle">
        <slot name="handle">
          <iIcon :icon="handleIcon" size="large" />
        </slot>
      </iColumn>
      <iColumn width="hug">
        <slot />
      </iColumn>
    </iRow>
  </div>
</template>

<script>

export default {
  name: "iDraggable",
  props: {
    draggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    handleIcon: {
      type: String,
      required: false,
      default: "drag",
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ["dragged", "dropped"],
  data() {
    return {
      dragOver: false,
    };
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
    dividerSize: { "size.border": "standard" },
    dividerColor: { "color.border": "dark" },
    dragOverBorderColor: { "color.border": "striking" },
  }),
  methods: {
    startDrag() {
      this.$emit("dragged", this.index);
    },
    allowDrop(e) {
      e.dataTransfer.dropEffect = "move";
      this.dragOver = true;
    },
    dragOverEnd() {
      this.dragOver = false;
    },
    placeBelow() {
      this.$emit("dropped", this.index);
      this.dragOverEnd();
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable{
  box-sizing: border-box;
  width:100%;
  cursor: grab;
  border-bottom: v-bind("$getStyles.dividerSize") solid v-bind("$getStyles.dividerColor");
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
  &:last-child{
    border-bottom: none;
  }
}
.drag-over{
  border-color: v-bind("$getStyles.dragOverBorderColor");
}
</style>
