<template>
  <div class="i-chip" @click="$emit('click', $event)">
    <iColumn width="fill">
      <slot />
    </iColumn>
    <iColumn v-if="showIcon" width="hug">
      <iIcon
        icon="close"
        size="small"
        variant="secondary"
        :is-loading="isLoading"
        @click.stop="$emit('click:icon', $event)"
      />
    </iColumn>
  </div>
</template>

<script>
import iIcon from "@/components/icons/iIcon";

export default {
  name: "iChip",
  components: {
    iIcon,
  },
  props: {
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click", "click:icon"],
  styleGuide: () => ({
    backgroundColor: { "color.background": "dark" },
    fontSize: { "size.font": "small" },
    color: { "color.font": "light" },
    gap: { "size.spacing": "small" },

    borderRadius: { "size.borderRadius": "large" },

    paddingTop: { "size.spacing": "small" },
    paddingBottom: { "size.spacing": "small" },
    paddingLeft: { "size.spacing": "small" },
    paddingRight: { "size.spacing": "small" },
  }),
};
</script>

<style scoped lang="scss">
.i-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  gap: v-bind("$getStyles.gap");

  padding-top: v-bind("$getStyles.paddingTop");
  padding-bottom: v-bind("$getStyles.paddingBottom");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");

  border-radius: v-bind("$getStyles.borderRadius");
  background: v-bind("$getStyles.backgroundColor");

  color: v-bind("$getStyles.color");
  font-size: v-bind("$getStyles.fontSize");

  :deep(.i-text) {
    color: v-bind("$getStyles.color");
    font-size: v-bind("$getStyles.fontSize");
  }
}
</style>
