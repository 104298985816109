<template>
  <iModal
    v-if="visible"
    :title="title"
    :width="500"
    @close="$emit('close')"
  >
    <template #body>
      <iColumn gap="large">
        <iParagraph v-if="message">
          {{ message }}
        </iParagraph>
        <iTextInput
          v-model="myData"
          :label="label"
          name="myDataInput"
          width="fill"
          :focus="true"
          @enter="$emit('submit', myData)"
        />
      </iColumn>
    </template>
    <template #footer>
      <iRow width="fill" align="right">
        <iColumn width="hug">
          <iButton
            :disabled="myData === ''"
            variant="primary"
            @click="$emit('submit', myData)"
          >
            {{ buttonText }}
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
export default {
  name: "iPromptModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: "Submit",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["close", "submit"],
  data() {
    return {
      myData: "",
    };
  },
  computed: {
  },
  created() {
  },
  unmounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
