const icons = {};
export const loadIcons = async () => {
  if (Object.keys(icons).length !== 0) {
    return icons;
  }
  const files = import.meta.glob("./icons/**/*.svg", { eager: true });
  for (const iconPath in files) {
    // eslint-disable-next-line no-useless-escape
    const iconName = iconPath.match(/\/([^\/]+)\.\w+$/)[1];
    let iconData = files[iconPath];
    icons[iconName] = iconData.default;
  }
  return icons;
};
export default loadIcons();
