<template>
  <div
    v-click-outside="clickInput"
    class="i-select"
  >
    <div class="i-select-container">
      <iLabel>
        {{ label }}
      </iLabel>
      <div
        class="i-select-input-container"
        @click="clickInput(id)"
      >
        <template v-if="chips">
          <div class="chip-container" @click.stop>
            <iChip
              v-if="!multiple && modelValue"
              @click:icon.stop="removeChip(modelValue)"
            >
              <iText>{{ inputValue }}</iText>
            </iChip>
            <iChip
              v-for="(item, index) in modelValue"
              v-else
              :key="index"
              @click:icon.stop="removeChip(item)"
            >
              <iText>{{ item[textField] }}</iText>
            </iChip>
          </div>
        </template>
        <input
          v-else
          :id="id"
          class="i-select-input"
          :value="inputValue"
          readonly
        >
        <iLoadingSpinner v-if="isLoading" />
        <div class="icon-container">
          <iIcon :icon="showItems ? 'caret-up' : 'caret-down'" />
        </div>
      </div>
    </div>
    <ul
      v-if="showItems"
      :class="{
        'i-select-dropdown': true,
        'max-height-overflow': items.length > 6,
      }"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{
          'selected': isSelected(item),
          'i-select-dropdown-item': true,
        }"
        @click="selectItem(item)"
      >
        <iText>
          {{ item[textField] }}
        </iText>
      </li>
    </ul>
  </div>
</template>

<script>
import Enum from "@/data-types/enum.js";
import iLabel from "@/components/forms/iLabel";
import iText from "@/components/text/iText";
import iChip from "@/components/forms/iChip";
import iIcon from "@/components/icons/iIcon";
import iLoadingSpinner from "@/components/icons/iLoadingSpinner";
import PropValidationError from "@/errors/prop-validation-error";

export default {
  name: "iSelect",
  components: {
    iLabel,
    iText,
    iChip,
    iIcon,
    iLoadingSpinner,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "",
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: 300,
      options: ["hug", "fill"],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: [String, Object],
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueField: {
      type: String,
      required: false,
      default: "id",
    },
    textField: {
      type: String,
      required: false,
      default: "name",
    },
    showEmptyState: {
      type: Boolean,
      required: false,
      default: true,
    },
    emptyString: {
      type: String,
      required: false,
      default: "No items available",
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    zIndex: {
      type: Number,
      required: false,
      default: 1,
    },
    returnValue: {
      type: Boolean,
      required: false,
      default: false,
      validator(value, props) {
        if (props.multiple && value) {
          throw new PropValidationError("iSelect: returnValue prop is not compatible with multiple prop", value);
        }
        return true;
      },
    },
  },
  styleGuide: () => ({
    inputPaddingTop: { "size.spacing": "standard" },
    inputPaddingBottom: { "size.spacing": "standard" },
    inputPaddingLeft: { "size.spacing": "standard" },
    inputPaddingRight: { "size.spacing": "standard" },
    inputBackgroundColor: { "color.background": "standard" },
    inputBorderWidth: { "size.border": "standard" },
    inputBorderColor: { "color.border": "dark" },
    inputBorderRadius: { "size.borderRadius": "large" },
    inputFontColor: { "color.font": "standard" },
    inputPlaceholderColor: { "color.font": "subtle" },
    inputFontSize: { "size.font": "standard" },
    inputFontWeight: { "font.weight": "standard" },
    inputTypeFace: { "font.typeface": "standard" },

    dropdownBackgroundColor: { "color.background": "paper" },
    dropdownBorderWidth: { "size.border": "thin" },
    dropdownBorderColor: { "color.border": "dark" },
    dropdownBorderRadius: { "size.borderRadius": "large" },

    dropdownItemColor: { "color.font": "standard" },
    dropdownItemSelectedBackgroundColor: { "color.background": "darker" },
    dropdownItemSelectedColor: { "color.font": "light" },
    dropdownItemHoverBackgroundColor: { "color.background": "dark" },
    dropdownItemHoverColor: { "color.font": "light" },
    dropdownItemBorderWidth: { "size.border": "thin" },
    dropdownItemBorderColor: { "color.border": "standard" },
    dropdownItemPaddingTop: { "size.spacing": "standard" },
    dropdownItemPaddingBottom: { "size.spacing": "standard" },
    dropdownItemPaddingLeft: { "size.spacing": "large" },
    dropdownItemPaddingRight: { "size.spacing": "large" },
  }),
  emits: ["update:modelValue"],
  data() {
    return {
      showItems: false,
    };
  },
  computed: {
    inputValue() {
      if (!this.modelValue || Object.keys(this.modelValue).length === 0) {
        if (this.showEmptyState) {
          return this.emptyString;
        }
        return "";
      }
      if (this.multiple && this.chips) {
        return "";
      }

      let value;
      if (this.multiple) {
        value = this.modelValue.map(item => item[this.textField]).join(", ");
      } else if (typeof this.modelValue === "string") {
        value = this.modelValue;
        const foundItem = this.items.find(item => item[this.valueField] === this.modelValue);
        if (foundItem) {
          value = foundItem[this.textField];
        }
      } else {
        value = this.modelValue[this.textField];
      }

      if (this.showEmptyState && !value) {
        return this.emptyString;
      }

      return value;
    },
    myWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }

      return this.width === "fill" ? "100%" : "auto";
    },
  },
  methods: {
    clickInput(clickedId) {
      if (clickedId === this.id) {
        this.showItems = !this.showItems;
      } else {
        this.showItems = false;
      }
    },
    selectItem(item) {
      if (this.multiple) {
        const selected = this.modelValue || [];
        const index = selected.findIndex(selectedItem => selectedItem[this.valueField] === item[this.valueField]);
        if (index === -1) {
          selected.push(item);
        } else {
          selected.splice(index, 1);
        }
        this.$emit("update:modelValue", selected);
      } else {
        if (this.returnValue) {
          this.$emit("update:modelValue", item[this.valueField]);
        } else {
          this.$emit("update:modelValue", item);
        }
        this.showItems = false;
      }
    },
    isSelected(item) {
      if (this.multiple) {
        return this.modelValue && this.modelValue.some(selectedItem => selectedItem[this.valueField] === item[this.valueField]);
      }
      if (typeof this.modelValue === "string") {
        return this.modelValue === item[this.valueField];
      }
      return this.modelValue && this.modelValue[this.valueField] && this.modelValue[this.valueField] === item[this.valueField];
    },
    removeChip(item) {
      if (!this.multiple) {
        this.$emit("update:modelValue", "");
        return;
      }

      const selected = this.modelValue || [];
      const index = selected.findIndex(selectedItem => selectedItem[this.valueField] === item[this.valueField]);
      selected.splice(index, 1);
      this.$emit("update:modelValue", selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.i-select {
  position: relative;
  display: inline-block;
  width: v-bind(myWidth);

  &-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-input {
    border: none;
    appearance: none;
    background-color: transparent;
    flex: 1;
    cursor: pointer;

    font-size: v-bind('$getStyles.inputFontSize');
    font-weight: v-bind('$getStyles.inputFontWeight');
    font-family: v-bind('$getStyles.inputTypeFace');

    &:focus {
      outline: none;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      gap: 6px;

      padding-top: v-bind('$getStyles.inputPaddingTop');
      padding-bottom: v-bind('$getStyles.inputPaddingBottom');
      padding-left: v-bind('$getStyles.inputPaddingLeft');
      padding-right: v-bind('$getStyles.inputPaddingRight');

      color: v-bind('$getStyles.inputFontColor');
      background-color: v-bind('$getStyles.inputBackgroundColor');
      border-width: v-bind('$getStyles.inputBorderWidth');
      border-radius: v-bind('$getStyles.inputBorderRadius');
      border-color: v-bind('$getStyles.inputBorderColor');
      border-style: solid;

      .spinner-container {
        margin-right: 5px;
      }

      .icon-container {
        margin-top: 2px;
        flex-basis: fit-content;
      }

      .chip-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }

  &-dropdown {
    width:  v-bind(myWidth);
    position: absolute;
    z-index: v-bind(zIndex);
    margin: 0;
    padding: 0;
    list-style-type: none;
    transform-origin: top;
    overflow: hidden;

    border-style: solid;
    border-width: v-bind('$getStyles.dropdownBorderWidth');
    border-color: v-bind('$getStyles.dropdownBorderColor');
    border-radius: v-bind('$getStyles.dropdownBorderRadius');

    &-item {
      background: v-bind('$getStyles.dropdownBackgroundColor');
      color: v-bind('$getStyles.dropdownItemColor');
      padding-top: v-bind('$getStyles.dropdownItemPaddingTop');
      padding-bottom: v-bind('$getStyles.dropdownItemPaddingBottom');
      padding-left: v-bind('$getStyles.dropdownItemPaddingLeft');
      padding-right: v-bind('$getStyles.dropdownItemPaddingRight');

      border-bottom-style: solid;
      border-bottom-width: v-bind('$getStyles.dropdownItemBorderWidth');
      border-color: v-bind('$getStyles.dropdownItemBorderColor');

      &.selected {
        background: v-bind('$getStyles.dropdownItemSelectedBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.dropdownItemSelectedColor');
        }

        &:hover {
          .i-text {
            color: v-bind('$getStyles.dropdownItemHoverColor');
          }
        }
      }

      &:hover {
        cursor: pointer;
        background: v-bind('$getStyles.dropdownItemHoverBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.dropdownItemHoverColor');
        }
      }
    }
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease-in-out;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: scaleY(0);
}
.slide-fade-enter-to, .slide-fade-leave-from {
  transform: scaleY(1);
}

.max-height-overflow {
  max-height: 300px;
  overflow-y: auto;
}
</style>
