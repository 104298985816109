
<template>
  <iColumn class="sortable">
    <iDraggable
      v-for="(item, index) in modelValue"
      :key="index"
      :index="index"
      @dragged="dragIndex=$event"
      @dropped="drop"
    >
      <slot name="draggable-item" :menu-item="item" :index="index" />
    </iDraggable>
  </iColumn>
</template>

<script>
export default {
  name: "iSortable",
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dragIndex: null,
    };
  },
  methods: {
    drop(index) {
      if (index < this.dragIndex) {
        index++;
      }
      let arrayClone = [...this.modelValue];
      let itemsToMove = arrayClone.splice(this.dragIndex, 1)[0];
      arrayClone.splice(index, 0, itemsToMove);
      this.$emit("update:modelValue", arrayClone);
    },
  },
};
</script>
