<template>
  <div
    :class="`
          image-container ${shape}
          i-dynamic-spacing
          i-dynamic-width-height
      `"
  >
    <img
      class="i-dynamic-border i-dynamic-image"
      :alt="alt"
      :src="src"
    >
  </div>
</template>

<script>
import Enum from "@/data-types/enum";

export default {
  name: "iDynamicImageElement",
  isUsableInDynamicElements: true,
  supportedDynamicComponentOptions: [
    "BorderOptions",
    "SpacingOptions",
    "SingleImage",
    "WidthHeightOptions",
    "ImageOptions",
  ],
  props: {
    src: {
      type: String,
      required: false,
      default: "",
    },
    shape: {
      type: Enum,
      required: false,
      options: ["rectangle", "circle"],
      default: "rectangle",
    },
    alt: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped>
.image-container {
  &.circle {
    border-radius: 100%;

    img {
      border-radius: 100%;
    }
  }

  position: relative;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
}
</style>
