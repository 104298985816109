export const EFFECT_ON_ACTION = {
  HOVER: "hover",
  CLICK: "click",
  PAGE_LOAD: "page_load",
  NONE: "none",
};

export const FILL_MODES = {
  NONE: "none",
  FORWARDS: "forwards",
  BACKWARDS: "backwards",
  BOTH: "both",
  INFINITE: "infinite",
};

export const EASING_FUNCTIONS = {
  EASE: "ease",
  EASE_IN: "ease-in",
  EASE_OUT: "ease-out",
  EASE_IN_OUT: "ease-in-out",
  LINEAR: "linear",
  STEP_START: "step-start",
  STEP_END: "step-end",
};

export const SCROLL_SNAP_TYPES = {
  SNAP: "snap",
  NONE: "none",
};
