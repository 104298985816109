import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export function getRelativeTimeString(date) {
  dayjs.extend(relativeTime);
  if (!isNaN(date)) {
    return dayjs().to(dayjs.unix(date));
  }
  return dayjs().to(dayjs(date));
}
