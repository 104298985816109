<template>
  <iRow
    class="i-status-container"
    gap="small"
    vertical-align="middle"
    width="hug"
  >
    <div class="i-status-dot" />
    <iText v-if="showStatusText">
      {{ statusText }}
    </iText>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum.js";
import { status } from "@/enums/status-enums.js";
import iRow from "@/components/layout/iRow.vue";
import iText from "@/components/text/iText.vue";
import _ from "lodash";

export default {
  name: "iStatus",
  components: {
    iRow,
    iText,
  },
  props: {
    status: {
      type: [Enum, String],
      required: false,
      default: "inactive",
      options: ["active", "verified", "inactive", "pending"],
    },
    showStatusText: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: Enum,
      required: false,
      default: "small",
      options: ["small", "standard", "large"],
    },
  },
  computed: {
    statusText() {
      return _.upperFirst(this.status);
    },
    statusColor() {
      let color = "subtle";
      switch (this.status) {
        case status.ACTIVE:
        case status.VERIFIED:
          color = "success";
          break;
        case status.INACTIVE:
          color = "subtle";
          break;
        case status.PENDING:
          color = "accent";
          break;
      }

      return this.$getStyleValuePropertiesFromTheme("color.font")[color];
    },
    dotSize() {
      switch (this.size) {
        case "small":
          return "8px";
        case "standard":
          return "12px";
        case "large":
          return "16px";
      }

      return "12px";
    },
    fontSize() {
      return this.$getStyleValuePropertiesFromTheme("size.font")[this.size];
    },
  },
  styleGuide: () => ({
    fontWeight: { "font.weight": "light" },

    borderColor: { "color.border": "dark" },
    borderWidth: { "size.border": "none" },
    borderRadius: { "size.borderRadius": "large" },

    paddingTop: { "size.spacing": "extraSmall" },
    paddingBottom: { "size.spacing": "extraSmall" },
    paddingLeft: { "size.spacing": "small" },
    paddingRight: { "size.spacing": "small" },
  }),
};
</script>

<style scoped lang="scss">
.i-status {
  &-container {
    border-style: solid;
    border-width: v-bind('$getStyles.borderWidth');
    border-color: v-bind('$getStyles.borderColor');
    border-radius: v-bind('$getStyles.borderRadius');

    padding-top: v-bind('$getStyles.paddingTop');
    padding-bottom: v-bind('$getStyles.paddingBottom');
    padding-left: v-bind('$getStyles.paddingLeft');
    padding-right: v-bind('$getStyles.paddingRight');

    &:deep(.i-text) {
      font-size: v-bind(fontSize);
      font-weight: v-bind('$getStyles.fontWeight');
    }
  }

  &-dot {
    width: v-bind(dotSize);
    height: v-bind(dotSize);
    border-radius: 50%;
    background-color: v-bind(statusColor);
  }
}
</style>
