export const PROP_MAP = {
  splashImageHref: "onClick:splashImage",
  badgeHref: "onClick:badge",
  titleHref: "onClick:title",
  subtitleHref: "onClick:subtitle",
  authorTagHref: "onClick:authorTag",
  descriptionHref: "onClick:description",
  authorAvatarHref: "onClick:authorAvatar",
  authorNameHref: "onClick:authorName",
  authorDescriptionHref: "onClick:authorDescription",
};
