<template>
  <iRow>
    <iColumn
      v-for="_i, columnIndex in numberOfColumns"
      :key="columnIndex"
      :width="350"
    >
      <template v-for="item, itemIndex in itemsForClusterIndex(columnIndex)" :key="itemIndex">
        <VNode :vnode="item" />
      </template>
    </iColumn>
  </iRow>
</template>
<script>
import DimensionsMixin from "@/mixins/dimensions-mixin";

export default {
  name: "iMasonry",
  components: {
    VNode: {
      functional: true,
      props: ["vnode"],
      render: h => h.vnode,
    },
  },
  mixins: [DimensionsMixin],
  props: {
    direction: {
      type: String,
      default: "column",
      required: false,
    },
  },
  computed: {
    items() {
      let items = this.$slots.default();
      if (typeof items[0].type == "symbol") { // this is needed to extract the items if they are nested in a v-for directive
        return items[0].children;
      }
      return items;
    },
    numberOfColumns() {
      return Math.floor(this.containerWidth / 350);
    },
    containerWidth() {
      return this.$parentDimensions.width;
    },
  },
  methods: {
    itemsForClusterIndex(columnIndex) {
      return this.items.filter((_item, itemIndex) => {
        return itemIndex % this.numberOfColumns === columnIndex;
      });
    },
  },
};
</script>
