<template>
  <iFlex>
    <input
      :checked="isChecked"
      :disabled="disabled"
      :name="name"
      :readonly="readonly"
      :required="required"
      :value="value"
      type="radio"
      @click="emitUpdate"
    >
    <label class="label">
      <slot name="label">
        <template v-if="label">
          {{ label }}
        </template>
      </slot>
    </label>
  </iFlex>
</template>

<script>
import _ from "lodash";

export default {
  name: "iRadioButton",
  props: {
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: null,
    },
  },
  styleGuide: () => ({
    labelFontFamily: { "font.typeface": "standard" },
    labelFontSize: { "size.font": "large" },
    labelFontWeight: { "font.weight": "standard" },
    labelFontColor: { "color.font": "standard" },

    inputColor: { "color.background": "darker" },
    inputBorderColor: { "color.background": "darker" },

    inputDisabledColor: { "color.background": "disabled" },
  }),
  emits: ["update:modelValue"],
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  created: function() {
    this.emitUpdate = _.debounce(function () {
      this.$emit("update:modelValue", this.value);
    }, this.debounce);
  },
  methods: {
    // including this so the linter does not complain
    // about the function being defined in the created hook
    emitUpdate: function() {},
  },
};
</script>

<style lang="scss" scoped>
  .label {
    color: v-bind('$getStyles.labelFontColor');
    font-size: v-bind('$getStyles.labelFontSize');
    font-weight: v-bind('$getStyles.labelFontWeight');
    font-family: v-bind('$getStyles.labelFontFamily');
  }

  input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid v-bind('$getStyles.inputBorderColor');
    border-radius: 50%;
    outline: none;
    position: relative;

    &:checked {
      border-color: v-bind('$getStyles.inputBorderColor');

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: v-bind('$getStyles.inputColor');
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {
      background-color: v-bind('$getStyles.inputDisabledColor');

      &:checked::after {
        background-color: v-bind('$getStyles.inputDisabledColor');
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
</style>
