<template>
  <component
    :is="myLayoutComponent"
    vertical-align="middle"
    :width="width"
    height="hug"
    wrap="nowrap"
    :disabled="disabled"
    class="button-container"
  >
    <iText v-if="isLoading && loadingText && loadingTextPosition === 'before'" :wrap="false">
      {{ loadingText }}
    </iText>
    <iLoadingSpinner v-if="isLoading && loadingSpinnerPosition === 'before'" :variant="loadingSpinnerVariant" />
    <a
      v-if="variant === 'tertiary'"
      :class="{
        'button-text-link': true,
        [`i-button--size-${size}`]: true,
      }"
    >
      <iRow :align="textAlignment" vertical-align="middle">
        <template v-if="isLoading && loadingText && loadingTextPosition === 'inside'">
          <iText>
            {{ loadingText }}
          </iText>
        </template>
        <iText v-else :wrap="false">
          <slot />
        </iText>
        <iLoadingSpinner v-if="isLoading && loadingSpinnerPosition === 'inside'" :variant="loadingSpinnerVariant" />
      </iRow>
    </a>

    <iText
      v-else-if="variant === 'text'"
      :class="{
        'button-text-btn': true,
      }"
    >
      <iRow :align="textAlignment" vertical-align="middle">
        <template v-if="isLoading && loadingText && loadingTextPosition === 'inside'">
          <iText>
            {{ loadingText }}
          </iText>
        </template>
        <iText v-else :wrap="false">
          <slot />
        </iText>
        <iLoadingSpinner v-if="isLoading && loadingSpinnerPosition === 'inside'" :variant="loadingSpinnerVariant" />
      </iRow>
    </iText>

    <button
      v-else
      :class="{
        'i-button': true,
        disabled: disabled,
        [`i-button--variant-${variant}`]: true,
        [`i-button--size-${size}`]: true,
      }"
    >
      <iRow :align="textAlignment" vertical-align="middle" width="fill">
        <template v-if="isLoading && loadingText && loadingTextPosition === 'inside'">
          <iText :wrap="false">
            {{ loadingText }}
          </iText>
        </template>
        <iText v-else :wrap="false">
          <slot />
        </iText>
        <iLoadingSpinner
          v-if="isLoading && loadingSpinnerPosition === 'inside'"
          :class="{'button-loading-spinner-position-absolute': width === 'fill'}"
          :variant="loadingSpinnerVariant"
        />
      </iRow>
    </button>

    <iLoadingSpinner v-if="isLoading && loadingSpinnerPosition === 'between'" :variant="loadingSpinnerVariant" />
    <iText v-if="isLoading && loadingText && loadingTextPosition === 'after'" :wrap="false">
      {{ loadingText }}
    </iText>
    <iLoadingSpinner v-if="isLoading && loadingSpinnerPosition === 'after'" :variant="loadingSpinnerVariant" />
  </component>
</template>

<script>
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants";

export default {
  name: "iButton",
  props: {
    width: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
    textAlignment: {
      type: Enum,
      required: false,
      default: "center",
      options: ["left", "center", "right"],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingSpinnerPosition: {
      type: Enum,
      required: false,
      default: "after",
      options: ["inside", "after", "between", "before"],
    },
    loadingTextPosition: {
      type: Enum,
      required: false,
      default: "after",
      options: ["inside", "after", "before"],
    },
    loadingText: {
      type: String,
      required: false,
      default: "",
    },
    variant: {
      type: Enum,
      required: false,
      default: "primary",
      options: ["primary", "secondary", "tertiary", "striking", "branded", "text"],
    },
    loadingSpinnerVariant: {
      type: Enum,
      required: false,
      default: "standard",
      options: [...themeStructure.color.font],
    },
    size: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["small", "standard", "large"],
    },
  },
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "large" },

    primaryVariantBorderWidth: { "size.border": "none" },
    primaryVariantBorderColor: { "color.border": "none" },
    primaryVariantBackgroundColor: { "color.background": "darker" },
    primaryVariantFontColor: { "color.font": "light" },
    primaryVariantFontWeight: { "font.weight": "standard" },
    primaryVariantDisabledBackgroundColor: { "color.background": "disabled" },
    primaryVariantDisabledFontColor: { "color.font": "disabled" },
    primaryVariantHoverFontColor: { "color.font": "light" },
    primaryVariantHoverBackgroundColor: { "color.background": "dark" },

    brandedVariantBorderWidth: { "size.border": "thin" },
    brandedVariantBorderColor: { "color.font": "branded" },
    brandedVariantBackgroundColor: { "color.background": "pure" },
    brandedVariantFontColor: { "color.font": "branded" },
    brandedVariantFontWeight: { "font.weight": "standard" },
    brandedVariantDisabledBackgroundColor: { "color.background": "disabled" },
    brandedVariantDisabledFontColor: { "color.font": "disabled" },
    brandedVariantHoverFontColor: { "color.font": "branded" },
    brandedVariantHoverBackgroundColor: { "color.background": "standard" },

    secondaryVariantBorderWidth: { "size.border": "thin" },
    secondaryVariantFontWeight: { "font.weight": "standard" },
    secondaryVariantFontColor: { "color.font": "standard" },
    secondaryVariantDisabledFontColor: { "color.font": "disabled" },
    secondaryVariantHoverFontColor: { "color.font": "standard" },

    secondaryVariantBackgroundColor: { "color.background": "pure" },
    secondaryVariantHoverBackgroundColor: { "color.background": "pure" },
    secondaryVariantDisabledBackgroundColor: { "color.background": "light" },

    secondaryVariantBorderColor: { "color.border": "dark" },
    secondaryVariantDisabledBorderColor: { "color.border": "disabled" },
    secondaryVariantHoverBorderColor: { "color.border": "veryDark" },

    tertiaryVariantFontWeight: { "font.weight": "standard" },
    tertiaryVariantFontColor: { "color.font": "standard" },
    tertiaryVariantHoverFontColor: { "color.font": "standard" },
    tertiaryVariantDisabledFontColor: { "color.font": "disabled" },

    strikingVariantBackgroundColor: { "color.background": "striking" },
    strikingVariantFontColor: { "color.font": "standard" },
    strikingVariantFontWeight: { "font.weight": "dark" },
    strikingVariantBorderColor: { "color.border": "none" },

    borderRadius: { "size.borderRadius": "large" },

    smallVerticalPadding: { "size.spacing": "small" },
    smallHorizontalPadding: { "size.spacing": "standard" },
    smallButtonFontSize: { "size.font": "verySmall" },

    standardVerticalPadding: { "size.spacing": "standard" },
    standardHorizontalPadding: { "size.spacing": "large" },
    standardButtonFontSize: { "size.font": "small" },

    largeVerticalPadding: { "size.spacing": "large" },
    largeHorizontalPadding: { "size.spacing": "extraLarge" },
    largeButtonFontSize: { "size.font": "standard" },

    paddingTop: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "extraLarge" },
    paddingRight: { "size.spacing": "extraLarge" },
    paddingBottom: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },

    textButtonUnderline: { "font.decorations": "underline" },
  }),
  computed: {
    myLayoutComponent() {
      if (this.width === "fill") {
        return "iColumn";
      }
      return "iRow";
    },
    myButtonWidth() {
      if (this.width === "hug") {
        return "auto";
      }
      if (this.width === "fill") {
        return "100%";
      }
      return `${this.width}px`;
    },
    paddingAdjustment() {
      const borderWidth = this.getPxValue(`${this.variant}VariantBorderWidth`, "2px");
      return borderWidth - 2 < 0 ? (borderWidth - 2) * -1 : 0;
    },
    myLeftPadding() {
      return this.getPxValue("paddingLeft") + this.paddingAdjustment + "px";
    },
    myRightPadding() {
      return this.getPxValue("paddingRight") + this.paddingAdjustment + "px";
    },
    myTopPadding() {
      return this.getPxValue("paddingTop") + this.paddingAdjustment + "px";
    },
    myBottomPadding() {
      return this.getPxValue("paddingBottom") + this.paddingAdjustment + "px";
    },
    myPaddingVertical() {
      return this.getPxValue(`${this.size}VerticalPadding`) + this.paddingAdjustment + "px";
    },
    myPaddingHorizontal() {
      return this.getPxValue(`${this.size}HorizontalPadding`) + this.paddingAdjustment + "px";
    },
  },
  methods: {
    getPxValue(key, defaultValue = "0px") {
      return parseInt((this.$getStyles[key] || defaultValue).replace("px", ""));
    },
  },
};
</script>

<style scoped lang="scss">

.i-button {
  position: relative;
  width: v-bind(myButtonWidth);
  height: 100%;

  font-family: v-bind('$getStyles.fontFamily');
  border-style: solid;
  border-radius: v-bind('$getStyles.borderRadius');

  margin-top: v-bind('$getStyles.marginTop');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');
  margin-bottom: v-bind('$getStyles.marginBottom');

  padding-top: v-bind(myTopPadding);
  padding-left: v-bind(myLeftPadding);
  padding-right: v-bind(myRightPadding);
  padding-bottom: v-bind(myBottomPadding);

  .i-text {
    font-family: v-bind('$getStyles.fontFamily');
    font-size: v-bind('$getStyles.fontSize');
  }

  &--variant {
    &-primary {
      background-color: v-bind('$getStyles.primaryVariantBackgroundColor');
      color: v-bind('$getStyles.primaryVariantFontColor');
      border-width: v-bind('$getStyles.primaryVariantBorderWidth');
      border-color: v-bind('$getStyles.primaryVariantBorderColor');

      :deep(.loading-spinner) {
        border-top-color: v-bind('$getStyles.primaryVariantFontColor');
        border-left-color: v-bind('$getStyles.primaryVariantFontColor');
        border-right-color: v-bind('$getStyles.primaryVariantFontColor');
        border-width: 3px;
      }

      .i-text {
        color: v-bind('$getStyles.primaryVariantFontColor');
        font-weight: v-bind('$getStyles.primaryVariantFontWeight');
      }

      &.disabled {
        background-color: v-bind('$getStyles.primaryVariantDisabledBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.primaryVariantDisabledFontColor');
        }
      }

      &:hover:not(.disabled) {
        background-color: v-bind('$getStyles.primaryVariantHoverBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.primaryVariantHoverFontColor');
        }
      }
    }

    &-secondary {
      background-color: v-bind('$getStyles.secondaryVariantBackgroundColor');
      border-width: v-bind('$getStyles.secondaryVariantBorderWidth');
      border-color: v-bind('$getStyles.secondaryVariantBorderColor');

      .i-text:not(.disabled) {
        color: v-bind('$getStyles.secondaryVariantFontColor');
        font-weight: v-bind('$getStyles.secondaryVariantFontWeight');
      }

      &.disabled {
        background-color: v-bind('$getStyles.secondaryVariantDisabledBackgroundColor');
        border-color: v-bind('$getStyles.secondaryVariantDisabledBorderColor');

        .i-text {
          color: v-bind('$getStyles.secondaryVariantDisabledFontColor');
        }
      }

      &:hover:not(.disabled) {
        background-color: v-bind('$getStyles.secondaryVariantHoverBackgroundColor');
        border-color: v-bind('$getStyles.secondaryVariantHoverBorderColor');

        .i-text {
          color: v-bind('$getStyles.secondaryVariantHoverFontColor');
        }
      }
    }

    &-striking {
      background-color: v-bind('$getStyles.strikingVariantBackgroundColor');
      border-color: v-bind('$getStyles.strikingVariantBorderColor');

      .i-text {
        color: v-bind('$getStyles.strikingVariantFontColor');
        font-weight: v-bind('$getStyles.strikingVariantFontWeight');
      }
    }

    &-branded {
      background-color: v-bind('$getStyles.brandedVariantBackgroundColor');
      color: v-bind('$getStyles.brandedVariantFontColor');
      border-width: v-bind('$getStyles.brandedVariantBorderWidth');
      border-color: v-bind('$getStyles.brandedVariantBorderColor');

      :deep(.loading-spinner) {
        border-top-color: v-bind('$getStyles.brandedVariantFontColor');
        border-left-color: v-bind('$getStyles.brandedVariantFontColor');
        border-right-color: v-bind('$getStyles.brandedVariantFontColor');
        border-width: 3px;
      }

      .i-text {
        color: v-bind('$getStyles.brandedVariantFontColor');
        font-weight: v-bind('$getStyles.brandedVariantFontWeight');
      }

      &.disabled {
        background-color: v-bind('$getStyles.brandedVariantDisabledBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.brandedVariantDisabledFontColor');
        }
      }

      &:hover:not(.disabled) {
        background-color: v-bind('$getStyles.brandedVariantHoverBackgroundColor');

        .i-text {
          color: v-bind('$getStyles.brandedVariantHoverFontColor');
        }
      }
    }
  }

  &:hover:not(.disabled) {
    cursor: pointer;
  }

  .button-loading-spinner-position-absolute {
    position: absolute;
    right: v-bind('$getStyles.paddingRight');
  }
}

.button-text-btn {
  cursor: pointer;

  &:hover {
    text-decoration: v-bind('$getStyles.textButtonUnderline');
  }
}

.button-text-link {
  width: v-bind(myButtonWidth);
  box-sizing: border-box;
  margin-top: v-bind('$getStyles.marginTop');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');
  margin-bottom: v-bind('$getStyles.marginBottom');
  padding-left: 0 !important;
  padding-right: 0 !important;

  .i-text {
    font-family: v-bind('$getStyles.fontFamily');
    font-size: v-bind('$getStyles.fontSize');
    font-weight: v-bind('$getStyles.tertiaryVariantFontWeight');
    color: v-bind('$getStyles.tertiaryVariantFontColor');
  }

  &.disabled {
    .i-text {
      color: v-bind('$getStyles.tertiaryVariantDisabledFontColor');
    }
  }

  &:hover:not(.disabled) {
    cursor: pointer;

    .i-text {
      color: v-bind('$getStyles.tertiaryVariantHoverFontColor');
    }
  }

  &:visited {
    .i-text {
      color: v-bind('$getStyles.tertiaryVariantFontColor');
    }
  }
}

.i-button--size {
  padding-top: v-bind('$getStyles.paddingTop');
  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');

  &-small {
    padding-top: v-bind(myPaddingVertical);
    padding-bottom: v-bind(myPaddingVertical);
    padding-left: v-bind(myPaddingHorizontal);
    padding-right: v-bind(myPaddingHorizontal);

    .i-text {
      font-size: v-bind('$getStyles.smallButtonFontSize');
    }
  }

  &-standard {
    padding-top: v-bind(myPaddingVertical);
    padding-bottom: v-bind(myPaddingVertical);
    padding-left: v-bind(myPaddingHorizontal);
    padding-right: v-bind(myPaddingHorizontal);

    .i-text {
      font-size: v-bind('$getStyles.standardButtonFontSize');
    }
  }

  &-large {
    padding-top: v-bind(myPaddingVertical);
    padding-bottom: v-bind(myPaddingVertical);
    padding-left: v-bind(myPaddingHorizontal);
    padding-right: v-bind(myPaddingHorizontal);

    .i-text {
      font-size: v-bind('$getStyles.largeButtonFontSize');
    }
  }
}

</style>
