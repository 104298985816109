<template>
  <code>
    <slot />
  </code>
</template>

<script>
export default {
  name: "iCode",
  props: {
  },
  computed: {
  },
};
</script>
<style scoped lang="scss">
  code {
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    background-color: #19191a;
    color: #28fb14;
  }
  code :deep(p),
  code :deep(span) {
    font-family: Monaco;
    color: #28fb14;
  }
  code :deep(.yellow),
  code :deep(.content) {
    color: #afad23;
  }
  code :deep(.blue),
  code :deep(.bracket),
  code :deep(.equals),
  code :deep(.prop-name) {
    color: #32bbc7;
  }
  code :deep(.green),
  code :deep(.tag) {
    color: #28fb14;
  }
  code :deep(.orange),
  code :deep(.prop-value) {
    color: #b96e20;
  }
</style>
