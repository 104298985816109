import { scrollX } from "@/effects/scrollX";
import { fade } from "@/effects/fade";
import { rotate } from "@/effects/rotate";
import { scale } from "@/effects/scale";
import { zPosition } from "@/effects/zPosition";
import { blur } from "@/effects/blur";

export default {
  "fade": fade,
  "scroll-x": scrollX,
  "rotate": rotate,
  "scale": scale,
  "z-position": zPosition,
  "blur": blur,
};
