<template>
  <label class="label">
    <iRow vertical-align="middle" wrap="nowrap">
      <iColumn width="hug">
        <input
          v-if="!isLoading"
          :id="name"
          v-model="toggleValue"
          :disabled="disabled"
          :name="name"
          :readonly="readonly"
          :required="required"
          type="checkbox"
          @input="emitUpdate"
        >
        <iLoadingSpinner v-else size="standard" />
      </iColumn>

      <iColumn v-if="label || $slots.default" vertical-align="middle">
        <slot>
          <span>{{ label }}</span>
        </slot>
      </iColumn>
    </iRow>
  </label>
</template>

<script>

export default {
  name: "iCheckbox",
  props: {
    /**
     * Number of milliseconds to debounce v-model updates
     */
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    /**
     * Disables the component's functionality and puts it in a disabled state
     *  @values true, false
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Sets the label of the checkbox (overridden by the label slot)
     */
    label: {
      type: String,
      required: false,
      default: "",
    },
    /**
     *  @model checked state binding
     *  @values true, false
     */
    modelValue: {
      type: [String, Boolean],
      required: true,
    },
    /**
     * Sets the value of the `name` attribute on the component
     */
    name: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * Adds the `readonly` attribute to the component
     * @values true, false
     */
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Adds the `required` attribute to the component
     * @values true, false
     */
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    trueValue: {
      type: [String, Boolean],
      default: true,
    },
    falseValue: {
      type: [String, Boolean],
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  styleGuide: () => ({
    containerPaddingTop: { "size.spacing": "small" },
    containerPaddingBottom: { "size.spacing": "small" },
    containerPaddingLeft: { "size.spacing": "none" },
    containerPaddingRight: { "size.spacing": "none" },
    containerMarginTop: { "size.spacing": "none" },
    containerMarginBottom: { "size.spacing": "none" },
    containerMarginLeft: { "size.spacing": "small" },
    containerMarginRight: { "size.spacing": "small" },
    inputColor: { "color.background": "striking" },
    inputBorderColor: { "color.border": "light" },
    labelMarginLeft: { "size.spacing": "small" },
    labelPaddingTop: { "size.spacing": "none" },
    labelFontFamily: { "font.typeface": "standard" },
    labelFontSize: { "size.font": "standard" },
    labelFontWeight: { "font.weight": "standard" },
    labelFontColor: { "color.font": "standard" },
    checkedColor: { "color.font": "standard" },
    checkboxHeight: { "size.icon": "standard" },
    checkboxWidth: { "size.icon": "standard" },
  }),
  emits: ["update:modelValue", "change"],
  data() {
    return {
      toggleValue: this.modelValue === this.trueValue,
    };
  },
  computed: {
    myOpacity() {
      if (this.disabled) {
        return 0.5;
      }
      return 1;
    },
    myInputColor() {
      if (this.toggleValue) {
        return this.inputColor;
      }

      return "transparent";
    },
  },
  watch: {
    modelValue(value) {
      this.toggleValue = value === this.trueValue;
    },
  },
  methods: {
    // including this so the linter does not complain
    // about the function being defined in the created hook
    emitUpdate: function(event) {
      this.$emit("update:modelValue", this.trueValue !== true ? this.toggleValue : event.target.checked);
      this.$emit("change", this.trueValue !== true ? this.toggleValue : event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  height: fit-content;
  opacity: v-bind('myOpacity');

  color: v-bind('$getStyles.labelFontColor');
  font-size: v-bind('$getStyles.labelFontSize');
  font-weight: v-bind('$getStyles.labelFontWeight');
  font-family: v-bind('$getStyles.labelFontFamily');

  input[type="checkbox"] {
    margin: 0;
    width: v-bind('$getStyles.checkboxWidth');
    height: v-bind('$getStyles.checkboxHeight');
    accent-color: v-bind('$getStyles.checkedColor');

    &::before {
      background-color: v-bind('myInputColor');
    }
  }

  span {
    padding-top: v-bind('$getStyles.labelPaddingTop');
  }
}
</style>
