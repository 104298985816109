<template>
  <iColumn v-tooltip="copied ? 'Link Copied' : `Copy Link`" width="hug">
    <iIcon v-if="!copied" icon="copy" @click="copy" />
    <iIcon v-else variant="branded" icon="check-circle" />
  </iColumn>
</template>

<script>
export default {
  name: "iCopyIcon",
  props: {
    textToCopy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.textToCopy).then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
