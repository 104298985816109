const DimensionsMixin = {
  data() {
    return {
      $dimensions: {
        parent: { width: 0, height: 0 },
        window: { width: 0, height: 0 },
        component: { width: 0, height: 0 },
        refs: [],
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.initializeDimensions();
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {
    $parentDimensions() {
      return this.$data.$dimensions.parent;
    },
    $componentDimensions() {
      return this.$data.$dimensions.component;
    },
    $windowDimensions() {
      return this.$data.$dimensions.window;
    },
  },
  methods: {
    $getReferenceElementDimensions(referenceName = null) {
      if (!referenceName) {
        return this.$data.$dimensions.refs;
      }
      let referenceElement = this.$data.$dimensions.refs.find(reference => reference.name === referenceName);
      if (referenceElement) {
        return referenceElement.dimensions;
      } else {
        return {
          width: 0,
          height: 0,
        };
      }
    },
    resizeHandler() {
      this.initializeDimensions();
    },
    initializeDimensions() {
      this.$nextTick(() => {
        this.updateElementDimensions(this.$data.$dimensions.window, window);
        if (this.$parent) {
          this.updateElementDimensions(this.$data.$dimensions.parent, this.$parent.$el);
        } else {
          this.updateElementDimensions(this.$data.$dimensions.parent, window);
        }
        this.updateElementDimensions(this.$data.$dimensions.component, this.$el);

        this.$data.$dimensions.refs = Object.entries(this.$refs).map(([name, element]) => ({
          name,
          dimensions: this.getAggregatedDimensionsForElement(element),
        }));
      });
    },
    getElementDimensions(element) {
      let width = element.clientWidth;
      let height = element.clientHeight;

      return { width, height };
    },
    updateElementDimensions(state, element) {
      let dimensions = this.getElementDimensions(element);
      state.width = dimensions.width;
      state.height = dimensions.height;
    },
    getAggregatedDimensionsForElement(element) {
      if (element.$el) {
        return this.getElementDimensions(element.$el);
      } else if (Array.isArray(element)) {
        return element.reduce((aggregateDimensions, subElement) => {
          let individualDimensions = this.getElementDimensions(subElement.$el);
          return {
            width: aggregateDimensions.width + individualDimensions.width,
            height: aggregateDimensions.height + individualDimensions.height,
          };
        }, { width: 0, height: 0 });
      } else {
        return { width: 0, height: 0 };
      }
    },
  },
};

export default DimensionsMixin;
