<template>
  <iSubHeading>Collapsable Card</iSubHeading>
  <StoryBook
    :components="components"
    :prop-options="propOptions"
  />
</template>

<script>
import StoryBook from "@/components/storybook/StoryBook";
export default {
  name: "CollapsableCardDocs",
  components: { StoryBook },
  data() {
    return {
      components: ["iCollapsableCard"],
      propOptions: {
      },
    };
  },
};
</script>
