<template>
  <main>
    <slot />
  </main>
</template>


<script>
export default {
  name: "iMain",
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontSize: { "size.font": "standard" },
    fontWeight: { "font.weight": "standard" },
    textColor: { "color.font": "standard" },
    margin: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
main{
  font-family: v-bind("$getStyles.fontFamily");
  font-size: v-bind("$getStyles.fontSize");
  font-weight: v-bind("$getStyles.fontWeight");
  color: v-bind("$getStyles.textColor");
  margin: v-bind("$getStyles.margin") 0;
}

</style>
