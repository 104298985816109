<template>
  <div :class="['toast', toastType]">
    <div class="toast-header">
      <p v-if="title" class="toast-title">
        {{ title }}
      </p>
      <div v-if="isClosable" class="toast-close" @click="closeToast">
        x
      </div>
    </div>
    <div class="toast-content">
      <p class="toast-text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useToastStore } from "@/stores/toast-store.js";

export default {
  name: "Toast",
  props: {
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    toastType: {
      type: String,
      required: false,
      default: "standard",
      validator(value) {
        return ["standard", "error", "success"].includes(value);
      },
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    isClosable: function() {
      return !this.timeout || this.closable;
    },
  },
  mounted() {
    if (this.timeout) {
      this.timer = setTimeout(this.closeToast, this.timeout);
    }
  },
  methods: {
    async closeToast() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.removeToast(this.id);
    },
    ...mapActions(useToastStore, { removeToast: "removeToast" }),
  },
};
</script>

<style scoped lang="scss">
$toast-color: (
        error:#d87676,
        success: #76d880,
        standard:#414141
);
.toast {
  display: flex;
  flex-direction: column;
  max-height: 10rem;
  width: 25rem;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(162, 162, 162);
  background-color: rgb(162, 162, 162);
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  @each $color, $value in $toast-color {
    &.#{$color} {
      background-color: lighten($value, 10%);

      .toast-header {
        background-color: lighten($value, 20%);
        border-bottom: 1px solid $value;
        color: $value;
      }

      .toast-content {
        color: darken($value, 30%);
      }
    }
  }

  &-header {
    padding: 0.2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 900;
  }

  &-title {
    margin: 0;
  }

  &-text {
    white-space:pre-line;
  }

  &-content {
    box-sizing: border-box;
    width: 100%;
    padding: 0.2rem;
  }

  &-close {
    cursor: pointer;
  }

}
</style>
