import { useStyleStore } from "@/stores/style-store.js";
import { isObject } from "lodash";

const assertStyleGuideEntryValid = function(styleGuideEntry) {
  if (!isObject(styleGuideEntry)) {
    throw new Error("styleGuideEntries must be objects");
  }
  if (Object.keys(styleGuideEntry).length !== 1) {
    throw new Error("styleGuideEntries must be objects of length 1");
  }
  const styleType = Object.keys(styleGuideEntry)[0];
  const styleDefault = styleGuideEntry[styleType];
  assertStyleValid(styleType, styleDefault);
};

const assertStyleValid = function(styleType, value) {
  if (styleType.indexOf(".") === -1) {
    throw new Error("style types must be dot delimited strings");
  }
  const typeArray = styleType.split(".");

  if (typeArray[0] === "color" && isValidColor(value)) {
    return;
  }

  if (typeArray[0] === "size" && isValidSize(value)) {
    return;
  }

  let structure = useStyleStore().themeStructure;
  typeArray.forEach((element, index) => {
    if (index === typeArray.length - 1) {
      if (!Array.isArray(structure[element])) {
        throw new Error(
          `styleType "${styleType}" is not valid, ${typeArray[1]} must be an array in the themeStructure object`,
        );
      }
      if (!structure[element].includes(value)) {
        throw new Error(`the style value: "${value}" is not a valid option for the provided type: ${styleType}`);
      }
    } else {
      if (!isObject(structure[element])) {
        throw new Error(`${element} : styleType is not valid, non-last entries must be objects in the theme`);
      }
      structure = structure[element];
    }
  });
};

const assertStyleGuideOverrideAreValid = function(guide, overrides) {
  if (!isObject(overrides)) {
    throw new Error("style overrides must be an object");
  }
  Object.keys(overrides).forEach(override => {
    if (!(override in guide)) {
      throw new Error(`${override} style override not found in style guide`);
    }
    const styleGuideEntry = guide[override];
    const styleType = Object.keys(styleGuideEntry)[0];
    assertStyleValid(styleType, overrides[override]);
  });
};

const getStyleValueForCurrentTheme = function(styleVarName, guide, overrides, theme) {
  const guideEntry = guide[styleVarName];
  const type = Object.keys(guideEntry)[0];
  const styleDefault = guideEntry[type];
  const typeArray = type.split(".");
  let structure = theme;
  let themeVal = null;
  let lastElement = null;
  typeArray.forEach((element, index) => {
    if (index === typeArray.length - 1) {
      // last element grab value
      themeVal = structure[element][styleDefault];
      lastElement = element;
    } else {
      // dig deeper
      structure = structure[element];
    }
  });
  if (overrides[styleVarName]) {
    const overrideElement = overrides[styleVarName];
    return structure[lastElement][overrideElement];
  } else {
    return themeVal;
  }
};

const getStylesForTheme = function(styleGuide, styleOverrides, myTheme) {
  // console.log("get styles: ", styleGuide, styleOverrides, myTheme);
  assertStyleGuideOverrideAreValid(styleGuide, styleOverrides);
  const response = {};
  Object.keys(styleGuide).forEach(styleVarName => {
    // const styleVarType = Object.keys(guide[styleVarName])[0];
    // this.assertStyleGuideEntryValid(styleVarType, guide[styleVarName][styleVarType]);
    // console.log("style name: ", styleVarName);
    assertStyleGuideEntryValid(styleGuide[styleVarName]);
    if (isValidColor(styleOverrides[styleVarName]) || isValidSize(styleOverrides[styleVarName])) {
      response[styleVarName] = styleOverrides[styleVarName];
    } else {
      response[styleVarName] = getStyleValueForCurrentTheme(styleVarName, styleGuide, styleOverrides, myTheme);
    }
  });

  return response;
};

const isValidColor = function(color) {
  if (/^#[0-9A-F]{6}$/i.test(color) || color === "transparent") {
    return color;
  }
};

const isValidSize = function(size) {
  if (/^\d+px$/.test(size)) {
    return size;
  }
};

const getStyleValuePropertiesFromTheme = (styleVarName, theme) => {
  return styleVarName.split(".").reduce((acc, key) => acc[key], theme);
};

export {
  getStylesForTheme,
  getStyleValuePropertiesFromTheme,
};
