<template>
  <iColumn
    :class="{
      'i-table-container': true,
      'fill-height': height === 'fill',
    }"
  >
    <iRow
      v-if="!hideActions || title"
      class="i-table-header"
      align="right"
      vertical-align="middle"
    >
      <iColumn v-if="title" width="fill">
        <iHeading>
          {{ title }}
        </iHeading>
      </iColumn>
      <iColumn v-if="!hideActions" width="hug">
        <slot name="actions" />
      </iColumn>
    </iRow>
    <iRow
      :class="{
        'fill-height': height === 'fill',
      }"
    >
      <table
        :class="{
          'i-table': true,
          'fill-height': height === 'fill',
        }"
      >
        <tr>
          <th v-for="(field, index) in tableFields" :key="field.key" :style="{width: field.width}">
            <slot
              v-if="$slots[`th(${field.key})`]"
              :name="`th(${field.key})`"
              :data="field"
              :value="field.label"
              :index="index"
            >
              {{ field.label }}
            </slot>
            <slot
              v-else
              name="th"
              :data="field"
              :value="field.label"
              :index="index"
            >
              {{ field.label }}
            </slot>
          </th>
        </tr>
        <template v-if="!isLoading && showEmptyState && tableData.length === 0">
          <tr>
            <td :colspan="tableFields.length">
              <slot name="empty">
                <iText>
                  {{ emptyString }}
                </iText>
              </slot>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td class="table-content-cell" :colspan="Object.keys(tableFields).length">
              <iInfiniteScroll :has-more="hasMore" hide-loading-state @load-more="$emit('load-more')">
                <table class="infinite-scroll-table">
                  <tr
                    v-for="(tableRow, index) in tableData"
                    :key="tableRow[tablePrimaryKey] ?? index"
                    @click="emitRowClick(tableRow[tablePrimaryKey] ?? index)"
                  >
                    <td v-for="field in tableFields" :key="`${field.key}_${tableRow[tablePrimaryKey] ?? index}`" :style="{width: field.width}">
                      <slot
                        v-if="$slots[`td(${field.key})`]"
                        :key="tableRow[tablePrimaryKey] ?? index"
                        :name="`td(${field.key})`"
                        :index="index"
                        :data="tableRow"
                        :value="tableRow[field.key]"
                        :field="field"
                      >
                        <iText>{{ tableRow[field.key] }}</iText>
                      </slot>
                      <slot
                        v-else
                        :key="`${field.key}_${tableRow[tablePrimaryKey] ?? index}`"
                        name="td"
                        :index="index"
                        :data="tableRow"
                        :value="tableRow[field.key]"
                        :field="field"
                      >
                        <iText>{{ tableRow[field.key] }}</iText>
                      </slot>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="isLoading" :colspan="tableFields.length">
                      <iRow align="center">
                        <iLoadingSpinner />
                      </iRow>
                    </td>
                  </tr>
                </table>
              </iInfiniteScroll>
            </td>
          </tr>
        </template>
      </table>
    </iRow>
  </iColumn>
</template>

<script>
import axios from "axios";
import { snakeToTitle } from "@";
import Enum from "@/data-types/enum";
import iInfiniteScroll from "@/components/widgets/iInfiniteScroll.vue";

export default {
  name: "iDataTable",
  components: {
    iInfiniteScroll,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
      default: "",
    },
    endpointResponseProperty: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    hideActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    primaryKey: {
      type: String,
      required: false,
      default: "",
    },
    showEmptyState: {
      type: Boolean,
      required: false,
      default: true,
    },
    emptyString: {
      type: String,
      required: false,
      default: "There is no data to display.",
    },
    hasMore: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug", // Nick thinks this is a more appropriate default
      options: ["fill", "hug"], // Fill seems suspicious here - Nick, maybe the whole height prop should not be here
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
  },
  styleGuide: () => ({
    tableFontFamily: { "font.typeface": "standard" },
    tableBorderWidth: { "size.border": "thin" },
    tableBorderColor: { "color.border": "dark" },
    tableBorderRadius: { "size.borderRadius": "large" },

    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    tableHeaderPaddingTop: { "size.spacing": "small" },
    tableHeaderPaddingBottom: { "size.spacing": "none" },
    tableHeaderPaddingLeft: { "size.spacing": "none" },
    tableHeaderPaddingRight: { "size.spacing": "large" },

    headerBackgroundColor: { "color.background": "standard" },
    headerFontSize: { "color.border": "standard" },
    headerFontWeight: { "font.weight": "standard" },
    headerBorderWidth: { "size.border": "none" },
    headerBorderColor: { "color.border": "none" },
    headerFontColor: { "color.font": "standard" },
    headerPaddingTop: { "size.spacing": "standard" },
    headerPaddingBottom: { "size.spacing": "standard" },
    headerPaddingLeft: { "size.spacing": "standard" },
    headerPaddingRight: { "size.spacing": "standard" },

    cellFontSize: { "size.font": "small" },
    cellFontWeight: { "font.weight": "light" },
    cellPaddingTop: { "size.spacing": "large" },
    cellPaddingBottom: { "size.spacing": "large" },
    cellPaddingLeft: { "size.spacing": "standard" },
    cellPaddingRight: { "size.spacing": "standard" },
  }),
  emits: ["row-click", "load-more"],
  data() {
    return {
      currentPage: null,
      endpointData: [],
    };
  },
  computed: {
    myHeight() {
      if (typeof this.height === "number") {
        return `${this.height}px`;
      }

      return this.height === "fill" ? "100%" : "fit-content";
    },
    myWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }

      return this.width === "fill" ? "100%" : "auto";
    },
    tableData() {
      if (this.endpoint !== "") {
        return this.endpointData;
      } else if (this.items) {
        return this.items;
      } else {
        return [];
      }
    },
    tableFields() {
      if (this.fields.length > 0) {
        return this.fields;
      }

      if (this.tableData.length > 0) {
        return Object.keys(this.tableData[0]).map(tableField => {
          return {
            key: tableField,
            label: snakeToTitle(tableField, true),
          };
        });
      }
      return [];
    },
    tablePrimaryKey() {
      if (this.primaryKey !== "") {
        return this.primaryKey;
      }
      return null;
    },
  },
  created() {
    if (this.endpoint !== "") {
      axios.get(this.endpoint).then(response => {
        if (this.endpointResponseProperty) {
          this.endpointData = response.data[this.endpointResponseProperty];
        } else {
          this.endpointData = response.data;
        }
      });
    }
  },
  methods: {
    emitRowClick(rowIdentifier) {
      this.$emit("row-click", rowIdentifier);
    },
  },
};

</script>

<style lang="scss" scoped>
.i-table {
    &-container {
        &.fill-height {
            height: 100%;
        }
        & .row.fill-height, & table.fill-height {
            height: 100%;
        }
    }
  &-header {
    padding-top: v-bind('$getStyles.tableHeaderPaddingTop');
    padding-bottom: v-bind('$getStyles.tableHeaderPaddingBottom');
    padding-left: v-bind('$getStyles.tableHeaderPaddingLeft');
    padding-right: v-bind('$getStyles.tableHeaderPaddingRight');
  }

  table-layout: fixed;
  width: v-bind(myWidth);
  color: v-bind('$getStyles.tableFontColor');
  font-family: v-bind('$getStyles.tableFontFamily');

  border-style: solid;
  border-color: v-bind('$getStyles.tableBorderColor');
  border-width: v-bind('$getStyles.tableBorderWidth');
  border-radius: v-bind('$getStyles.tableBorderRadius');

  border-spacing: 0;
  border-collapse: separate;
  overflow: auto;

  margin-top: v-bind('$getStyles.marginTop');
  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');

  padding-top: v-bind('$getStyles.paddingTop');
  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');

  th, td {
    text-align: left;
    font-size: v-bind('$getStyles.tableRowFontSize');
  }

  th {
    height: 35px;

    background-color: v-bind('$getStyles.headerBackgroundColor');
    font-size: v-bind('$getStyles.headerFontSize');
    font-weight: v-bind('$getStyles.headerFontWeight');
    color: v-bind('$getStyles.headerFontColor');

    border-color: v-bind('$getStyles.headerBorderColor');
    border-width: v-bind('$getStyles.headerBorderWidth');
    border-style: solid;

    padding-top: v-bind('$getStyles.headerPaddingTop');
    padding-bottom: v-bind('$getStyles.headerPaddingBottom');
    padding-left: v-bind('$getStyles.headerPaddingLeft');
    padding-right: v-bind('$getStyles.headerPaddingRight');
  }

  td {
    font-weight: v-bind('$getStyles.cellFontWeight');

    padding-top: v-bind('$getStyles.cellPaddingTop');
    padding-bottom: v-bind('$getStyles.cellPaddingBottom');
    padding-left: v-bind('$getStyles.cellPaddingLeft');
    padding-right: v-bind('$getStyles.cellPaddingRight');

    overflow-wrap: break-word;
    vertical-align: top;
  }

  .table-content-cell {
    padding: 0;
  }

  .loading-row {
    :deep(.spinner-container) {
      width: 100%;
    }
  }
}

td > .infinite-scroll-container {
  height: v-bind(myHeight);
}

.infinite-scroll-table {
  height: 100%;
  width: 100%;
  table-layout: fixed;
}
</style>
