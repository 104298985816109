<template>
  <div v-for="(item,index) in items" :key="index" class="container">
    <input
      :id="name+'_'+index"
      v-model="selectedItems"
      type="checkbox"
      :value="dataValue?itemValue[index]:item"
      :name="name+'_'+index"
      @change="emitUpdate"
    >
    <label class="label" :for="name+'_'+index">
      <slot name="label">
        <template v-if="itemText[index]">
          {{ itemText[index] }}
        </template>
      </slot>
    </label>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "iCheckList",
  props: {
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    textValue: {
      type: String,
      required: false,
      default: null,
    },
    dataValue: {
      type: String,
      required: false,
      default: null,
    },
    trueValue: {
      type: [String, Boolean],
      default: true,
    },
    falseValue: {
      type: [String, Boolean],
      default: false,
    },
  },
  styleGuide: () => ({
    labelFontFamily: { "font.typeface": "standard" },
    labelFontSize: { "size.font": "extraLarge" },
    labelFontWeight: { "font.weight": "standard" },
    labelFontColor: { "color.font": "standard" },
    inputColor: { "color.background": "light" },
    containerPaddingTop: { "size.spacing": "small" },
    containerPaddingBottom: { "size.spacing": "small" },
    containerPaddingLeft: { "size.spacing": "none" },
    containerPaddingRight: { "size.spacing": "none" },
    containerMarginTop: { "size.spacing": "none" },
    containerMarginBottom: { "size.spacing": "none" },
    containerMarginLeft: { "size.spacing": "small" },
    containerMarginRight: { "size.spacing": "small" },
  }),
  emits: ["update:modelValue", "change"],
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    itemText() {
      if (typeof this.items[0] === "object" && this.textValue) {
        return this.items.map(item => item[this.textValue]);
      } else {
        return this.items;
      }
    },
    itemValue() {
      if (typeof this.items[0] === "object" && this.dataValue) {
        return this.items.map(item => item[this.dataValue]);
      } else {
        return this.items;
      }
    },
  },
  created: function() {
    this.emitUpdate = _.debounce(function () {
      /**
       * v-model event
       * @property {boolean} checked Current checkbox state
       */
      this.$emit("update:modelValue", this.selectedItems);

    }, this.debounce);
  },
  methods: {
    // including this so the linter does not complain
    // about the function being defined in the created hook
    emitUpdate: function() {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  margin-top: v-bind('$getStyles.containerMarginTop');
  margin-bottom: v-bind('$getStyles.containerMarginBottom');
  margin-left: v-bind('$getStyles.containerMarginLeft');
  margin-right: v-bind('$getStyles.containerMarginRight');
  padding-top: v-bind('$getStyles.containerPaddingTop');
  padding-bottom: v-bind('$getStyles.containerPaddingBottom');
  padding-left: v-bind('$getStyles.containerPaddingLeft');
  padding-right: v-bind('$getStyles.containerPaddingRight');
}
.label {
  gap: v-bind('$getStyles.gap');
  color: v-bind('$getStyles.labelFontColor');
  font-size: v-bind('$getStyles.labelFontSize');
  font-weight: v-bind('$getStyles.labelFontWeight');
  font-family: v-bind('$getStyles.labelFontFamily');
}

input[type="checkbox"] {
  width: 20px;
  accent-color: v-bind('$getStyles.inputColor');
}
</style>
