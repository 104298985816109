<template>
  <iColumn :max-width="maxWidth" :width="width" wrap="nowrap">
    <iRow v-if="label && !showAsTextComputed">
      <label :for="textInputId" class="i-text-input-label" @click="$emit('click:label')">
        <slot>
          {{ label }}
        </slot>
      </label>
    </iRow>
    <iRow v-if="!showAsTextComputed" vertical-align="middle" wrap="nowrap">
      <iColumn :width="width">
        <iTextArea
          v-if="textArea"
          :id="textInputId"
          v-model="editValue"
          :disabled="isDisabled"
          :is-loading="isLoading"
          :placeholder="placeholder"
          :rows="textAreaRows"
          width="fill"
          @enter="submit"
          @click.prevent="$emit('click:label')"
        />
        <iTextInput
          v-else
          :id="textInputId"
          v-model="editValue"
          :disabled="isDisabled"
          :is-loading="isLoading"
          :placeholder="placeholder"
          width="fill"
          @enter="submit"
          @click.prevent="$emit('click:input')"
        />
      </iColumn>
      <iColumn v-if="showEditButton" vertical-align="middle" width="hug">
        <iButton
          v-if="!useEditIcon"
          :disabled="disabled"
          variant="tertiary"
          @click.prevent="edit"
        >
          Edit
        </iButton>
        <iIcon
          v-else
          :icon="editIcon"
          @click.prevent="edit"
        />
      </iColumn>
      <iColumn v-if="showActionAndCancelButtons && !showAsTextComputed" width="hug">
        <iRow vertical-align="middle" wrap="nowrap">
          <iColumn width="hug">
            <iButton
              :disabled="disabled || isLoading"
              size="small"
              variant="primary"
              @click.prevent="submit"
            >
              {{ submitText }}
            </iButton>
          </iColumn>
          <iColumn height="fill" width="hug">
            <iButton
              :disabled="disabled"
              variant="tertiary"
              @click.prevent="cancel"
            >
              Cancel
            </iButton>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
    <iColumn v-else-if="$slots.display" @click.prevent="edit">
      <slot name="display">
        <iText
          :font-size="textFontSize"
          :variant="editValue ? textVariant : 'subtle'"
          :wrap="textWrap"
          class="cursor-pointer text-input-text"
        >
          {{ editValue || placeholder || "Enter a value" }}
        </iText>
      </slot>
    </iColumn>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants";

export default {
  name: "iTextEdit",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: "",
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAsText: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    submitText: {
      type: String,
      required: false,
      default: "Save",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    useEditIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    editIcon: {
      type: String,
      required: false,
      default: "pencil",
    },
    variant: {
      type: Enum,
      required: false,
      default: "unlocked",
      options: ["locked", "unlocked"],
    },
    textVariant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error", "success", "branded"],
    },
    textFontSize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", ...themeStructure.size.font],
    },
    textWrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    textArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearValueOnSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearValueOnCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    maxWidth: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    textAreaRows: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  emits: ["submit", "click:label", "click:input", "cancel", "input", "edit"],
  data() {
    return {
      isEditing: false,
      editValue: this.value,
      isShowingAsInput: false,
    };
  },
  styleGuide: () => ({
    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    textPaddingTop: { "size.spacing": "extraSmall" },
    textPaddingRight: { "size.spacing": "extraSmall" },
    textPaddingBottom: { "size.spacing": "extraSmall" },
    textPaddingLeft: { "size.spacing": "extraSmall" },

    textHoverBackgroundColor: { "color.background": "standard" },
  }),
  computed: {
    showAsTextComputed() {
      return this.showAsText && !this.isShowingAsInput;
    },
    showActionAndCancelButtons() {
      if (this.variant === "unlocked") {
        if (this.value !== this.editValue) {
          return true;
        }
        if (this.editValue === "" && this.$attrs.value) {
          return true;
        }
      }
      if (this.variant === "locked") {
        return this.isEditing;
      }
      return false;
    },
    showEditButton() {
      return this.variant === "locked" && !this.isEditing;
    },
    isDisabled() {
      if (this.disabled) {
        return true;
      }
      return this.variant === "locked" && !this.isEditing;
    },
    textInputId() {
      if (this.id) {
        return this.id;
      }

      let suffix = this.label || Math.random().toString(36).slice(2, 7);
      suffix = suffix.replace(" ", "").toLowerCase();
      return `text-input-${suffix}`;
    },
  },
  watch: {
    editValue(newValue) {
      this.$emit("input", newValue);
    },
    isEditing(newValue) {
      this.$emit("edit", newValue);
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.editValue);
      if (this.clearValueOnSubmit) {
        this.editValue = "";
      }
      this.isEditing = false;
      this.isShowingAsInput = false;
    },
    edit() {
      this.isEditing = true;
      this.isShowingAsInput = true;
    },
    cancel(event) {
      this.isShowingAsInput = false;
      this.editValue = this.value;
      this.isEditing = false;
      this.$emit("cancel", event);
      if (this.clearValueOnCancel) {
        this.editValue = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-input-text {
  padding: v-bind('$getStyles.textPaddingTop') v-bind('$getStyles.textPaddingRight') v-bind('$getStyles.textPaddingBottom') v-bind('$getStyles.textPaddingLeft');
}

.text-input-text:hover {
  background: v-bind('$getStyles.textHoverBackgroundColor');
}
</style>

