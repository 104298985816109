<template>
  <iColumn width="hug" wrap="nowrap">
    <iRow width="fill" vertical-align="middle" wrap="nowrap">
      <iTextInput
        :model-value="text"
        disabled
        :width="width"
        right-icon="copy"
        @click-icon-right="copyLinkToClipboard"
      />
      <iText v-if="linkCopied && messagePosition=='right'">
        {{ copyMessage }}
      </iText>
    </iRow>
    <iRow v-if="linkCopied && messagePosition=='bottom'">
      <iText>
        {{ copyMessage }}
      </iText>
    </iRow>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";

export default {
  name: "iCopyText",
  props: {
    text: {
      type: String,
      required: true,
    },
    copyMessage: {
      type: String,
      required: false,
      default: "Copied!",
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: 500,
      options: ["fill", "hug"],
    },
    messagePosition: {
      type: Enum,
      required: false,
      default: "right",
      options: ["right", "bottom", "tooltip"],
    },
    messageTimeout: {
      type: Number,
      required: false,
      default: 3000,
    },
  },
  data() {
    return {
      linkCopied: false,
      timer: null,
    };
  },
  methods: {
    startTimer() {
      if (this.messageTimeout) {
        this.timer = setTimeout(this.closeMessage, this.messageTimeout);
      }
    },
    copyLinkToClipboard() {
      this.linkCopied = true;
      this.startTimer();
      navigator.clipboard.writeText(this.text);
    },
    closeMessage() {
      this.linkCopied = false;
      this.endTimer();
    },
    endTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
};
</script>
