<template>
  <iRow wrap="nowrap" vertical-align="middle">
    <iRow
      width="hug"
      class="reaction-container"
      vertical-align="middle"
      wrap="nowrap"
    >
      <iRow
        v-for="(details, reaction) in groupedReactions"
        :key="reaction"
        v-tooltip="details.users.join(', ')"
        width="hug"
        wrap="nowrap"
        :animations="[scaleAnimation]"
        class="reaction-item"
        vertical-align="middle"
        @click="$emit('reaction-selected', reaction)"
      >
        <iText font-size="verySmall" class="reaction-icon">
          {{ reaction }}
        </iText>
        <iText font-size="verySmall" class="reaction-count">
          {{ details.count }}
        </iText>
      </iRow>
    </iRow>
    <iColumn width="hug">
      <iReactionPicker button-text="+" @reaction-selected="$emit('reaction-selected', $event)" />
    </iColumn>
  </iRow>
</template>

<script>
export default {
  name: "iReactionViewer",
  props: {
    reactions: {
      type: Array,
      required: true,
    },
  },
  emits: ["reaction-selected"],
  data() {
    return {
      scaleAnimation: {
        effect: "scale",
        options: {
          duration: 200,
          to: 1.2,
        },
        onAction: "hover",
      },
    };
  },
  computed: {
    groupedReactions() {
      const groupedReactions = {};
      this.reactions.forEach(reaction => {
        if (groupedReactions[reaction.reaction]) {
          groupedReactions[reaction.reaction].count += 1;
          const users = reaction.users;
          if (!groupedReactions[reaction.reaction].users.includes(reaction.authorName)) {
            users.push(reaction.authorName);
          }
          groupedReactions[reaction.reaction].users = users;
        } else {
          groupedReactions[reaction.reaction] = {
            count: 1,
            users: [reaction.authorName],
          };
        }
      });
      return groupedReactions;
    },
    getReactionInfo() {
      return this.reactions.map(reaction => `${reaction.reaction} ${reaction.authorName}`).join(", ");
    },
  },
  styleGuide: () => ({
    paddingInline: { "size.spacing": "small" },
    paddingBlock: { "size.spacing": "extraSmall" },
    borderWidth: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    borderColor: { "color.border": "standard" },
  }),
};
</script>

<style lang="scss" scoped>
.reaction-container {
  position: relative;
  bottom: 2px;
}

.reaction-item {
  cursor: pointer;
  border: v-bind("$getStyles.borderWidth") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
  padding-inline: v-bind("$getStyles.paddingInline");
  padding-block: v-bind("$getStyles.paddingBlock");
}
</style>
