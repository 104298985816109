<template>
  <iColumn :width="width" overflow="visible">
    <input
      id="title"
      v-model="title"
      class="native-post-title"
      type="text"
      name="title"
      placeholder="Title"
      :readonly="previewMode"
    >

    <iColumn class="native-post-content-container">
      <input
        v-if="!previewMode"
        id="file"
        ref="file"
        type="file"
        hidden
        @change="uploadFunction"
      >

      <selection-bubble
        v-if="editor && !previewMode"
        :editor="editor"
        :should-show="shouldShow"
        :upload-post-image-loading="uploadPostImageLoading"
        @select-file="selectFile"
        @toggle-bold="toggleBold"
        @toggle-italic="toggleItalic"
        @toggle-link="toggleLink"
      />
      <editor-content class="editor-container" :editor="editor" />
    </iColumn>
    <iHashtagInput
      v-if="enableHashtags"
      v-model="hashtags"
      :disabled="disableHashtags"
      :placeholder="hashtagPlaceholder"
      :allow-duplicates="allowHashtagDuplicates"
      :split-hash-tags-by="splitHashtagsBy"
      :validation="validateHashtags"
      :edit="previewMode ? false: enableEditHashtags"
      :hashtag-value-key="hashtagValueKey"
      @submit:hashtag="$emit('submit:hashtag', $event)"
      @delete:hashtag="$emit('delete:hashtag', $event)"
    />
    <slot name="above-submit" />
    <iSpace v-if="gapAboveActionButtons !== 'none'" :height="20" />
    <iRow v-if="!previewMode" vertical-align="middle" :align="actionButtonAlign">
      <iColumn width="hug">
        <iButton variant="secondary" @click="cancelPost">
          {{ cancelButtonText }}
        </iButton>
      </iColumn>
      <iColumn width="hug">
        <slot name="submit-button">
          <iButton
            variant="primary"
            :disabled="updatePostIsLoading"
            :is-loading="updatePostIsLoading"
            @click="submitPost"
          >
            {{ submitButtonText }}
          </iButton>
        </slot>
      </iColumn>
    </iRow>
  </iColumn>
</template>

<script>
import { Editor, isTextSelection, EditorContent } from "@tiptap/vue-3";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import SelectionBubble from "@/components/widgets/SubComponents/post-editor/SelectionBubble";
import CustomImage from "@/components/widgets/extensions/post-editor/custom-image-extension";
import { MoveNodeUp } from "@/components/widgets/extensions/post-editor/move-up";
import { MoveNodeDown } from "@/components/widgets/extensions/post-editor/move-down";
import Enum from "@/data-types/enum.js";
import iHashtagInput from "@/components/widgets/iHashtagInput";

export default {
  name: "iRichTextEditor",
  components: {
    EditorContent,
    SelectionBubble,
    iHashtagInput,
  },
  props: {
    previewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    gapAboveActionButtons: {
      type: [Enum, Number],
      required: false,
      default: 20,
      options: ["none"],
    },
    width: {
      type: [Enum, Number],
      required: false,
      default: 600,
      options: ["fill", "hug"],
    },
    uploadPostImage: {
      type: Function,
      required: true,
      default: () => {},
    },
    actionButtonAlign: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "right", "center", "evenly", "around", "between"],
    },
    enableHashtags: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableHashtags: {
      type: Boolean,
      required: false,
      default: false,
    },
    hashtagPlaceholder: {
      type: String,
      required: false,
      default: "Add hashtags",
    },
    allowHashtagDuplicates: {
      type: Boolean,
      required: false,
      default: false,
    },
    splitHashtagsBy: {
      type: Array,
      required: false,
      default: () => [" ", ","],
    },
    validateHashtags: {
      type: Function,
      required: false,
      default: () => true,
    },
    enableEditHashtags: {
      type: Boolean,
      required: false,
      default: true,
    },
    hashtagValueKey: {
      type: String,
      required: false,
      default: "name",
    },
    updatePostIsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: "Cancel",
    },
    submitButtonText: {
      type: String,
      required: false,
      default: "Submit",
    },
  },
  emits: ["submit:post", "cancel:post", "submit:hashtag", "delete:hashtag", "input:content"],
  data() {
    return {
      title: "",
      editor: null,
      model: "",
      hashtags: [],
      uploadPostImageLoading: false,
    };
  },
  computed: {
    postContent() {
      return this.editor ? this.editor.getHTML() : "";
    },
  },
  watch: {
    title(newValue) {
      this.$emit("input:content", { title: newValue, content: this.postContent });
    },
    postContent(newValue) {
      this.$emit("input:content", { title: this.title, content: newValue });
    },
  },
  mounted() {
    this.editor = new Editor({
      content: "",
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: "Write something amazing..." }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: "https",
        }),
        CustomImage,
        MoveNodeUp,
        MoveNodeDown,
      ],
    });
    if (this.previewMode) {
      this.editor.setEditable(false);
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    shouldShow() {
      return ({ view, state, from, to }) => {
        const { doc, selection } = state;
        const { empty } = selection;
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        return true;
      };
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      let url;
      if (!previousUrl) {
        url = window.prompt("URL", previousUrl);
      }

      if (url === null) {
        return;
      }

      this.editor.chain().focus().extendMarkRange("link").toggleLink({ href: url, target: "_blank" }).run();
    },
    selectFile() {
      document.getElementById("file").click();
    },
    async uploadFunction() {
      this.uploadPostImageLoading = true;
      this.selectedFile = this.$refs.file.files[0];
      const imageUrl = await this.uploadPostImage(this.selectedFile);

      this.editor.chain().focus().setImage({ src: imageUrl }).run();
      this.$refs.file.value = "";
      this.uploadPostImageLoading = false;
    },
    cancelPost() {
      this.$emit("cancel:post");
    },
    submitPost() {
      this.$emit("submit:post", this.title, this.editor.getHTML(), this.hashtags);
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
