<template>
  <iSubHeading>Layout Components</iSubHeading>
  <StoryBook
    :components="components"
    :prop-options="propOptions"
  />
</template>

<script>
import StoryBook from "@/components/storybook/StoryBook";
export default {
  name: "LayoutComponents",
  components: { StoryBook },
  data() {
    return {
      components: ["iButton"],
      propOptions: {
        variant: ["text", "primary", "secondary"],
        disabled: [true, false],
        isLoading: [true, false],
        loadingText: ["please wait"],
        loadingTextPosition: ["inside", "after"],
        loadingSpinnerPosition: ["inside", "after"],
      },
    };
  },
};
</script>
