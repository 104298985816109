<template>
  <span class="i-text">
    <slot />
  </span>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";
export default {
  name: "iText",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error", "success", "branded"],
    },
    fontSize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", ...themeStructure.size.font],
    },
    wrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: [Enum, Number],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    fontWeight: {
      type: Enum,
      required: false,
      default: "default",
      options: ["default", ...themeStructure.font.weight],
    },
    ellipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    breakWord: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myWidth() {
      if (this.width === "hug") {
        return "fit-content";
      } else if (this.width === "fill") {
        return "100%";
      }
      return `${this.width}px`;
    },
    getFontFamily() {
      return this.$getStyles[`${this.variant}FontFamily`];
    },
    getFontSize() {
      if (this.fontSize !== "none") {
        return this.$getStyleValuePropertiesFromTheme("size.font")[this.fontSize];
      }

      return this.$getStyles[`${this.variant}FontSize`];
    },
    getFontColor() {
      return this.$getStyles[`${this.variant}FontColor`];
    },
    getFontWeight() {
      if (this.fontWeight === "default") {
        return this.$getStyles[`${this.variant}FontWeight`];
      } else {
        return this.$getStyleValuePropertiesFromTheme("font.weight")[this.fontWeight];
      }
    },
    myWhiteSpace() {
      return this.wrap ? "normal" : "nowrap";
    },
    myTextOverflow() {
      if (this.ellipsis) {
        return "ellipsis";
      }
      return "unset";
    },
    myDisplay() {
      if (typeof this.width === "number") {
        return "inline-block";
      }
      return "inline";
    },
    myWordBreak() {
      return this.breakWord ? "anywhere" : "normal";
    },
  },
  styleGuide: () => ({
    lineHeight: { "size.lineHeight": "standard" },

    subtleFontFamily: { "font.typeface": "standard" },
    subtleFontSize: { "size.font": "standard" },
    subtleFontColor: { "color.font": "subtle" },
    subtleFontWeight: { "font.weight": "standard" },

    accentFontFamily: { "font.typeface": "standard" },
    accentFontSize: { "size.font": "standard" },
    accentFontColor: { "color.font": "subtle" },
    accentFontWeight: { "font.weight": "standard" },

    brandedFontFamily: { "font.typeface": "standard" },
    brandedFontSize: { "size.font": "standard" },
    brandedFontColor: { "color.font": "branded" },
    brandedFontWeight: { "font.weight": "standard" },

    errorFontFamily: { "font.typeface": "standard" },
    errorFontSize: { "size.font": "standard" },
    errorFontColor: { "color.font": "error" },
    errorFontWeight: { "font.weight": "standard" },

    successFontFamily: { "font.typeface": "standard" },
    successFontSize: { "size.font": "standard" },
    successFontColor: { "color.font": "success" },
    successFontWeight: { "font.weight": "standard" },

    standardFontFamily: { "font.typeface": "standard" },
    standardFontSize: { "size.font": "standard" },
    standardFontColor: { "color.font": "standard" },
    standardFontWeight: { "font.weight": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
.i-text {
  font-family: v-bind(getFontFamily);
  font-size: v-bind(getFontSize);
  font-weight: v-bind(getFontWeight);
  line-height: v-bind('$getStyles.lineHeight');
  color: v-bind(getFontColor);
  white-space: v-bind(myWhiteSpace);
  width: v-bind(myWidth);
  text-overflow: v-bind(myTextOverflow);
  overflow: hidden;
  display: v-bind(myDisplay);
  overflow-wrap: v-bind(myWordBreak);

  margin-top: v-bind("$getStyles.marginTop");
  margin-bottom: v-bind("$getStyles.marginBottom");
  margin-left: v-bind("$getStyles.marginLeft");
  margin-right: v-bind("$getStyles.marginRight");
}
</style>
