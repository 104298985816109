<template>
  <ul
    class="breadcrumbs"
  >
    <li
      v-for="link in links"
      :key="link.label"
    >
      <iLink :hover-underline="true" :href="link.url">
        {{ link.label }}
      </iLink>&nbsp;&sol;&nbsp;
    </li>
    <li>
      <span class="current-page">{{ currentPage }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "iBreadcrumbs",
  props: {
    links: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: String,
      required: true,
    },
  },
  styleGuide: () => ({
    fontColor: { "color.font": "subtle" },
  }),

};
</script>

<style lang="scss" scoped>

.breadcrumbs {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  padding: 0;
  margin: 0;
  color: #ddd;

  a, a:visited, a:hover, span {
    color: v-bind('$getStyles.fontColor');
  }

  li {
    display: flex;
    white-space: nowrap;
  }
}

</style>
