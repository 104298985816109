<template>
  <h1 class="i-heading">
    <slot />
  </h1>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";
export default {
  name: "iHeading",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error", "light"],
    },
    align: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "center", "right"],
    },
    fontSize: {
      type: Enum,
      required: false,
      default: "default",
      options: ["default", ...themeStructure.size.font],
    },
    fontWeight: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["default", ...themeStructure.font.weight],
    },
  },
  computed: {
    getFontFamily() {
      return this.$getStyles[`${this.variant}FontFamily`];
    },
    getFontSize() {
      if (this.fontSize !== "default") {
        return this.$getStyleValuePropertiesFromTheme("size.font")[this.fontSize];
      }

      return this.$getStyles[`${this.variant}FontSize`];
    },
    getFontColor() {
      return this.$getStyles[`${this.variant}FontColor`];
    },
    getFontWeight() {
      if (this.fontWeight !== "default") {
        return this.$getStyleValuePropertiesFromTheme("font.weight")[this.fontWeight];
      }

      return this.$getStyles[`${this.variant}FontWeight`];
    },
  },
  styleGuide: () => ({
    lineHeight: { "size.lineHeight": "standard" },

    subtleFontFamily: { "font.typeface": "heading" },
    subtleFontSize: { "size.font": "extraExtraLarge" },
    subtleFontColor: { "color.font": "subtle" },
    subtleFontWeight: { "font.weight": "standard" },

    accentFontFamily: { "font.typeface": "heading" },
    accentFontSize: { "size.font": "extraExtraLarge" },
    accentFontColor: { "color.font": "accent" },
    accentFontWeight: { "font.weight": "standard" },

    errorFontFamily: { "font.typeface": "heading" },
    errorFontSize: { "size.font": "extraExtraLarge" },
    errorFontColor: { "color.font": "error" },
    errorFontWeight: { "font.weight": "standard" },

    standardFontFamily: { "font.typeface": "heading" },
    standardFontSize: { "size.font": "extraExtraLarge" },
    standardFontColor: { "color.font": "standard" },
    standardFontWeight: { "font.weight": "standard" },

    lightFontFamily: { "font.typeface": "heading" },
    lightFontSize: { "size.font": "extraExtraLarge" },
    lightFontColor: { "color.font": "light" },
    lightFontWeight: { "font.weight": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
  h1 {
    font-family: v-bind(getFontFamily);
    font-size: v-bind(getFontSize);
    font-weight: v-bind(getFontWeight);
    line-height: v-bind('$getStyles.lineHeight');
    color: v-bind(getFontColor);
    text-align: v-bind(align);

    margin-top: v-bind("$getStyles.marginTop");
    margin-bottom: v-bind("$getStyles.marginBottom");
    margin-left: v-bind("$getStyles.marginLeft");
    margin-right: v-bind("$getStyles.marginRight");
  }
</style>
