import { EASING_FUNCTIONS } from "@/constants/effect-constants";

export const rotate = {
  options: {
    duration: 1000,
    from: 0,
    to: 30,
    easing: "",
  },
  beforeAnimationHandler(el, options) {
    const { duration, from, easing = EASING_FUNCTIONS.EASE_IN_OUT } = options;
    const transition = `rotate ${duration}ms ${easing}`;
    if (!el.style.transition) {
      el.style.transition = transition;
    } else {
      el.style.transition = [el.style.transition, transition].join(", ");
    }
    el.style.rotate = `${from}deg`;
  },
  animationHandler: (el, options) => {
    const { to } = options;
    setTimeout(() => {
      el.style.rotate = `${to}deg`;
    }, 10);
  },
  afterAnimationHandler: (el, options) => {
    const { from } = options;
    el.style.rotate = `${from}deg`;
  },
};
