// used to dispatch and listen for custom js events
export const listen = (eventName, callbackFunction) => {
  window.addEventListener(eventName, callbackFunction);
};

export const dispatch = (eventName, data, dataProp = "data") => {
  window.dispatchEvent(new CustomEvent(eventName, {
    bubbles: true,
    detail: { [dataProp]: data },
  }));
};
export default { listen, dispatch };
