/* eslint-disable */
import { nextTick } from "vue";
import PropValidationError from "@/errors/prop-validation-error";

const OFFSET = 1;

/**
 * IMPORTANT: IF USED INSIDE A LOOP, MAKE SURE TO ADD A UNIQUE KEY TO THE ELEMENTS, OTHERWISE THE DIRECTIVE WILL NOT
 * WORK, SOME COMPONENTS HAVE INTERNAL UNIQUE KEYS, BUT IF YOU ARE USING A CUSTOM COMPONENT, MAKE SURE TO ADD A KEY
 *
 * Emits the following events:
 * - onResize: When the window is resized
 * - onScroll: When the window is scrolled
 *
 * Usage:
 *  Parent has to have a data-parent-id attribute with a unique value corresponding to the id passed to the directive
 *
 *  v-follow-parent="'parent-id'"
 *  v-follow-parent="{
 *    parentId: 'parent-id',
 *    position: 'top-right', // top, bottom, left, right, center-left, center-right
 *    onResize: (event) => {},
 *    onScroll: (event) => {},
 *    onPositionChange: ({top, left}) => {}
 *  }"
 */

export const followParent = {
  mounted(el, binding) {
    let parentId;
    let position = 'bottom-left';
    let callbacks = {
      onResize: () => {
      },
      onScroll: () => {
      },
      onPositionChange: () => {
      },
    };

    if (typeof binding.value === "string") {
      parentId = binding.value;
    } else {
      parentId = binding.value.parentId;
      position = binding.value.position || position;
      callbacks = {
        onResize: binding.value.onResize || callbacks.onResize,
        onScroll: binding.value.onScroll || callbacks.onScroll,
        onPositionChange: binding.value.onPositionChange || callbacks.onPositionChange,
      };
    }
    const parent = document.querySelector(`[data-parent-id="${parentId}"]`);

    if (!parent) {
      throw new PropValidationError(`No element found with data-parent-id="${parentId}"`, { parentId });
    }

    el.style.position = "fixed";
    el.style.zIndex = "2";

    const updatePosition = () => {
      const parentRect = parent.getBoundingClientRect();
      const elRect = el.getBoundingClientRect();
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;

      let top = parentRect.top;
      let left = parentRect.left;

      // Positioning
      switch (position) {
        case 'top-left':
          top = parentRect.top - elRect.height - OFFSET;
          left = parentRect.left;
          break;
        case 'top-right':
          top = parentRect.top - elRect.height - OFFSET;
          left = parentRect.right - elRect.width;
          break;
        case 'bottom-left':
          top = parentRect.bottom + OFFSET;
          left = parentRect.left;
          break;
        case 'bottom-right':
          top = parentRect.bottom + OFFSET;
          left = parentRect.right - elRect.width;
          break;
        case 'left':
          top = parentRect.top + (parentRect.height - elRect.height) / 2;
          left = parentRect.left - elRect.width - OFFSET;
          break;
        case 'right':
          top = parentRect.top + (parentRect.height - elRect.height) / 2;
          left = parentRect.right + OFFSET;
          break;
        case 'center-left':
          top = parentRect.top + (parentRect.height - elRect.height) / 2;
          left = parentRect.left - elRect.width - OFFSET;
          break;
        case 'center-right':
          top = parentRect.top + (parentRect.height - elRect.height) / 2;
          left = parentRect.right + OFFSET;
          break;
      }

      // Ensure element stays within the viewport
      if (top + elRect.height > clientHeight) {
        top = clientHeight - elRect.height - OFFSET;
      }
      if (top < 0) {
        top = OFFSET;
      }
      if (left + elRect.width > clientWidth) {
        left = clientWidth - elRect.width - OFFSET;
      }
      if (left < 0) {
        left = OFFSET;
      }

      el.style.top = `${top}px`;
      el.style.left = `${left}px`;
      el.style.height = "fit-content";
      callbacks.onPositionChange({ top, left });
    };

    function updateOnScroll(e) {
      callbacks.onScroll(e);
      updatePosition();
    }

    function updateOnResize(e) {
      callbacks.onResize(e);
      updatePosition();
    }

    const addScrollListeners = element => {
      element.addEventListener("scroll", updateOnScroll, { passive: true });
      if (element.parentElement && element.parentElement !== document.documentElement) {
        addScrollListeners(element.parentElement);
      }
    };

    nextTick(() => {
      let retries = 2;
      const setup = setInterval(() => {
        if (retries === 0) {
          clearInterval(setup);
          return;
        }
        retries--;
        updatePosition();
      }, 1);

      window.addEventListener("resize", updateOnResize);
      addScrollListeners(parent);
      addScrollListeners(el);
    });

    el._followParentCleanup = () => {
      window.removeEventListener("resize", updateOnResize);
      const removeScrollListeners = element => {
        element.removeEventListener("scroll", updateOnScroll);
        if (element.parentElement && element.parentElement !== document.documentElement) {
          removeScrollListeners(element.parentElement);
        }
      };
      removeScrollListeners(parent);
      removeScrollListeners(el);
    };
  },

  unmounted(el) {
    if (el._followParentCleanup) {
      el._followParentCleanup();
    }
  },
};