<template>
  <iDropdown
    v-click-outside="(e) => $emit('close', e)"
    :position="position"
    :show-dropdown="showDropdown"
    class="i-dropdown-menu"
    :teleport-to-body="teleportToBody"
    @close="$emit('close')"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
    <template #overlay>
      <iMenu
        :active-item="activeItem"
        :items="items"
        class="i-dropdown-menu-content"
        orientation="vertical"
        @click="(value) => $emit('click', value)"
      />
    </template>
  </iDropdown>
</template>

<script>
import Enum from "@/data-types/enum";
import iDropdown from "@/components/layout/iDropdown";
import iMenu from "@/components/widgets/iMenu";

export default {
  name: "iDropdownMenu",
  components: { iDropdown, iMenu },
  props: {
    showDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    position: {
      type: [Enum, String],
      required: false,
      default: "left",
      options: ["left", "right"],
    },
    zIndex: {
      type: Number,
      required: false,
      default: 1,
    },
    teleportToBody: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  styleGuide: () => ({
    backgroundColor: { "color.background": "paper" },
    borderColor: { "color.border": "dark" },
    borderWidth: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },

    gap: { "size.spacing": "small" },

    marginTop: { "size.spacing": "standard" },
    marginBottom: { "size.spacing": "standard" },
    marginRight: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },

    menuItemFontSize: { "size.font": "standard" },
    menuItemHoverBackgroundColor: { "color.background": "subtle" },
    menuItemPaddingTop: { "size.spacing": "standard" },
    menuItemPaddingBottom: { "size.spacing": "standard" },
    menuItemPaddingRight: { "size.spacing": "extraLarge" },
    menuItemPaddingLeft: { "size.spacing": "extraLarge" },
  }),
  emits: ["close", "click"],
  data() {
    return {
      activeItem: "",
      internalId: this.uniqueId(),
    };
  },
  mounted() {
    this.$exposeStyleGuide("idropdownmenu");
  },
  methods: {
    uniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },
};
</script>

<style lang="scss" scoped>
.i-dropdown-menu {
  &-content {
    background-color: var(--idropdownmenu-backgroundColor);
    gap: var(--idropdownmenu-gap);
    border-radius: var(--idropdownmenu-borderRadius);

    :deep(.menu-item) {
      padding: 0;

      :hover {
        cursor: pointer;
        background-color: var(--idropdownmenu-menuItemHoverBackgroundColor);
      }
    }

    :deep(.nav-link) {
      width:100%;
      font-size: var(--idropdownmenu-menuItemFontSize);

      padding-top: var(--idropdownmenu-menuItemPaddingTop);
      padding-bottom: var(--idropdownmenu-menuItemPaddingBottom);
      padding-right: var(--idropdownmenu-menuItemPaddingRight);
      padding-left: var(--idropdownmenu-menuItemPaddingLeft);
    }
  }
}
</style>
