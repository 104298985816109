<template>
  <div class="toast-wrapper">
    <TransitionGroup>
      <Toast
        v-for="toast in toasts"
        :id="toast.id"
        :key="toast.id"
        :text="toast.text"
        :timeout="toast.timeout"
        :title="toast.title"
        :toast-type="toast.toastType"
        :closable="toast.closable"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import Toast from "@/components/toast/Toast.vue";
import { useToastStore } from "@/stores/toast-store.js";

export default {
  name: "ToastWrapper",
  components: { Toast },
  computed: {
    ...mapWritableState(useToastStore, ["toasts"]),
  },
};
</script>

<style scoped>
.toast-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    padding: 1rem;
    gap: 1rem;
    box-sizing: border-box;
}

.v-enter-active {
    transition:max-height 0.2s ease, opacity 0.5s ease 0.5s ;
}
.v-leave-active {
    transition:opacity 0.5s ease, max-height 0.2s ease 0.5s ;
}
.v-enter-from,.v-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
