<template>
  <div class="infinite-scroll-container">
    <div id="content" class="infinite-scroll">
      <iColumn
        ref="content"
        width="fill"
        height="fill"
        overflow="scroll"
        :wrap="wrap"
      >
        <InfiniteLoading
          v-if="scrollDirection === 'top' && hasMore"
          :distance="scrollThreshold"
          top
          firstload
          @infinite="loadMore"
        >
          <template #spinner>
            <iLoadingSpinner v-if="isLoading && !hideLoadingState" />
            <div v-else />
          </template>
        </InfiniteLoading>

        <!-- @slot Contents of the infinite scroll -->
        <slot />

        <InfiniteLoading
          v-if="scrollDirection === 'bottom' && hasMore"
          :distance="scrollThreshold"
          firstload
          @infinite="loadMore"
        >
          <template #spinner>
            <iLoadingSpinner v-if="isLoading && !hideLoadingState" />
            <div v-else />
          </template>
        </InfiniteLoading>
      </iColumn>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import iLoadingSpinner from "@/components/icons/iLoadingSpinner";
import Enum from "@/data-types/enum";

export default {
  name: "iInfiniteScroll",
  components: { iLoadingSpinner, InfiniteLoading },
  props: {
    /**
     * Variable prop which determines whether the data source has more data available
     * @values true, false
     */
    hasMore: {
      type: Boolean,
      required: true,
      default: false,
    },
    /**
     * Variable prop which holds the data loading state. The loadMore event will not trigger while this is true
     * @values true, false
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Toggles whether to hide the loading slot
     * @values true, false
     */
    hideLoadingState: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Height of the infinite scroll container
     * @values [Number, "fill", "hug"]
     */
    height: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    /**
     * Direction of the infinite scroll
     * @values ["top", "bottom"]
     */
    scrollDirection: {
      type: Enum,
      required: false,
      default: "bottom",
      options: ["top", "bottom"],
    },
    /**
     * Threshold for the infinite scroll
     * @values [Number]
     */
    scrollThreshold: {
      type: Number,
      required: false,
      default: 0,
    },
    wrap: {
      type: Enum,
      required: false,
      default: "nowrap",
      options: ["wrap", "nowrap", "wrap-reverse", "inherit", "unset"],
    },
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
  }),
  emits: ["loadMore"],
  data() {
    return {
      hasScrolled: false,
      savedScrollHeight: 0,
      savedContentCount: 1,
    };
  },
  computed: {
    calculatedHeight() {
      if (typeof this.height === "number") {
        return `${this.height}px`;
      }

      return this.height === "fill" ? "100%" : "fit-content";
    },
  },
  mounted() {
    const element = this.$refs.content.$el;
    element.addEventListener("scroll", () => {
      this.checkThreshold();
    });

    const observer = new MutationObserver(() => {
      this.$nextTick(() => {
        this.scrollTillClientHeightReached();

        if (this.scrollDirection === "top") {
          this.restoreScrollPosition();

          this.scrollToBottom();
        }
      });
    });

    observer.observe(this.$el, {
      childList: true,
      subtree: true,
    });
  },
  methods: {
    scrollToBottom: function () {
      const element = this.$refs.content.$el;

      const hasContent = element.children.length > 1;
      if (this.isLoading || this.hasScrolled || !hasContent) {
        return;
      }

      element.scrollTop = element.scrollHeight;
      this.hasScrolled = true;
    },

    saveScrollPosition() {
      this.savedScrollHeight = this.$refs.content.$el.scrollHeight;
    },
    restoreScrollPosition() {
      const currentContentCount = this.$refs.content.$el.children.length;
      if (currentContentCount === this.savedContentCount) {
        return;
      }

      const currentScrollHeight = this.$refs.content.$el.scrollHeight;
      this.$refs.content.$el.scrollTop = currentScrollHeight - this.savedScrollHeight;
      this.savedContentCount = currentContentCount;
    },

    checkThreshold() {
      const element = this.$refs.content.$el;
      const bottomScrollPosition = element.scrollTop + element.clientHeight;

      if (element.scrollHeight - bottomScrollPosition < this.scrollThreshold) {
        this.loadMore();
      }
    },

    scrollTillClientHeightReached() {
      const element = this.$refs.content.$el;

      let childrenHeight = 0;
      for (let i = 0; i < element.children.length; i++) {
        childrenHeight += element.children[i].clientHeight;
      }
      if (childrenHeight < element.clientHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      if (this.isLoading || !this.hasMore) {
        return;
      }
      this.saveScrollPosition();

      this.$emit("loadMore");
    },
  },
};
</script>

<style scoped lang="scss">
.infinite-scroll-container {
  display: flex;
  flex-direction: column;
  height: v-bind(calculatedHeight);
  width: 100%;

  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-top: v-bind('$getStyles.paddingTop');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');

  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-top: v-bind('$getStyles.marginTop');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');
}

.infinite-scroll {
  height: 100%;
  scrollbar-width: none;
}
</style>
