<template>
  <bubble-menu
    :editor="editor"
    :tippy-options="{duration: 100}"
    :should-show="shouldShow"
  >
    <div class="bubble-menu">
      <button
        v-if="!hideBold"
        :class="{'is-active': editor.isActive('bold')}"
        tabindex="-1"
        @click="toggleBold"
      >
        B
      </button>
      <button
        v-if="!hideItalic"
        :class="{'is-active': editor.isActive('italic')}"
        tabindex="-1"
        @click="toggleItalic"
      >
        <em>I</em>
      </button>
      <button
        v-if="!hideLink"
        :class="{'is-active': editor.isActive('link')}"
        tabindex="-1"
        @click="toggleLink"
      >
        <iIcon icon="hyperlink" />
      </button>
      <button
        v-if="!hideImage"
        :disabled="uploadPostImageLoading"
        tabindex="-1"
        @click="selectFile"
      >
        <iLoadingSpinner v-if="uploadPostImageLoading" />
        <iIcon v-else icon="image" />
      </button>
    </div>
  </bubble-menu>
</template>

<script>
import { BubbleMenu } from "@tiptap/vue-3";

export default {
  name: "SelectionBubble",
  components: {
    BubbleMenu,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    shouldShow: {
      type: Function,
      required: true,
    },
    uploadPostImageLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideBold: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideItalic: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["selectFile", "toggleBold", "toggleItalic", "toggleLink"],
  methods: {
    // text functions
    toggleBold() {
      this.$emit("toggleBold");
    },
    toggleItalic() {
      this.$emit("toggleItalic");
    },
    toggleLink() {
      this.$emit("toggleLink");
    },

    // media functions
    selectFile() {
      this.$emit("selectFile");
    },
  },
};
</script>

<style scoped lang="scss">
.bubble-menu {
  display: flex;
  gap: 4px;

  padding: 8px;
  background-color: #ffffff;

  border: 1px solid #E7E5EB;
  border-radius: 8px;

  button {
    min-width: 32px;
    background-color: unset;
    border: none;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background-color: #E7E5EB;
    }

    &.is-active {
      background-color: #E7E5EB;
      font-weight: 700;

      &:hover {
        background-color: #FAF9FB;
      }
    }
  }
}
</style>
