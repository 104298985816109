<template>
  <div
    v-if="direction === 'horizontal' && typeof height == 'number' && width === 'fill'"
    :class="`flex-box parent row-width-fill ${$attrs.class}`"
    @click="$emit('click')"
  >
    <div :class="`flex-box row-height-px-child row-width-fill`">
      <slot />
    </div>
  </div>
  <div
    v-else-if="direction === 'horizontal' && height === 'hug' && width === 'fill'"
    :class="`flex-box height-hug-parent ${$attrs.class} row-width-fill`"
    @click="$emit('click')"
  >
    <slot />
  </div>
  <div
    v-else-if="direction === 'horizontal' && width === 'fill'"
    :class="`flex-box ${$attrs.class} row-width-fill`"
    @click="$emit('click')"
  >
    <slot />
  </div>
  <div
    v-else
    :class="`flex-box ${$attrs.class}`"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants.js";
export default {
  name: "iFlex",
  inheritAttrs: false,
  props: {
    overflow: {
      type: Enum,
      required: false,
      default: "visible",
      options: ["scroll", "hidden", "visible", "none"],
    },
    direction: {
      type: Enum,
      required: false,
      default: "vertical",
      options: ["vertical", "horizontal"],
    },
    justifyContent: {
      type: String,
      required: false,
      default: "flex-start",
      options: ["flex-start", "flex-end", "center", "space-evenly", "space-around", "space-between"],
    },
    alignItems: {
      type: Enum,
      required: false,
      default: "flex-start",
      options: ["flex-start", "flex-end", "center"],
    },
    gap: {
      type: [Number, Enum],
      required: false,
      default: "standard",
      options: [...themeStructure.size.spacing],
    },
    secretHeight: {
      type: Number,
      required: false,
      default: 0,
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    wrap: {
      type: Enum,
      required: false,
      default: "wrap",
      options: ["wrap", "nowrap", "wrap-reverse"],
    },
  },
  emits: ["click"],
  computed: {
    mySecretHeight() {
      if (this.secretHeight === 0) {
        return this.myHeight;
      }
      return `${this.secretHeight}px`;
    },
    myDisplay() {
      if (this.width === "hug") {
        return "inline-flex";
      }
      return "flex";
    },
    myMaxWidth() {
      return this.overflow === "scroll" ? "unset" : "100%";
    },
    myAlignSelf() {
      if (this.direction === "horizontal" && this.height === "hug" && this.width === "fill") {
        return "auto";
      }
      if (this.direction === "horizontal" && this.height === "hug") {
        return "auto";
      }
      if (this.direction === "horizontal") {
        return "stretch";
      }
      if (this.height === "fill") {
        return "stretch";
      }
      return "auto";
    },
    myGap() {
      if (typeof this.gap === "number") {
        return `${this.gap}px`;
      }

      return this.$getStyleValuePropertiesFromTheme("size.spacing")[this.gap];
    },
    myWrap() {
      return this.wrap;
    },
    myGrow() {
      if (this.direction === "vertical" && this.width === "fill") {
        return 1;
      }
      if (this.direction === "horizontal" && this.width === "fill") {
        return 1;
      }
      return 0;
    },
    myShrink() {
      if (this.direction === "horizontal") {
        return 0;
      }
      return 0;
    },
    myBasis() {
      if (this.direction === "vertical" && this.width === "fill") {
        return "0%";
      }
      if (this.direction === "horizontal" && this.width === "fill") {
        return "100%";
        // return "0%";
      }
      return "auto";
    },
    myHeight() {
      if (typeof this.height == "number") {
        return `${this.height}px`;
      }
      if (this.height === "hug") {
        return "auto";
      }
      return "unset";
    },
    myOverflow() {
      if (this.overflow === "scroll") {
        return "auto";
      }
      if (this.overflow === "none") {
        return "unset";
      }
      return this.overflow;
    },
    myWidth() {
      if (this.width === "fill" && this.direction === "horizontal") {
        return "auto";
      }
      if (typeof this.width == "number") {
        return `${this.width}px`;
      }
      if (this.width === "hug") {
        return "auto";
      }
      return "unset";
    },
    flexDirection() {
      return this.direction === "horizontal" ? "row" : "column";
    },
  },
  styleGuide: () => ({
    borderWidth: { "size.border": "none" },
    borderColor: { "color.border": "standard" },

    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
.flex-box {
  max-width: v-bind(myMaxWidth);
  display: v-bind(myDisplay);
  width: v-bind(myWidth);
  min-height: v-bind(myHeight);
  max-height: v-bind(myHeight);
  border-style: solid;
  box-sizing: border-box;
  overflow: v-bind(myOverflow);

  flex-direction: v-bind(flexDirection);
  flex-wrap: v-bind(myWrap);
  flex-grow: v-bind(myGrow);
  flex-shrink: v-bind(myShrink);
  flex-basis: v-bind(myBasis);
  justify-content: v-bind(justifyContent);
  align-items: v-bind(alignItems);
  align-self: v-bind(myAlignSelf);

  border-width: v-bind('$getStyles.borderWidth');
  border-color: v-bind('$getStyles.borderColor');
  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-top: v-bind('$getStyles.paddingTop');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');
  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-top: v-bind('$getStyles.marginTop');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');

  gap: v-bind(myGap);
}
.container {
  height: v-bind(mySecretHeight);
}
.parent {
  flex-basis: 100%;
}
.row-height-px-child {
  flex-basis: auto;
}
.height-hug {
  &-parent {
    flex-basis: 100%;
    align-self: stretch;
    height: fit-content;
  }

  &-child {
    align-self: auto;
  }
}
</style>
