<template>
  <h3>
    <slot>
      {{ text }}
    </slot>
  </h3>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iTitle",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: ["standard", "subtle", "accent", "error"],
    },
  },
  computed: {
    getFontFamily() {
      return this.$getStyles[`${this.variant}FontFamily`];
    },
    getFontSize() {
      return this.$getStyles[`${this.variant}FontSize`];
    },
    getFontColor() {
      return this.$getStyles[`${this.variant}FontColor`];
    },
    getFontWeight() {
      return this.$getStyles[`${this.variant}FontWeight`];
    },
  },
  styleGuide: () => ({
    lineHeight: { "size.lineHeight": "standard" },

    subtleFontFamily: { "font.typeface": "standard" },
    subtleFontSize: { "size.font": "large" },
    subtleFontColor: { "color.font": "subtle" },
    subtleFontWeight: { "font.weight": "standard" },

    accentFontFamily: { "font.typeface": "standard" },
    accentFontSize: { "size.font": "large" },
    accentFontColor: { "color.font": "subtle" },
    accentFontWeight: { "font.weight": "standard" },

    errorFontFamily: { "font.typeface": "standard" },
    errorFontSize: { "size.font": "large" },
    errorFontColor: { "color.font": "subtle" },
    errorFontWeight: { "font.weight": "standard" },

    standardFontFamily: { "font.typeface": "standard" },
    standardFontSize: { "size.font": "large" },
    standardFontColor: { "color.font": "standard" },
    standardFontWeight: { "font.weight": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
  h3 {
    font-family: v-bind("getFontFamily");
    font-size: v-bind("getFontSize");
    font-weight: v-bind("getFontWeight");
    line-height: v-bind('$getStyles.lineHeight');
    color: v-bind("getFontColor");

    margin-top: v-bind("$getStyles.marginTop");
    margin-bottom: v-bind("$getStyles.marginBottom");
    margin-left: v-bind("$getStyles.marginLeft");
    margin-right: v-bind("$getStyles.marginRight");
  }
</style>
