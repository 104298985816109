<template>
  <iRow vertical-align="top">
    <iColumn :width="200">
      <iMenuNew orientation="column" :items="sidebarNavItems" />
    </iColumn>
    <iColumn v-if="section" width="fill">
      <component :is="section" />
    </icolumn>
  </iRow>
</template>

<script>
import LayoutComponents from "@/components/storybook/LayoutComponents";
import RowDocs from "@/components/storybook/components/layout/RowDocs";
import ColumnDocs from "@/components/storybook/components/layout/ColumnDocs";
import CardDocs from "@/components/storybook/components/layout/CardDocs";
import CollapsableCardDocs from "@/components/storybook/components/layout/CollapsableCardDocs";

import FormComponents from "@/components/storybook/FormComponents";
import ButtonDocs from "@/components/storybook/components/form/ButtonDocs";
import SelectDocs from "@/components/storybook/components/form/SelectDocs";

import TextComponents from "@/components/storybook/TextComponents";
import HeadingDocs from "@/components/storybook/components/text/HeadingDocs";
import SubHeadingDocs from "@/components/storybook/components/text/SubHeadingDocs";
import ParagraphDocs from "@/components/storybook/components/text/ParagraphDocs";
import TextDocs from "@/components/storybook/components/text/TextDocs";

export default {
  name: "iStoryBook",
  components: {
    FormComponents,
    ButtonDocs,
    SelectDocs,

    LayoutComponents,
    RowDocs,
    ColumnDocs,
    CardDocs,
    CollapsableCardDocs,

    TextComponents,
    HeadingDocs,
    SubHeadingDocs,
    ParagraphDocs,
    TextDocs,
  },
  data: function() {
    return {
      section: false,
      sidebarNavItems: [
        { text: "Form Components", items: this.formMenuItems() },
        /*
        { text: "href", url: "https://www.apple.com", target: "_blank" },
        { text: "handler", onClick: () => {
          alert("here i am"); 
        } },
        { text: "Text Components", items: this.textMenuItems },
*/
        { text: "Layout Components", items: this.getLayoutMenuItems() },
      ],
    };
  },
  methods: {
    getLayoutMenuItems() {
      return [
        { text: "Column", onClick: () => this.changeSection("ColumnDocs") },
        { text: "Row", onClick: () => this.changeSection("RowDocs") },
        { text: "Card", onClick: () => this.changeSection("CardDocs") },
        //       { text: "more",   items: [{ text: "one" }, { text: "two" }, { text: "three" }] }
      ];
    },
    textMenuItems() {
      return [
        { text: "Heading", onClick: () => this.changeSection("Heading") },
        { text: "SubHeading", onClick: () => this.changeSection("SubHeading") },
        { text: "Paragraph", onClick: () => this.changeSection("Paragraph") },
        { text: "Text", onClick: () => this.changeSection("Text") },
      ];
    },
    formMenuItems() {
      return [
        { text: "Buttons", onClick: () => this.changeSection("ButtonDocs") },
        { text: "Select", onClick: () => this.changeSection("SelectDocs") },
      ];
    },
    changeSection(section) {
      this.section = section;
    },
  },
};
</script>
