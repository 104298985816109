<template>
  <div :class="`image-container ${shape}`">
    <img
      v-if="srcset"
      :srcset="srcset"
      :alt="alt"
    >
    <img
      v-else
      :src="src"
      :alt="alt"
    >
    <div class="overlay">
      <slot name="overlay" />
    </div>
  </div>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iImageContainer",
  props: {
    src: {
      type: String,
      required: false,
      default: "",
    },
    srcset: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "fill"],
      default: "auto",
    },
    shape: {
      type: Enum,
      required: false,
      options: ["rectangle", "circle"],
      default: "rectangle",
    },
    width: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "fill"],
      default: "auto",
    },
    alt: {
      type: String,
      required: false,
      default: "",
    },
    zIndexOverlay: {
      type: Number,
      required: false,
      default: 1,
    },
    objectFit: {
      type: Enum,
      required: false,
      default: "contain",
      options: ["none", "scale-down", "fill", "cover", "contain"],
    },
    objectPosition: {
      type: Enum,
      required: false,
      default: "center",
      options: ["top", "bottom", "left", "right", "center"],
    },
  },
  computed: {
    myHeight() {
      if (this.height === "auto") {
        return "auto";
      }
      if (this.height === "fill") {
        return "100%";
      }
      return `${this.height}px`;
    },
    myWidth() {
      if (this.width === "auto") {
        return "auto";
      }
      if (this.width === "fill") {
        return "100%";
      }
      return `${this.width}px`;
    },
  },
  styleGuide: () => ({
    overlayFontFamily: { "font.typeface": "standard" },
    overlayFontSize: { "size.font": "large" },
    overlayFontColor: { "color.font": "standard" },
    borderRadius: { "size.borderRadius": "small" },
  }),
};
</script>

<style scoped>
.overlay {
  position: absolute;
  text-align: center;
  color: v-bind('$getStyles.overlayFontColor');
  font-size: v-bind('$getStyles.overlayFontSize');
  font-family: v-bind('$getStyles.overlayFontFamily');
  top:0;
  left: 0;
  z-index:v-bind(zIndexOverlay);
}

.image-container {
  &.circle {
    border-radius: 100%;

    img, .overlay {
      border-radius: inherit;
    }

    .overlay {
      width: inherit;
      height: inherit;
      overflow: hidden;
    }
  }
  width: v-bind('myWidth');
  height: v-bind('myHeight');
  position: relative;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: v-bind(objectFit);
    object-position: v-bind(objectPosition);
  }
}
</style>
