<template>
  <div />
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum.js";

export default {
  name: "iSpace",
  props: {
    width: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "fill"],
      default: 0,
    },
    height: {
      type: [Number, Enum],
      required: false,
      options: ["auto", "fill"],
      default: 0,
    },
    backgroundColor: {
      type: [String, Enum],
      required: false,
      default: "none",
      options: ["none", ...themeStructure.color.background],
    },
  },
  computed: {
    myWidth() {
      if (typeof this.width === "string") {
        if (this.width === "auto") {
          return "auto";
        }
        if (this.width === "fill") {
          return "100%";
        }
      } else if (this.width > 0) {
        return `${this.width}px`;
      }

      return "1px";
    },
    myHeight() {
      if (typeof this.height === "string") {
        if (this.height === "auto") {
          return "auto";
        }
        if (this.height === "fill") {
          return "100%";
        }
      } else if (this.height > 0) {
        return `${this.height}px`;
      }

      return "1px";
    },
    myBackgroundColor() {
      if (this.backgroundColor === "none") {
        return "transparent";
      }

      return this.$getStyleValuePropertiesFromTheme("color.background")[this.backgroundColor];
    },
  },
};
</script>

<style scoped lang="scss">
div {
  display: inline-block;
  width: v-bind('myWidth');
  height: v-bind('myHeight');
  background-color: v-bind('myBackgroundColor');
}
</style>
