<template>
  <iColumn gap="extraLarge" width="fill">
    <iRow v-for="(question, index) in questions" :key="question.name">
      <iFormQuestion :question="question" :index="index" :number-question="numberQuestions" />
    </iRow>
    <iRow>
      <slot />
    </iRow>
  </iColumn>
</template>

<script>
import iFormQuestion from "@/components/forms/iFormQuestion";

export default {
  name: "iForm",
  components: [iFormQuestion],
  props: {
    numberQuestions: {
      type: Boolean,
      required: false,
      default: false,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
};
</script>
