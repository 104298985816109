<template>
  <iRow :width="width" :height="height" vertical-align="middle">
    <div class="alert-container" :class="{[variant]: true}">
      <iRow wrap="nowrap">
        <iColumn
          v-if="!hideIcon"
          gap="none"
          width="hug"
          height="fill"
        >
          <iSpace :height="3" />
          <iIcon
            disable-hover
            variant="light"
            size="standard"
            :icon="myIcon"
          />
        </iColumn>
        <iColumn>
          <slot name="title">
            <p class="alert-container-title">
              {{ myTitle }}
            </p>
          </slot>
          <slot />
        </iColumn>
      </iRow>
    </div>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";

export default {
  name: "iAlert",
  props: {
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["hug", "fill"],
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: Enum,
      required: false,
      default: "info",
      options: ["info", "success", "warning", "error"],
    },
  },
  computed: {
    myIcon() {
      if (this.icon) {
        return this.icon;
      }
      switch (this.variant) {
        case "info":
          return "info-circle";
        case "success":
          return "check-circle";
        case "warning":
          return "exclamation-triangle";
        case "error":
          return "exclamation-triangle";
        default:
          return "info-circle";
      }
    },
    myTitle() {
      if (this.title) {
        return this.title;
      }
      switch (this.variant) {
        case "info":
          return "Information";
        case "success":
          return "Success";
        case "warning":
          return "Warning";
        case "error":
          return "Error";
        default:
          return "Information";
      }
    },
  },
  styleGuide: () => ({
    infoBackgroundColor: { "color.font": "informational" },
    errorBackgroundColor: { "color.font": "error" },
    warningBackgroundColor: { "color.font": "warning" },
    successBackgroundColor: { "color.font": "success" },

    infoFontColor: { "color.font": "light" },
    errorFontColor: { "color.font": "light" },
    warningFontColor: { "color.font": "light" },
    successFontColor: { "color.font": "light" },

    alertPadding: { "size.spacing": "standard" },

    titleFontSize: { "size.font": "large" },

    borderRadius: { "size.borderRadius": "large" },
  }),
};
</script>

<style scoped lang="scss">
.alert-container {
  padding: v-bind('$getStyles.alertPadding');
  width: 100%;
  box-sizing: border-box;
  border-radius: v-bind('$getStyles.borderRadius');

  &.info {
    background-color: v-bind('$getStyles.infoBackgroundColor');
    color: v-bind('$getStyles.infoFontColor');
  }

  &.success {
    background-color: v-bind('$getStyles.successBackgroundColor');
    color: v-bind('$getStyles.successFontColor');
  }

  &.warning {
    background-color: v-bind('$getStyles.warningBackgroundColor');
    color: v-bind('$getStyles.warningFontColor');
  }

  &.error {
    background-color: v-bind('$getStyles.errorBackgroundColor');
    color: v-bind('$getStyles.errorFontColor');
  }

  &-title {
    font-weight: bold;
    font-size: v-bind('$getStyles.titleFontSize');
    margin: 0;
  }
}
</style>
