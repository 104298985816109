<template>
  <iRow :width="width" class="i-text-input-wrapper">
    <component :is="layoutDirection" gap="none" vertical-align="middle">
      <component :is="childComponent" v-if="label" width="hug">
        <label :for="getTextInputId()" class="i-text-input-label">
          <slot>
            {{ label }}
          </slot>
        </label>
      </component>

      <component
        :is="childComponent"
        :class="{
          'predictive': isFocused && predictiveText,
        }"
        :data-predictive-text="predictiveText"
        class="i-text-input-container"
        vertical-align="middle"
        wrap="nowrap"
      >
        <iIcon
          v-if="leftIcon"
          :icon="leftIcon"
          class="icon-left"
          @click="emitClickIconLeft"
        />
        <iColumn v-if="$slots.prepend" width="hug" class="slot-left">
          <iColumn>
            <slot name="prepend" />
          </iColumn>
        </iColumn>
        <input
          :id="getTextInputId()"
          ref="input"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :class="{
            'i-text-input': true,
            disabled,
            'i-text-input-background': !predictiveText
          }"
          :disabled="disabled"
          :name="name"
          :pattern="myPattern"
          :placeholder="placeholder"
          :readonly="readonly"
          :required="required"
          :type="myType"
          :value="modelValue"
          @blur="emitBlur"
          @focus="emitFocus"
          @input="emitUpdate"
          @keyup.enter="emitEnter"
        >


        <iRow
          v-if="predictiveText || rightIcon || type === 'password'"
          class="icon-right"
          vertical-align="middle"
          width="hug"
          wrap="nowrap"
        >
          <iRow
            v-if="isFocused && predictiveText"
            class="predictive-text-insert"
            vertical-align="middle"
            wrap="nowrap"
          >
            Hit
            <pre>Enter</pre>
            to insert
          </iRow>
          <iIcon
            v-if="rightIcon && (type !== 'password')"
            :icon="rightIcon"
            @click="emitClickIconRight"
          />
          <iIcon
            v-if="type === 'password'"
            :icon="passwordVisible ? 'visibility' : 'visibility-off'"
            @click="passwordVisible = !passwordVisible"
          />
          <iLoadingSpinner v-if="isLoading" class="i-text-input-spinner" />
        </iRow>
      </component>
    </component>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";

export default {
  name: "iTextInput",
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    labelPosition: {
      type: [String, Enum],
      required: false,
      default: "above",
      options: ["above", "left"],
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: 500,
      options: ["fill", "hug"],
    },
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    // this is no longer used, use labelPosition instead
    direction: {
      type: Enum,
      required: false,
      default: "DO NOT USE",
      options: ["DO NOT USE"],
    },
    pattern: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: [String, Enum],
      required: false,
      default: "text",
      options: [
        "text", "password", "email", "number", "search", "tel", "url",
      ],
    },
    justifyContent: {
      type: [String, Enum],
      required: false,
      default: "unset",
      options: ["flex-start", "flex-end", "center", "unset"],
    },
    alignItems: {
      type: [String, Enum],
      required: false,
      default: "unset",
      options: ["flex-start", "flex-end", "center", "unset"],
    },
    leftIcon: {
      type: String,
      required: false,
      default: "",
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightIcon: {
      type: String,
      required: false,
      default: "",
    },
    paddingLeftMultiplier: {
      type: Number,
      required: false,
      default: 4,
    },
    predictiveText: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: [
    "blur",
    "focus",
    "update:modelValue",
    "change",
    "enter",
    "clickIconLeft",
    "clickIconRight",
    "update:predictiveText",
    "click:iconRight",
    "click:iconLeft",
  ],
  data() {
    return {
      passwordVisible: false,
      isFocused: false,
    };
  },
  computed: {
    layoutDirection() {
      if (this.labelPosition === "above") {
        return "iColumn";
      }
      return "iRow";
    },
    childComponent() {
      return this.labelPosition === "left" ? "iColumn" : "iRow";
    },
    myPattern() {
      return this.pattern === "" ? null : this.pattern;
    },
    myWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }

      return this.width === "fill" ? "100%" : "auto";
    },
    myInputPaddingLeft() {
      return this.leftIcon || this.$slots.prepend ?
        `calc(${this.$getStyles.inputPaddingLeft} * ${this.paddingLeftMultiplier})` :
        this.$getStyles.inputPaddingLeft;
    },
    myIconSize() {
      return this.$getStyles.iconSize;
    },
    spinnerPadding() {
      if (this.rightIcon) {
        return this.$getStyles.inputPaddingRight + this.myIconSize;
      }

      return this.$getStyles.inputPaddingRight;
    },
    myType() {
      return this.type === "password" && this.passwordVisible ? "text" : this.type;
    },
  },
  styleGuide: () => ({
    labelFontSize: { "size.font": "standard" },
    labelFontWeight: { "font.weight": "standard" },
    labelTypeFace: { "font.typeface": "standard" },
    labelFontColor: { "color.font": "standard" },

    labelMarginTop: { "size.spacing": "none" },
    labelMarginBottom: { "size.spacing": "none" },
    labelMarginLeft: { "size.spacing": "none" },
    labelMarginRight: { "size.spacing": "none" },

    labelSpacing: { "size.spacing": "standard" },

    inputPaddingTop: { "size.spacing": "standard" },
    inputPaddingBottom: { "size.spacing": "standard" },
    inputPaddingLeft: { "size.spacing": "standard" },
    inputPaddingRight: { "size.spacing": "standard" },
    inputBackgroundColor: { "color.background": "subtle" },
    inputBorderWidth: { "size.border": "standard" },
    inputBorderColor: { "color.border": "dark" },
    inputBorderRadius: { "size.borderRadius": "large" },
    inputFontColor: { "color.font": "standard" },
    inputPlaceholderColor: { "color.font": "subtle" },
    inputFontSize: { "size.font": "standard" },
    lineHeight: { "size.lineHeight": "standard" },
    inputFontWeight: { "font.weight": "standard" },
    inputTypeFace: { "font.typeface": "standard" },
    inputBorderFocusColor: { "color.border": "dark" },
    inputBorderFocusWidth: { "size.border": "standard" },
    inputBorderFocusRadius: { "size.borderRadius": "large" },

    disabledInputBackgroundColor: { "color.background": "subtle" },
    disabledInputFontColor: { "color.font": "disabled" },
    disabledInputBorderColor: { "color.border": "disabled" },

    predictiveTextColor: { "color.font": "subtle" },

    iconSize: { "size.icon": "small" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    borderWidth: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "standard" },
  }),
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    getTextInputId() {
      if (this.id) {
        return this.id;
      }

      let suffix = this.label || this.placeholder;
      suffix = suffix.replace(" ", "").toLowerCase();
      return `text-input-${suffix}`;
    },
    emitUpdate(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("change", event.target.value);
    },
    emitBlur(event) {
      this.$emit("blur", event);
      this.isFocused = false;
    },
    emitFocus(event) {
      this.$emit("focus", event);
      this.isFocused = true;
    },
    emitEnter(event) {
      this.$emit("enter", event);
      if (this.predictiveText) {
        this.$emit("update:modelValue", this.predictiveText);
        this.$emit("update:predictiveText", this.predictiveText);
      }
    },
    setFocus() {
      this.$refs.input.focus();
    },
    emitClickIconLeft(event) {
      this.$emit("clickIconLeft", event);
      this.$emit("click:iconLeft", event);
    },
    emitClickIconRight(event) {
      this.$emit("clickIconRight", event);
      this.$emit("click:iconRight", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.i-text-input {

  &-background {
    background-color: v-bind('$getStyles.inputBackgroundColor');
  }

  &-wrapper {
    gap: v-bind('$getStyles.labelSpacing');

    &.flex-box {
      margin-top: v-bind('$getStyles.marginTop');
      margin-bottom: v-bind('$getStyles.marginBottom');
      margin-left: v-bind('$getStyles.marginLeft');
      margin-right: v-bind('$getStyles.marginRight');
    }
  }

  &-label {
    width: v-bind(myWidth);
    color: v-bind('$getStyles.labelFontColor');
    font-size: v-bind('$getStyles.labelFontSize');
    line-height: v-bind('$getStyles.lineHeight');
    font-weight: v-bind('$getStyles.labelFontWeight');
    font-family: v-bind('$getStyles.labelTypeFace');

    margin-top: v-bind('$getStyles.labelMarginTop');
    margin-bottom: v-bind('$getStyles.labelMarginBottom');
    margin-left: v-bind('$getStyles.labelMarginLeft');
    margin-right: v-bind('$getStyles.labelMarginRight');
  }

  &-container {
    position: relative;

    .icon-left {
      position: absolute;
      left: v-bind("$getStyles.inputPaddingLeft");
    }

    .slot-left {
      position: absolute;
      left: v-bind("$getStyles.inputPaddingLeft");

      .col {
        position: relative;
        bottom: 1px;
      }
    }

    .icon-right {
      position: absolute;
      right: v-bind("$getStyles.inputPaddingRight");
    }

    &.predictive:not(:has(input.disabled))::before {
      content: attr(data-predictive-text);
      position: absolute;
      z-index: -1;
      bottom: v-bind('$getStyles.inputBorderWidth');
      top: v-bind('$getStyles.inputBorderWidth');
      left: v-bind('$getStyles.inputBorderWidth');
      right: v-bind('$getStyles.inputBorderWidth');
      font-size: v-bind('$getStyles.inputFontSize');
      line-height: v-bind('$getStyles.lineHeight');
      font-weight: v-bind('$getStyles.inputFontWeight');
      font-family: v-bind('$getStyles.inputTypeFace');
      pointer-events: none;
      border-radius: v-bind('$getStyles.inputBorderRadius');
      color: v-bind('$getStyles.predictiveTextColor');
      background-color: v-bind('$getStyles.inputBackgroundColor');
      height: calc(100% - v-bind('$getStyles.inputBorderWidth') * 2);
      padding: v-bind('$getStyles.inputPaddingTop') v-bind('$getStyles.inputPaddingRight') v-bind('$getStyles.inputPaddingBottom') v-bind(myInputPaddingLeft);
      box-sizing: border-box;
      width: calc(100% - v-bind('$getStyles.inputBorderWidth') * 2)
    }
  }

  border-style: solid;
  box-sizing: border-box;
  outline: none;
  width: v-bind(myWidth);
  color: v-bind('$getStyles.inputFontColor');
  background-color: transparent;
  border-width: v-bind('$getStyles.inputBorderWidth');
  border-radius: v-bind('$getStyles.inputBorderRadius');
  border-color: v-bind('$getStyles.inputBorderColor');
  font-size: v-bind('$getStyles.inputFontSize');
  line-height: v-bind('$getStyles.lineHeight');
  font-weight: v-bind('$getStyles.inputFontWeight');
  font-family: v-bind('$getStyles.inputTypeFace');
  padding-top: v-bind('$getStyles.inputPaddingTop');
  padding-bottom: v-bind('$getStyles.inputPaddingBottom');
  padding-left: v-bind(myInputPaddingLeft);
  padding-right: v-bind('$getStyles.inputPaddingRight');

  &.disabled {
    background-color: v-bind('$getStyles.disabledInputBackgroundColor');
    color: v-bind('$getStyles.disabledInputFontColor');
    border-color: v-bind('$getStyles.disabledInputBorderColor');
  }

  ::placeholder {
    color: v-bind('$getStyles.inputPlaceholderColor');
  }
}

input[type=text]:focus {
  border-color: v-bind('$getStyles.inputBorderFocusColor');
  border-width: v-bind('$getStyles.inputBorderFocusWidth');
  border-radius: v-bind('$getStyles.inputBorderFocusRadius');
}

.predictive-text-insert {
  color: v-bind("$getStyles.inputPlaceholderColor");
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
}
</style>
