<template>
  <iColumn
    :width="width"
    overflow="visible"
    height="fill"
    class="native-post-content-container"
  >
    <selection-bubble
      v-if="editor"
      :editor="editor"
      :should-show="shouldShow"
      :upload-post-image-loading="uploadPostImageLoading"
      @select-file="selectFile"
      @toggle-bold="toggleBold"
      @toggle-italic="toggleItalic"
      @toggle-link="toggleLink"
    />
    <editor-content class="editor-container" :editor="editor" />
    <input
      id="file"
      ref="file"
      type="file"
      hidden
      @change="uploadFunction"
    >
  </iColumn>
</template>

<script>
import { Editor, isTextSelection, EditorContent } from "@tiptap/vue-3";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import SelectionBubble from "@/components/widgets/SubComponents/post-editor/SelectionBubble";
import CustomImage from "@/components/widgets/extensions/post-editor/custom-image-extension";
import { MoveNodeUp } from "@/components/widgets/extensions/post-editor/move-up";
import { MoveNodeDown } from "@/components/widgets/extensions/post-editor/move-down";
import Enum from "@/data-types/enum.js";

export default {
  name: "iRichTextEditorNew",
  components: {
    EditorContent,
    SelectionBubble,
  },
  props: {
    width: {
      type: [Enum, Number],
      required: false,
      default: 600,
      options: ["fill", "hug"],
    },
    uploadPostImage: {
      type: Function,
      required: true,
      default: () => {
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: "Write something amazing...",
    },
  },
  emits: ["update:modelValue", "input", "change"],
  data() {
    return {
      editor: null,
      uploadPostImageLoading: false,
    };
  },
  computed: {
    postContent() {
      return this.editor ? this.editor.getHTML() : "";
    },
  },
  watch: {
    postContent(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: "",
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: this.placeholder }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: "https",
        }),
        CustomImage,
        MoveNodeUp,
        MoveNodeDown,
      ],
    });
    window.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "b") {
        this.toggleBold();
      }
      if (event.ctrlKey && event.key === "i") {
        this.toggleItalic();
      }
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    shouldShow() {
      return ({ view, state, from, to }) => {
        const { doc, selection } = state;
        const { empty } = selection;
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        return true;
      };
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      let url;
      if (!previousUrl) {
        url = window.prompt("URL", previousUrl);
      }

      if (url === null) {
        return;
      }

      this.editor.chain().focus().extendMarkRange("link").toggleLink({ href: url, target: "_blank" }).run();
    },
    selectFile() {
      document.getElementById("file").click();
    },
    async uploadFunction() {
      this.uploadPostImageLoading = true;
      this.selectedFile = this.$refs.file.files[0];
      const imageUrl = await this.uploadPostImage(this.selectedFile);

      this.editor.chain().focus().setImage({ src: imageUrl }).run();
      this.$refs.file.value = "";
      this.uploadPostImageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;
        height: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
