<template>
  <div class="timeline-container">
    <div v-for="(item, idx) in items" :key="`idx-${idx}-${uniqueId}`">
      <template v-if="!item.children || !item.children.length">
        <div
          class="timeline-item-container"
        >
          <div
            :class="{
              'main-timeline-bubble': true,
              'bubble': true,
              'primary': item.primary,
              'secondary': item.secondary,
              'small': item.bubbleSmall,
              'bubble-text-small': item.bubbleText?.length >= 3,
            }"
          />
          <div v-tooltip:bottom="item.title" class="timeline-details-container-template">
            <div class="timeline-title">
              {{ item.title }}
            </div>
            <div class="timeline-description">
              {{ item.description }}
            </div>
          </div>
          <slot v-if="item.secondary" name="latest-timeline-actions" />
          <slot name="actions" />
        </div>
      </template>
      <details v-else class="timeline-item-container timeline-item-details-container">
        <summary>
          <div class="timeline-item sub">
            <div class="bubble-group">
              <div class="bubble small" />
              <div class="bubble small" />
              <div class="bubble small" />
            </div>
            <div class="timeline-details-container-summary">
              <div class="timeline-title">
                {{ item.title }}
              </div>
              <div class="timeline-description">
                Click to expand
              </div>
            </div>
          </div>
        </summary>
        <iColumn class="timeline-children">
          <iTimeLine :items="item.children" />
        </iColumn>
      </details>
    </div>
  </div>
</template>

<script>
import PropValidationError from "@/errors/prop-validation-error";

export default {
  name: "iTimeLine",
  props: {
    items: {
      type: Array,
      required: true,
      validator: items => {
        items.forEach(item => {
          if (!item.title) {
            throw new PropValidationError("Every item in the array should have a \"title\" property");
          }
          if (item.children && !Array.isArray(item.children)) {
            throw new PropValidationError("Every item in the array should have a \"children\" property that is an array");
          }
          if (item.bubbleSmall !== undefined && typeof item.bubbleSmall !== "boolean") {
            throw new PropValidationError("The \"bubbleSmall\" property should be a boolean");
          }
          if (item.description !== undefined && typeof item.description !== "string") {
            throw new PropValidationError("The \"description\" property should be a string");
          }
          if (item.bubbleText !== undefined && typeof item.bubbleText !== "string") {
            throw new PropValidationError("The \"bubbleText\" property should be a string");
          }
        });
        return true;
      },
    },
    showMoreText: {
      type: String,
      default: "Show more",
    },
  },
  methods: {
    uniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },
  styleGuide: () => ({
    bubbleBackground: { "color.font": "subtle" },
    bubbleBorderColor: { "color.background": "dark" },
    bubbleBorderWidth: { "size.border": "thick" },
  }),
};
</script>

<style lang="scss" scoped>
.timeline-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 9px;
  padding-top: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 32px;
    bottom: 32px;
    left: 31px;
    width: 1px;
    z-index: 0;
    background: rgb(225, 225, 225);
  }
}

.timeline-item {
  &-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    position: relative;
    padding-left: 8px;
    padding-top: 3px;
    margin-bottom: 4px;
  }

  &.sub {
    display: flex;
    align-items: center;
  }

  &-details-container {
    position: relative;
    right: 6px;
  }
}

.timeline-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.timeline-description {
  font-size: 0.8rem;
  opacity: 0.5;
}

.timeline-collapse {
  font-size: 0.8rem;
  opacity: 0.75;
}

.timeline-details-container-summary {
  padding-left: 50px;
}

.timeline-details-container-template {
  padding-left: 64px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.timeline-date {
  font-size: 0.8rem;
}

.timeline-child {
  cursor: pointer;
}

.timeline-children {
  position: relative;
  left: -11px;
}


.bubble-group {
  display: flex;
  position: relative;
  left: 9px;
  top: -10px;
}

[data-bubble-small="true"] {
  .timeline-details-container-template {
    padding-left: 16px;
  }
}

.main-timeline-bubble {
  position: absolute;
  left: -6px;

  &.small {
    left: 12px;
  }
}

.bubble {
  border: rgb(225, 225, 225) solid 1px;
  background: rgb(239, 239, 239);
  border-radius: 50%;
  padding: 0.25rem;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;

  &.primary {
    background: #6eff5d;
  }

  &.secondary {
    background: #ebff5d;
  }

  .bubble-text-small {
    font-size: 12px;
  }
}

.bubble.small {
  width: 11px;
  height: 11px;

  &:nth-child(2) {
    position: absolute;
    top: 8px;
  }

  &:nth-child(3) {
    position: absolute;
    top: 16px;
  }
}

details {
  margin-block: 12px;
  &[open] {
    margin-block: 0;

    .timeline-container {
      padding-top: 0;
    }

    summary {
      margin-bottom: 16px;
      display: none;

      span.expand {
        display: none;
      }

      span.collapse {
        display: block;
      }
    }
  }

  summary {
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;

    span.expand {
      display: inline-block;
      padding-left: 3px;
    }

    span.collapse {
      display: none;
      padding-left: 3px;
    }
  }
}
</style>
