<template>
  <a
    :href="href"
    :target="target"
    class="text-link i-dynamic-text"
  >
    <slot />
  </a>
</template>

<script>
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants";

export default {
  name: "iLink",
  isUsableInDynamicElements: true,
  supportedDynamicComponentOptions: [
    "TextOptions",
  ],
  props: {
    href: {
      type: String,
      required: false,
      default: "javascript:undefined",
    },
    width: {
      type: Enum,
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    target: {
      type: Enum,
      required: false,
      default: "_self",
      options: ["_blank", "_self", "_parent", "_top"],
    },
    hoverUnderline: {
      type: Boolean,
      required: false,
      default: true,
    },
    wrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: Enum,
      required: false,
      default: "branded",
      options: [...themeStructure.color.font],
    },
    fontSize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", ...themeStructure.size.font],
    },
    breakWord: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myWidth() {
      return this.width === "fill" ? "100%" : "fit-content";
    },
    myFlexGrow() {
      return this.width === "fill" ? "1" : "auto";
    },
    myColor() {
      return this.$getStyleValuePropertiesFromTheme("color.font")[this.color];
    },
    myHoverUnderline() {
      return this.hoverUnderline ? "underline" : "none";
    },
    getFontSize() {
      if (this.fontSize !== "none") {
        return this.$getStyleValuePropertiesFromTheme("size.font")[this.fontSize];
      }

      return this.$getStyles[`${this.variant}FontSize`];
    },
    myWhiteSpace() {
      return this.wrap ? "normal" : "nowrap";
    },
    myWordBreak() {
      return this.breakWord ? "anywhere" : "normal";
    },
  },
  styleGuide: () => ({
    fontFamily: { "font.typeface": "standard" },
    fontWeight: { "font.weight": "standard" },
    backgroundColor: { "color.background": "light" },
    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    underline: { "font.decorations": "none" },
  }),
};
</script>

<style lang="scss" scoped>
.text-link {
  display: flex;
  width: v-bind(myWidth);
  flex-grow: v-bind(myFlexGrow);
  color: v-bind(myColor);
  font-size: v-bind(getFontSize);
  font-weight: v-bind('$getStyles.fontWeight');
  font-family: v-bind('$getStyles.fontFamily');
  text-decoration: v-bind('$getStyles.underline');
  cursor: pointer;
  white-space: v-bind(myWhiteSpace);
  overflow-wrap: v-bind(myWordBreak);

  &:hover {
    text-decoration: v-bind(myHoverUnderline);
  }
}
</style>
