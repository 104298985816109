<template>
  <iCode v-if="selfClosing">
    <span class="bracket">&lt;</span><span class="tag">{{ tag }}</span>
    <br>
    <template v-for="(propValue, propName) in $attrs" :key="propName">
      &nbsp;&nbsp;
      <span class="prop-name">{{ propName }}</span>
      <span class="equals">=</span>
      <span class="prop-value">"{{ propValue }}"</span>
      <br>
    </template>
    <span class="bracket">/&gt;</span>
  </iCode>
</template>

<script>
export default {
  name: "iCodeTag",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      required: true,
    },
    selfClosing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
