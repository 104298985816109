<template>
  <Transition name="modal-fade">
    <div
      v-if="visible"
      ref="modal"
      class="modal-wrapper"
      @click="closeModal"
    >
      <div class="modal" @click.stop>
        <iColumn
          gap="none"
          wrap="nowrap"
          width="fill"
          height="fill"
        >
          <template v-if="showHeader">
            <iRow>
              <iColumn gap="none">
                <iRow
                  class="modal-header"
                  gap="none"
                  vertical-align="middle"
                  wrap="nowrap"
                >
                  <slot name="header">
                    <iColumn
                      v-if="iconPosition === 'left'"
                      width="hug"
                    >
                      <iIcon icon="close" @click="closeModal" />
                    </iColumn>
                    <iColumn>
                      <iRow :align="headerTitlePosition">
                        <iSpace v-if="iconPosition === 'right'" :width="15" />
                        <iHeading :align="headerTitlePosition">
                          {{ title }}
                        </iHeading>
                        <iSpace v-if="iconPosition === 'left'" :width="15" />
                      </iRow>
                    </iColumn>
                    <iColumn
                      v-if="iconPosition === 'right'"
                      width="hug"
                    >
                      <iIcon icon="close" @click="closeModal" />
                    </iColumn>
                  </slot>
                </iRow>

                <iDivider />
              </iColumn>
            </iRow>
          </template>

          <iRow
            :width="width"
            class="modal-body"
            height="fill"
            :max-width="maxWidth"
          >
            <slot v-if="$slots.body" name="body" />
            <slot v-else />
          </iRow>

          <iRow>
            <iColumn gap="none">
              <iDivider v-if="showFooterDivider" class="footer-divider" />

              <iRow
                v-if="showFooter && (showPrimaryActionButton || showSecondaryActionButton)"
                :align="footerActionsPosition"
                class="modal-footer"
              >
                <slot name="footer">
                  <iColumn v-if="showSecondaryActionButton" width="hug">
                    <iButton
                      :disabled="secondaryActionButtonDisabled"
                      :is-loading="secondaryActionButtonLoading"
                      loading-spinner-position="inside"
                      variant="secondary"
                      @click="triggerSecondaryAction"
                    >
                      {{ secondaryActionButtonLabel }}
                    </iButton>
                  </iColumn>
                  <iColumn v-if="showPrimaryActionButton" width="hug">
                    <iButton
                      :disabled="primaryActionButtonDisabled"
                      :is-loading="primaryActionButtonLoading"
                      loading-spinner-position="inside"
                      variant="primary"
                      @click="triggerPrimaryAction"
                    >
                      {{ primaryActionButtonLabel }}
                    </iButton>
                  </iColumn>
                </slot>
              </iRow>
            </iColumn>
          </iRow>
        </iColumn>
      </div>
    </div>
  </Transition>
</template>

<script>
import iIcon from "@/components/icons/iIcon";
import Enum from "@/data-types/enum.js";

export default {
  name: "iModal",
  components: { iIcon },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    showFooterDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
    primaryActionButtonLabel: {
      type: String,
      required: false,
      default: "Submit",
    },
    secondaryActionButtonLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    headerTitlePosition: {
      type: Enum,
      required: false,
      default: "center",
      options: ["left", "center", "right"],
    },
    iconPosition: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "right"],
    },
    footerActionsPosition: {
      type: Enum,
      required: false,
      default: "right",
      options: ["left", "center", "right"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: 900,
      options: ["fill", "hug"],
    },
    maxWidth: {
      type: [Number, Enum],
      required: false,
      default: "unset",
      options: ["unset"],
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentHeight: {
      type: Number,
      required: false,
      default: 70,
    },
    closeOnBackdrop: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeOnEscape: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeOnPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSecondary: {
      type: Boolean,
      required: false,
      default: true,
    },
    primaryActionButtonLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    primaryActionButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondaryActionButtonLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondaryActionButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPrimaryActionButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSecondaryActionButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  styleGuide: () => ({
    wrapperPadding: { "size.spacing": "extraLarge" },
    modalBorderRadius: { "size.borderRadius": "large" },

    headerPaddingTop: { "size.spacing": "extraLarge" },
    headerPaddingBottom: { "size.spacing": "large" },
    headerPaddingLeft: { "size.spacing": "extraLarge" },
    headerPaddingRight: { "size.spacing": "extraLarge" },

    contentPaddingTop: { "size.spacing": "large" },
    contentPaddingBottom: { "size.spacing": "large" },
    contentPaddingLeft: { "size.spacing": "extraLarge" },
    contentPaddingRight: { "size.spacing": "extraLarge" },

    contentBackgroundColor: { "color.background": "paper" },
    contentDividerTopThickness: { "size.border": "thick" },
    contentDividerBottomThickness: { "size.border": "thick" },
    contentDividerTopColor: { "color.border": "dark" },
    contentDividerBottomColor: { "color.border": "dark" },

    footerPaddingTop: { "size.spacing": "standard" },
    footerPaddingBottom: { "size.spacing": "large" },
    footerPaddingLeft: { "size.spacing": "extraLarge" },
    footerPaddingRight: { "size.spacing": "extraLarge" },
  }),
  emits: ["close", "click:primary", "click:secondary"],
  computed: {
    myWidth() {
      if (this.width === "fill") {
        return "100%";
      }
      if (this.width === "hug") {
        return "fit-content";
      }

      return `${this.width}px`;
    },
    myMaxWidth() {
      if (typeof this.maxWidth === "number") {
        return `${this.maxWidth}px`;
      }
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }
      return "fit-content";
    },
    myContentHeight() {
      if (this.scrollable) {
        return `${this.contentHeight}vh`;
      }
      return "auto";
    },
    myModalBodyOverflow() {
      if (this.scrollable) {
        return "auto";
      }
      return "visible";
    },
  },
  created() {
    document.addEventListener("keydown", this.escapeHandler);
  },
  unmounted() {
    document.removeEventListener("keydown", this.escapeHandler);
  },
  methods: {
    triggerPrimaryAction() {
      this.$emit("click:primary");
      if (this.closeOnPrimary) {
        this.closeModal();
      }
    },
    triggerSecondaryAction() {
      this.$emit("click:secondary");
      if (this.closeOnSecondary) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    escapeCloseModal() {
      if (this.closeOnEscape) {
        this.closeModal();
      }
    },
    escapeHandler(event) {
      if (event.key === "Escape" && this.visible) {
        this.escapeCloseModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/external-styles/breakpoint-mixin";

.modal {
  &-wrapper {
    display: grid;
    place-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 25%);
    backdrop-filter: blur(4px);
    padding: v-bind('$getStyles.wrapperPadding');
  }

  overflow: visible;
  background-color: v-bind('$getStyles.contentBackgroundColor');
  box-shadow: 1px 1px 4px 0 #00000080;
  border-radius: v-bind("$getStyles.modalBorderRadius");
  max-width: v-bind(myMaxWidth);
  max-height: v-bind(myContentHeight);
  width: 100%;
  height: v-bind(myContentHeight);

  &-header {
    padding-top: v-bind('$getStyles.headerPaddingTop');
    padding-bottom: v-bind('$getStyles.headerPaddingBottom');
    padding-left: v-bind('$getStyles.headerPaddingLeft');
    padding-right: v-bind('$getStyles.headerPaddingRight');
  }

  &-body {
    padding-top: v-bind('$getStyles.contentPaddingTop');
    padding-left: v-bind('$getStyles.contentPaddingLeft');
    padding-right: v-bind('$getStyles.contentPaddingRight');
    padding-bottom: v-bind('$getStyles.contentPaddingBottom');
    max-height: v-bind(myContentHeight);
    overflow-y: v-bind(myModalBodyOverflow);
  }

  &-footer {
    padding-top: v-bind('$getStyles.footerPaddingTop');
    padding-bottom: v-bind('$getStyles.footerPaddingBottom');
    padding-left: v-bind('$getStyles.footerPaddingLeft');
    padding-right: v-bind('$getStyles.footerPaddingRight');
  }

  .footer-divider {
    padding-top: v-bind('$getStyles.footerPaddingTop');
  }
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: .25s ease all;
}
</style>
