<template>
  <div class="i-carousel-scroller">
    <iColumn
      v-for="(image, index) in images"
      :key="index"
      :width="imageWidth"
      :min-width="imageWidth"
      class="i-carousel-item"
      height="fill"
    >
      <iImageContainer
        :alt="image.alt"
        :src="image.src"
      />
    </iColumn>
  </div>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";

export default {
  name: "iCarousel",
  props: {
    images: {
      type: Array,
      required: true,
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    imageWidth: {
      type: Number,
      required: false,
      default: 400,
    },
    infinite: {
      type: Boolean,
      required: false,
      default: true,
    },
    gap: {
      type: Enum,
      required: false,
      default: "standard",
      options: [...themeStructure.size.spacing],
    },
  },
  computed: {
    myHeight() {
      if (typeof this.height === "number") {
        return `${this.height}px`;
      }

      return this.height === "fill" ? "100%" : "auto";
    },
    myWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }

      return this.width === "fill" ? "100%" : "auto";
    },
    myGap() {
      return this.$getStyleValuePropertiesFromTheme("size.spacing")[this.gap];
    },
  },
  mounted() {
    if (this.infinite) {
      let isScrolling = false;

      const observerOptions = {
        root: document.querySelector(".i-carousel-scroller"),
        threshold: 0.0, // Change to 0.0 to trigger when the element starts to leave
      };

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const carousel = document.querySelector(".i-carousel-scroller");
          const items = Array.from(carousel.children);

          items.forEach(item => item.id === "last-carousel-item" && item.removeAttribute("id"));
          items[items.length - 1].id = "last-carousel-item";

          if (entry.target.id === "last-carousel-item" && entry.isIntersecting && !isScrolling) {
            isScrolling = true;

            const clones = items.slice(0, this.images.length).map(item => item.cloneNode(true));
            clones.forEach(clone => carousel.appendChild(clone));
            clones.forEach(clone => observer.observe(clone));
            const childAnimations = this.animations.filter(animation => animation.target);
            this._initAnimationHandlers(document.querySelector(".i-carousel-scroller"), childAnimations);

            setTimeout(() => {
              isScrolling = false;
            }, 100);
          }
        });
      }, observerOptions);

      Array.from(document.querySelector(".i-carousel-scroller").children).forEach(item => observer.observe(item));
    }
  },
};
</script>

<style lang="scss" scoped>
.i-carousel-scroller {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  max-width: 100vw;

  height: v-bind("myHeight");
  width: v-bind("myWidth");
  gap: v-bind("myGap");
  scrollbar-width: none;
}

.i-carousel-item {
  overflow: hidden;
  &:hover {
    filter: blur(0)!important;
  }
}
</style>
