export const themeStructure = {
  font: {
    decorations: [
      "none",
      "underline",
    ],
    typeface: [
      "standard",
      "fancy",
      "heading",
      "accent",
    ],
    weight: [
      "standard",
      "dark",
      "light",
    ],
  },
  size: {
    lineHeight: [
      "standard",
    ],
    font: [
      "footnote",
      "verySmall",
      "small",
      "standard",
      "large",
      "extraLarge",
      "extraExtraLarge",
      "huge",
      "gigantic",
      "displayVerySmall",
      "displaySmall",
      "displayMedium",
      "displayLarge",
    ],
    spacing: [
      "none",
      "extraSmall",
      "small",
      "standard",
      "large",
      "extraLarge",
      "huge",
      "gigantic",
      "colossal",
    ],
    borderRadius: [
      "none",
      "small",
      "standard",
      "large",
    ],
    border: [
      "none",
      "thin",
      "standard",
      "thick",
    ],
    icon: [
      "small",
      "standard",
      "large",
    ],
    button: [
      "small",
      "standard",
      "large",
    ],
  },
  color: {
    font: [
      "standard",
      "light",
      "subtle",
      "accent",
      "error",
      "warning",
      "success",
      "branded",
      "striking",
      "disabled",
      "informational",
    ],
    border: [
      "none",
      "standard",
      "light",
      "dark",
      "veryDark",
      "striking",
      "disabled",
    ],
    background: [
      "paper",
      "pure",
      "standard",
      "light",
      "dark",
      "darker",
      "subtle",
      "informational",
      "strikingLight",
      "striking",
      "notice",
      "disabled",
    ],
    icon: [
      "standard",
      "light",
      "dark",
      "informational",
      "striking",
      "notice",
    ],
  },
};
