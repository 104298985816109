<template>
  <iRow>
    <iColumn>
      <iSubHeading>iColumn Default Props</iSubHeading>
      <iCodeTag
        tag="iColumn"
        overflow="scroll"
        height="hug"
        width="fill"
        align="left"
        vertical-align="top"
        gap="standard"
      />
    </iColumn>
  </iRow>
  <iRow v-for="(iteration, index) in iterations" :key="index">
    <iColumn>
      <iSubHeading>{{ iteration.title }}</iSubHeading>
      <iRow>
        <iCodeTag
          tag="iColumn"
          v-bind="iteration.leftProps"
          :self-closing="true"
        />
        <iCodeTag
          tag="iColumn"
          v-bind="iteration.rightProps"
          :self-closing="true"
        />
      </iRow>
      <iRow vertical-align="top">
        <iColumn v-bind="iteration.leftProps" class="border">
          <iButton>one</iButton>
          <iButton>two</iButton>
          <iButton>Many words that make a button wanting to be very wide</iButton>
          <iButton>thisbuttonhasaververververylongwordfortext</iButton>
        </iColumn>
        <iColumn v-bind="iteration.rightProps" class="border">
          <iButton>one</iButton>
          <iButton>two</iButton>
          <iButton>three</iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </iRow>
</template>

<script>
export default {
  name: "ColumnDocs",
  data() {
    return {
      iterations: [
        {
          leftProps: { width: 200 },
          rightProps: { width: 200 },
        },
        {
          leftProps: { width: 200 },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { width: "hug" },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { width: "hug" },
          rightProps: { width: "hug" },
        },
        {
          leftProps: { width: "fill" },
          rightProps: { width: "fill" },
        },
        {
          leftProps: { height: "fill" },
          rightProps: { height: "fill" },
        },
        {
          leftProps: { height: "hug" },
          rightProps: { height: "hug" },
        },
        {
          leftProps: { height: 300 },
          rightProps: { height: 200 },
        },
        {
          leftProps: { height: 300 },
          rightProps: { height: "fill" },
        },
        {
          leftProps: { height: 300, verticalAlign: "between" },
          rightProps: { height: "fill", verticalAlign: "around" },
        },
        {
          leftProps: { height: 300, verticalAlign: "evenly" },
          rightProps: { height: "fill", verticalAlign: "evenly" },
        },
        {
          leftProps: { height: "hug" },
          rightProps: { height: 400 },
        },
        {
          leftProps: { width: "fill", align: "center" },
          rightProps: { width: "fill", align: "right" },
        },
        {
          leftProps: { width: "fill", height: 300, align: "center", verticalAlign: "middle" },
          rightProps: { width: "fill", height: 300, align: "right", verticalAlign: "bottom" },
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
  .border {
    border: 1px solid red !important;
  }
</style>
