class BaseVclError extends Error {

  #data;

  constructor(message, data) {
    super(message);
    this.#data = data;
  }

  get data() {
    return this.#data;
  }
}

export default BaseVclError;
