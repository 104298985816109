<template>
  <iColumn :width="width" :height="height" class="native-post-content-container">
    <selection-bubble
      v-if="editor && !previewMode"
      :editor="editor"
      :should-show="shouldShow"
      :hide-image="true"
      @toggle-bold="toggleBold"
      @toggle-italic="toggleItalic"
      @toggle-link="toggleLink"
    />

    <editor-content class="editor-container" :editor="editor" />
  </iColumn>
</template>

<script>
import { Editor, EditorContent, isTextSelection } from "@tiptap/vue-3";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { MoveNodeUp } from "@/components/widgets/extensions/post-editor/move-up";
import { MoveNodeDown } from "@/components/widgets/extensions/post-editor/move-down";
import Enum from "@/data-types/enum";
import SelectionBubble from "@/components/widgets/SubComponents/post-editor/SelectionBubble";

export default {
  name: "iDynamicRichTextElement",
  components: { SelectionBubble, EditorContent },
  isUsableInDynamicElements: true,
  supportedDynamicComponentOptions: [
    "WidthHeightOptions",
  ],
  props: {
    previewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["fill", "hug"],
    },
  },
  emits: ["input:content"],
  data() {
    return {
      editor: null,
      updateBuffer: true,
    };
  },
  computed: {
    postContent() {
      return this.editor ? this.editor.getHTML() : "";
    },
  },
  watch: {
    postContent(newValue) {
      if (this.updateBuffer) {
        this.updateBuffer = false;
        return;
      }
      this.$emit("input:content", newValue);
    },
    modelValue(newValue) {
      this.editor.commands.setContent(newValue);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: "",
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: "Write something amazing..." }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: "https",
        }),
        MoveNodeUp,
        MoveNodeDown,
      ],
    });
    if (this.previewMode) {
      this.editor.setEditable(false);
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    shouldShow() {
      return ({ view, state, from, to }) => {
        const { doc, selection } = state;
        const { empty } = selection;
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        return true;
      };
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      let url;
      if (!previousUrl) {
        url = window.prompt("URL", previousUrl);
      }

      if (url === null) {
        return;
      }

      this.editor.chain().focus().extendMarkRange("link").toggleLink({ href: url, target: "_blank" }).run();
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
