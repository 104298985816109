<template>
  <div
    :class="{
      'i-divider': true,
      [`i-divider--orientation-${orientation}`]: true,
    }"
  />
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";

export default {
  name: "iDivider",
  props: {
    thickness: {
      type: Number,
      default: 1,
    },
    color: {
      type: Enum,
      default: "dark",
      options: [...themeStructure.color.border],
    },
    orientation: {
      type: Enum,
      default: "horizontal",
      options: ["horizontal", "vertical"],
    },
    borderStyle: {
      type: Enum,
      default: "solid",
      options: ["dashed", "dotted", "solid"],
    },
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },
    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    color: { "color.border": "dark" },
  }),
  computed: {
    myThickness() {
      return `${this.thickness}px`;
    },
    myColor() {
      return this.$getStyleValuePropertiesFromTheme("color.border")[this.color];
    },
  },
};
</script>

<style scoped lang="scss">
.i-divider {
  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-top: v-bind('$getStyles.paddingTop');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');

  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-top: v-bind('$getStyles.marginTop');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');

  border-color: v-bind(myColor);

  &--orientation {
    &-horizontal {
      border-top-style: v-bind(borderStyle);
      border-top-width: v-bind(myThickness);
      height: v-bind(myThickness);
      width: 100%;
    }

    &-vertical {
      border-left-style: v-bind(borderStyle);
      border-left-width: v-bind(myThickness);
      height: 100%;
      width: v-bind(myThickness);
    }
  }
}
</style>
