/* eslint-disable */

import { useStyleStore } from "@";
import PropDeclarationError from "@/errors/prop-declaration-error";

/**
 * DOCUMENTATION
 *  USAGE: v-tooltip="value"
 *    or
 *  v-tooltip="{ text: 'value', cursor: 'value', maxWidth: 'value', fontFamily: 'value', fontSize: 'value',
 *  fontColor: 'value', fontWeight: 'value', backgroundColor: 'value', zIndex: 'value' }"
 *
 *  value of directive: string | object
 *  text: string
 *  cursor: string
 *  maxWidth: number
 *  fontFamily: string
 *  fontSize: string
 *  fontColor: string
 *  fontWeight: string
 *  backgroundColor: string
 *  zIndex: number
 *
 *  Please note that the directive does not work as expected on the iIcon component. You will need to wrap an Icon
 *  component in a div or span and then use the directive on the parent component.
 *
 *  The text property is mandatory and must be a string. If you pass an object, the object must have a text property
 */

const OFFSET = 10;
const DEFAULT_Z_INDEX = 99999;
let tooltip;

function removeStyleElement() {
  const style = document.getElementById("v-tooltip-style");
  if (style) {
    style.remove();
  }
}

function removeTooltip() {
  document.querySelectorAll(".v-tooltip").forEach(el => {
    try {
      el.remove();
    } catch (e) {
      // ignore
    }
  });
  removeStyleElement();
}

function generateArrowCss(background, top = true, left = "50%", right = "") {
  removeStyleElement();

  const css = `
      .v-tooltip::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        ${top ? "margin-top: -3px;" : "margin-bottom: -10px;"}
        background-color: ${background};
        transform: rotate(45deg) translateX(-50%);
        ${top ? "top: 100%;" : "bottom: 100%;"}
        ${left ? `left: ${left};` : ""}
        ${right ? `right: ${right};` : ""}
      }
    `;
  const styleElement = document.createElement("style");
  styleElement.id = "v-tooltip-style";
  styleElement.innerHTML = css;
  document.head.appendChild(styleElement);
}

function createTooltipContainer() {
  let container = document.getElementById('v-tooltip-container');
  if (!container) {
    container = document.createElement('div');
    container.id = 'v-tooltip-container';
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.pointerEvents = 'none';
    container.style.zIndex = DEFAULT_Z_INDEX;
    document.body.appendChild(container);
  }
  return container;
}

function getScrollableParents(element) {
  const scrollableParents = [];

  function isScrollable(el) {
    const hasScrollableContent = el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
    const overflowYStyle = window.getComputedStyle(el).overflowY;
    const overflowXStyle = window.getComputedStyle(el).overflowX;
    const isScrollable = ['auto', 'scroll'].includes(overflowYStyle) ||
        ['auto', 'scroll'].includes(overflowXStyle);

    return hasScrollableContent && isScrollable;
  }

  function findScrollableParents(node) {
    if (!node || node === document.body) return;

    if (node !== document.documentElement) {
      if (isScrollable(node)) {
        scrollableParents.push(node);
      }
    }

    findScrollableParents(node.parentElement);
  }

  findScrollableParents(element);

  // Always add window for body/document scrolling
  scrollableParents.push(window);

  return scrollableParents;
}

function generateTooltip(configOptions, el) {
  const container = createTooltipContainer();

  tooltip = document.createElement("span");
  tooltip.className = "v-tooltip";
  tooltip.innerText = configOptions.text;

  tooltip.style.position = "fixed";
  tooltip.style.backgroundColor = configOptions.backgroundColor;
  tooltip.style.color = configOptions.fontColor;
  tooltip.style.fontFamily = configOptions.fontFamily;
  tooltip.style.fontSize = configOptions.fontSize;
  tooltip.style.fontWeight = configOptions.fontWeight;
  tooltip.style.zIndex = configOptions.zIndex || DEFAULT_Z_INDEX;
  tooltip.style.padding = "5px 10px";
  tooltip.style.borderRadius = "4px";
  tooltip.style.opacity = "0";
  tooltip.style.transition = "opacity 0.2s";
  tooltip.style.width = "max-content";
  tooltip.style.maxWidth = `${configOptions.maxWidth}px`;
  tooltip.style.pointerEvents = 'none';
  tooltip.style.wordBreak = 'break-word';

  container.appendChild(tooltip);
  el._tooltip = tooltip;

  let rect = el.getBoundingClientRect();
  let tooltipRect = tooltip.getBoundingClientRect();

  generateArrowCss(configOptions.backgroundColor);

  const clientWidth = document.documentElement.clientWidth;
  let isTop = configOptions.position === 'top' || rect.top - tooltipRect.height - OFFSET > 0;

  if (configOptions.position === 'bottom') {
    isTop = false;
  }

  if (isTop) {
    tooltip.style.top = `${rect.top - tooltipRect.height - OFFSET}px`;
  } else {
    tooltip.style.top = `${rect.bottom + OFFSET}px`;
    generateArrowCss(configOptions.backgroundColor, isTop);
  }

  tooltip.style.left = `${rect.left + (rect.width / 2) - (tooltipRect.width / 2)}px`;

  const tooltipOffsetLeft = rect.left + (rect.width / 2) - (tooltipRect.width / 2 + OFFSET);
  const tooltipOffsetRight = clientWidth - (rect.left + (rect.width / 2) + (tooltipRect.width / 2) + OFFSET);

  if (tooltipOffsetLeft < 0) {
    tooltip.style.left = `${OFFSET}px`;
    generateArrowCss(configOptions.backgroundColor, isTop, `${rect.left + (rect.width / 2) - OFFSET}px`);
  } else if (tooltipOffsetRight < 0) {
    tooltip.style.left = `${clientWidth - tooltipRect.width - OFFSET}px`;
    generateArrowCss(configOptions.backgroundColor, isTop, "", `${clientWidth - rect.right + (rect.width / 2) - OFFSET}px`);
  }

  tooltip.style.opacity = "1";
}

function generateDefaultConfig(styles, binding) {
  let value = binding.value;
  let position = binding.arg;
  const config = {
    text: "",
    cursor: "pointer",
    maxWidth: 200,
    zIndex: DEFAULT_Z_INDEX,
    fontFamily: styles.font.typeface.standard,
    fontSize: styles.size.font.standard,
    fontColor: styles.color.font.light,
    fontWeight: styles.font.weight.standard,
    backgroundColor: styles.color.background.dark,
    position
  };

  if (typeof value === "object") {
    Object.assign(config, value);
  } else {
    config.text = value;
  }

  if (!config.text) {
    throw new PropDeclarationError(`v-tooltip directive needs a "text" value, either pass it as a string directly or 
        as an object with the key "text"`, config);
  }

  return config;
}

function setup(el, binding) {
  const configOptions = generateDefaultConfig(useStyleStore().myTheme, binding);

  el.style.cursor = configOptions.cursor;
  el._showTooltip = () => generateTooltip(configOptions, el);
  el._hideTooltip = () => removeTooltip();

  // Get all scrollable parent containers
  el._scrollableParents = getScrollableParents(el);

  // Add scroll event listeners to all scrollable parents
  el._onScroll = () => removeTooltip();
  el._scrollableParents.forEach(parent => {
    parent.addEventListener("scroll", el._onScroll, { passive: true });
  });

  el.addEventListener("mouseenter", el._showTooltip);
  el.addEventListener("mouseleave", el._hideTooltip);
}

function cleanup(el) {
  removeTooltip();
  el.removeEventListener("mouseenter", el._showTooltip);
  el.removeEventListener("mouseleave", el._hideTooltip);

  // Clean up all scroll event listeners
  if (el._scrollableParents) {
    el._scrollableParents.forEach(parent => {
      parent.removeEventListener("scroll", el._onScroll, { passive: true });
    });
  }
}

export const vTooltip = {
  beforeMount(el, binding) {
    setup(el, binding);
  },
  updated(el, binding) {
    cleanup(el);
    setup(el, binding);
  },
  unmounted(el) {
    cleanup(el);
  },
};