import ToastBuilder from "@/builders/ToastBuilder";
import components from "@/registry";
import ToastWrapper from "@/components/toast/ToastWrapper";
import { snakeToTitle } from "@/helpers/text-format-helpers";
import { abbreviateNumber } from "@/helpers/text-format-helpers";
import { objectValidator } from "@/helpers/object-helpers";
import SocialShare from "@/helpers/social-share";
import mixins from "@/mixins";
import directives from "@/directives";
import { useToastStore } from "@/stores/toast-store";
import { useWindowStore } from "@/stores/window-store";
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants.js";
import { useStyleStore } from "@/stores/style-store";
import eventBus from "@/helpers/event-bus";
import * as MenuConstants from "@/constants/menu-constants";
import * as EffectConstants from "@/constants/effect-constants";
import {
  validateRegex,
  validateEmail,
  validateNumberInputMinZero,
  validateStringInput,
} from "@/helpers/validation-helpers";
import { getRelativeTimeString } from "@/helpers/date-helpers";
import { status as StatusEnum } from "@/enums/status-enums";
import DimensionsMixin from "@/mixins/dimensions-mixin";

export default {
  install(Vue) {
    const globalMixins = Object.values(mixins).filter(mixin => !mixin.library);
    if (globalMixins && Array.isArray(globalMixins)) {
      globalMixins.forEach(globalMixin => Vue.mixin(globalMixin.mixin));
    }

    for (const prop in components) {
      if (Object.prototype.hasOwnProperty.call(components, prop)) {
        const component = components[prop];

        // Add library-specific mixins
        const libraryMixins = Object.values(mixins).filter(mixin => mixin.library);
        if (libraryMixins && Array.isArray(libraryMixins)) {
          component.mixins = component.mixins || [];
          libraryMixins.forEach(libraryMixin => component.mixins.push(libraryMixin.mixin));
        }

        // Register the component
        Vue.component(component.name, component);
      }
    }

    // Register global directives
    for (const prop in directives) {
      if (Object.prototype.hasOwnProperty.call(directives, prop)) {
        const directive = directives[prop];
        Vue.directive(directive.name, directive.directive);
      }
    }
  },
};
export {
  Enum,
  themeStructure,
  snakeToTitle,
  ToastBuilder,
  ToastWrapper,
  useToastStore,
  useStyleStore,
  useWindowStore,
  eventBus,
  SocialShare,
  abbreviateNumber,
  objectValidator,
  MenuConstants,
  EffectConstants,
  StatusEnum,
  validateRegex,
  validateStringInput,
  validateNumberInputMinZero,
  validateEmail,
  getRelativeTimeString,
  DimensionsMixin,
};
