<template>
  <iColumn
    class="i-card"
    gap="none"
    :width="width"
    :height="height"
    :overflow="overflow"
    @click="emitClick"
  >
    <iRow
      v-if="hasHeaderSlot"
      class="i-card-header"
      vertical-align="middle"
      :height="headerHeight"
    >
      <slot name="header" />
    </iRow>

    <iRow class="i-card-body" vertical-align="middle">
      <slot name="body" />
    </iRow>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
import { themeStructure } from "@/constants/style-guide-constants.js";

export default {
  name: "iCard",
  props: {
    height: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    headerHeight: {
      type: [Number, Enum],
      required: false,
      default: "hug",
      options: ["hug", "fill"],
    },
    headerBackgroundColor: {
      type: [String, Enum],
      required: false,
      default: "none",
      options: ["none", ...themeStructure.color.background],
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    overflow: {
      type: Enum,
      required: false,
      default: "hidden",
      options: ["auto", "scroll", "hidden", "visible", "none"],
    },
  },
  emits: ["click"],
  computed: {
    myHeaderBackgroundColor() {
      if (this.headerBackgroundColor === "none") {
        return "transparent";
      }

      return this.$getStyleValuePropertiesFromTheme("color.background")[this.headerBackgroundColor];
    },
    myCursor() {
      if (this.clickable) {
        return "pointer";
      }
      return "inherit";
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
  methods: {
    emitClick(event) {
      if (this.clickable) {
        this.$emit("click", event);
      }
    },
  },
  styleGuide: () => ({
    borderWidth: { "size.border": "standard" },
    borderRadius: { "size.borderRadius": "large" },
    borderColor: { "color.border": "standard" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    paddingTop: { "size.spacing": "standard" },
    paddingBottom: { "size.spacing": "standard" },
    paddingLeft: { "size.spacing": "standard" },
    paddingRight: { "size.spacing": "standard" },

    headerPaddingTop: { "size.spacing": "none" },
    headerPaddingBottom: { "size.spacing": "standard" },
    headerPaddingLeft: { "size.spacing": "none" },
    headerPaddingRight: { "size.spacing": "none" },

    bodyPaddingTop: { "size.spacing": "none" },
    bodyPaddingBottom: { "size.spacing": "none" },
    bodyPaddingLeft: { "size.spacing": "none" },
    bodyPaddingRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
.i-card {
  border: v-bind("$getStyles.borderWidth") solid v-bind("$getStyles.borderColor") !important;
  border-radius: v-bind("$getStyles.borderRadius")!important;

    margin: v-bind("$getStyles.marginTop") v-bind("$getStyles.marginRight") v-bind("$getStyles.marginBottom") v-bind("$getStyles.marginLeft") !important;

    padding: v-bind("$getStyles.paddingTop") v-bind("$getStyles.paddingRight") v-bind("$getStyles.paddingBottom") v-bind("$getStyles.paddingLeft") !important;

    cursor: v-bind(myCursor);

  &-header {
    background-color: v-bind(myHeaderBackgroundColor);
    padding: v-bind("$getStyles.headerPaddingTop") v-bind("$getStyles.headerPaddingRight")
    v-bind("$getStyles.headerPaddingBottom") v-bind("$getStyles.headerPaddingLeft")!important;
  }

  &-body {
      padding: v-bind("$getStyles.bodyPaddingTop") v-bind("$getStyles.bodyPaddingRight")
      v-bind("$getStyles.bodyPaddingBottom") v-bind("$getStyles.bodyPaddingLeft")!important;
  }
}
</style>
