<template>
  <iSubHeading>Text Components</iSubHeading>
  <StoryBook
    :components="components"
    :prop-options="propOptions"
  />
</template>

<script>
import StoryBook from "@/components/storybook/StoryBook";
export default {
  name: "TextComponents",
  components: { StoryBook },
  data() {
    return {
      components: ["iHeading", "iSubHeading", "iParagraph"],
      propOptions: {
        variant: ["standard", "subtle", "accent", "error"],
        slot: ["Welcome to Bloglovin's Vue Component Library"],
      },
    };
  },
};
</script>
