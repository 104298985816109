<template>
  <iColumn class="page">
    <iRow>
      <iCollapsableCard title="options">
        <iRow v-for="(propValues, propName) in options" :key="`prop-checkboxes-${propName}`">
          <iColumn>
            {{ propName }}
            <iRow>
              <template
                v-for="val in propValues"
                :key="`prop-checkboxes-${propName}-${val}`"
              >
                <iCheckbox
                  v-if="val===true"
                  v-model="checkboxValues[propName][val]"
                  label="true"
                  :disabled="propValues.length == 1"
                />
                <iCheckbox
                  v-else-if="val===false"
                  v-model="checkboxValues[propName][val]"
                  label="false"
                  :disabled="propValues.length == 1"
                />
                <iCheckbox
                  v-else
                  v-model="checkboxValues[propName][val]"
                  :label="String(val)"
                  :disabled="propValues.length == 1"
                />
              </template>
            </iRow>
          </iColumn>
        </iRow>
      </iCollapsableCard>
    </iRow>

    <iRow>
      <iCard v-for="permutation, index in visiblePermutations" :key="`permutation-${index}`">
        <template #body>
          <iColumn>
            <iCode>
              <iParagraph class="blue">
                &lt;<span class="green">{{ permutation.componentName }}</span>
              </iParagraph>
              <iParagraph v-for="(propValue, propName) in permutation" :key="`prop-summary-${propName}-${propValue}`">
                <template v-if="propName!='componentName' && propName!='slot'">
                  &nbsp;&nbsp;<span class="blue">{{ propName }}=</span><span class="orange">"{{ propValue }}"</span>
                </template>
              </iParagraph>
              <iParagraph v-if="permutation.slot">
                <span class="blue">&gt;</span>
                <br>&nbsp;&nbsp;<span class="yellow">{{ permutation.slot }}</span>
                <br>
                <span class="blue">&lt;/</span>
                <span class="green">{{ permutation.componentName }}</span>
                <span class="blue">&gt;</span>
              </iParagraph>
              <iParagraph v-else class="blue">
                /&gt;
              </iParagraph>
            </iCode>
            <iSpace :height="20" />
            <iRow align="center">
              <component
                :is="permutation.componentName"
                v-bind="permutation"
              >
                {{ permutation.slot }}
              </component>
            </iRow>
          </iColumn>
        </template>
      </iCard>
    </iRow>
  </iColumn>
</template>

<script>

import { toRaw } from "vue";
export default {
  name: "StoryBook",
  props: {
    propOptions: {
      type: Object,
      required: true,
    },
    components: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkboxValues: {},
      permutations: [],
      options: {},
    };
  },
  computed: {
    visiblePermutations() {
      return this.permutations.filter(permutation => {
        const x = this.shouldShowCard(permutation);
        return x;
      });
    },
  },
  created() {
    this.options = structuredClone(toRaw(this.propOptions));
    this.options.componentName = this.components;
    this.checkboxValues = this.checkAllOptions();
    this.permutations = this.getPerms(0, {});
  },
  methods: {
    checkAllOptions() {
      const checks = {};
      const propNames = Object.keys(this.options);
      propNames.forEach(propName => {
        checks[propName] = {};
        this.options[propName].forEach(variant => {
          checks[propName][variant] = true;
        });
      });
      return checks;
    },
    getPerms(depth, branch) {
      const thisDepthsPropName = Object.keys(this.options)[depth];
      const numProps = Object.keys(this.options).length;
      let response = [];
      if (depth == numProps - 1) {
        // leaf nodes
        this.options[thisDepthsPropName].forEach(propVal => {
          const next = structuredClone(branch);
          next[thisDepthsPropName] = propVal;
          response.push(next);
        });
        return response;
      } else {
        // branch nodes
        this.options[thisDepthsPropName].forEach(propVal => {
          const newBranch = Object.assign(branch, { [thisDepthsPropName]: propVal });
          const newResponse = this.getPerms(depth + 1, newBranch);
          response = response.concat(newResponse);
        });
        return response;
      }
    },
    shouldShowCard(permutation) {
      for (const propName in permutation) {
        const propVal = permutation[propName];
        const checkboxVal = this.checkboxValues[propName][propVal];
        if (!checkboxVal) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
    margin: 20px;
}
</style>
