<template>
  <div class="spinner-container">
    <span class="loading-spinner" />
  </div>
</template>

<script>
import { themeStructure } from "@/constants/style-guide-constants.js";
import Enum from "@/data-types/enum";
export default {
  name: "iLoadingSpinner",
  props: {
    size: {
      type: [Number, Enum],
      required: false,
      default: "standard",
      options: [...themeStructure.size.icon],
    },
    variant: {
      type: Enum,
      required: false,
      default: "standard",
      options: [...themeStructure.color.font],
    },
  },
  computed: {
    getSize() {
      if (typeof this.size === "number") {
        return `${this.size}px`;
      }

      return this.$getStyleValuePropertiesFromTheme("size.icon")[this.size];
    },
    myColor() {
      return this.$getStyleValuePropertiesFromTheme("color.font")[this.variant];
    },
  },
  styleGuide: () => ({
    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
.spinner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
    margin-top: v-bind('$getStyles.marginTop');
    margin-left: v-bind('$getStyles.marginLeft');
    margin-right: v-bind('$getStyles.marginRight');
    margin-bottom: v-bind('$getStyles.marginBottom');
    width: v-bind('getSize');
    height: v-bind('getSize');
}

.loading-spinner {
  animation: rotation 0.75s linear infinite;
  border: 5px solid v-bind(myColor);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  aspect-ratio: 1;
  align-items: center;
  width: 100%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
