<template>
  <iColumn>
    <iLink
      v-if="onClick"
      @click="onClick"
    >
      {{ text }}
    </iLink>
    <iLink
      v-else-if="url"
      :href="url"
      :target="target"
    >
      {{ text }}
    </iLink>
    <iText v-else>
      {{ text }}
    </iText>
    <iRow
      v-if="items.length > 0"
      class="submenu"
    >
      <iMenuNew
        :items="items"
      />
    </iRow>
  </iColumn>
</template>

<script>
import Enum from "@/data-types/enum";
export default {
  name: "iMenuItemNew",
  props: {
    target: {
      type: Enum,
      required: false,
      default: "_self",
      options: ["_blank", "_self", "_parent", "_top"],
    },
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
  .submenu {
    margin-left: 20px;
  }
</style>
