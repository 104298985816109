<template>
  <div v-click-outside="hideDropdown" :data-parent-id="internalId" class="i-dropdown">
    <div class="i-dropdown-trigger">
      <slot name="trigger" />
    </div>
    <Teleport v-if="teleportToBody && showDropdown" to="body">
      <div
        v-follow-parent="{
          parentId: internalId,
          onScroll(e){
            $emit('close', e);
          }
        }"
        :class="{
          'i-dropdown-content': true,
          [`i-dropdown--position-${position}`]: true,
        }"
      >
        <slot name="overlay" />
      </div>
    </Teleport>
    <div
      v-else-if="showDropdown"
      v-follow-parent="{
        parentId: internalId,
        onScroll(e){
          $emit('close', e);
        }
      }"
      :class="{
        'i-dropdown-content': true,
        [`i-dropdown--position-${position}`]: true,
      }"
    >
      <slot name="overlay" />
    </div>
  </div>
</template>

<script>
export default {
  name: "iDropdown",
  props: {
    showDropdown: {
      type: Boolean,
      required: false,
      default: true,
    },
    position: {
      type: String,
      required: false,
      validator(value) {
        return ["right", "left"].includes(value);
      },
      default: "left",
    },
    zIndex: {
      type: Number,
      required: false,
      default: 1,
    },
    teleportToBody: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["close"],
  styleGuide: () => ({
    backgroundColor: { "color.background": "paper" },
    borderColor: { "color.border": "dark" },
    borderWidth: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
  }),
  data() {
    return {
      internalId: this.uniqueId(),
    };
  },
  mounted() {
    this.$exposeStyleGuide("idropdown");
  },
  methods: {
    uniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    hideDropdown() {
      if (this.showDropdown) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.i-dropdown {
  position: relative;
  height: fit-content;

  &-trigger:hover {
    cursor: pointer;
  }

  &-content {
    position: absolute;
    z-index: var(--idropdown-zIndex);
    width: max-content;

    background-color: var(--idropdown-backgroundColor);

    border: var(--idropdown-borderWidth) var(--idropdown-borderColor) solid;
    border-radius: var(--idropdown-borderRadius);

    margin: var(--idropdown-marginTop) var(--idropdown-marginRight) var(--idropdown-marginBottom) var(--idropdown-marginLeft);
  }

  &--position-left {
    left: 0;
  }

  &--position-right {
    right: 0;
  }
}
</style>
