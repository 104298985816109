<template>
  <iRow class="i-text-area-wrapper" :width="width">
    <component :is="layoutDirection" gap="extraSmall" vertical-align="middle">
      <component :is="childComponent" v-if="label" width="hug">
        <label class="i-text-area-label" :for="textAreaId">
          <slot>
            {{ label }}
          </slot>
        </label>
      </component>

      <component :is="childComponent" class="i-text-area-container" vertical-align="middle">
        <textarea
          :id="textAreaId"
          :class="{
            'i-text-area': true,
            disabled,
          }"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :name="name"
          :rows="rows"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :required="required"
          :value="modelValue"
          @keyup.enter="emitEnter"
          @blur="emitBlur"
          @input="emitUpdate"
          @focus="emitFocus"
        />
        <iLoadingSpinner v-if="isLoading" class="i-text-area-spinner" />
      </component>
    </component>
  </iRow>
</template>

<script>
import Enum from "@/data-types/enum";

export default {
  name: "iTextArea",
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    labelPosition: {
      type: [String, Enum],
      required: false,
      default: "above",
      options: ["above", "left"],
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: [Number, Enum],
      required: false,
      default: 700,
      options: ["fill", "hug"],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    direction: {
      type: [String, Enum],
      required: false,
      default: "vertical",
      options: ["vertical", "horizontal"],
    },
    pattern: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    resize: {
      type: Enum,
      required: false,
      default: "none",
      options: ["none", "vertical", "horizontal"],
    },
    rows: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  emits: ["blur", "focus", "update:modelValue", "change", "enter"],
  data() {
    return {
      passwordVisible: false,
    };
  },
  computed: {
    layoutDirection() {
      if (this.labelPosition === "above") {
        return "iColumn";
      }
      return "iRow";
    },
    childComponent() {
      return this.labelPosition === "left" ? "iColumn" : "iRow";
    },
    flexDirection() {
      return this.direction === "horizontal" ? "row" : "column";
    },
    myPattern() {
      return this.pattern === "" ? null : this.pattern;
    },
    myWidth() {
      if (typeof this.width === "number") {
        return `${this.width}px`;
      }

      return this.width === "fill" ? "100%" : "auto";
    },
    myInputPaddingLeft() {
      return this.leftIcon ? `calc(${this.$getStyles.inputPaddingLeft} * 4)` : this.$getStyles.inputPaddingLeft;
    },
    myIconSize() {
      return this.$getStyles.iconSize;
    },
    spinnerPadding() {
      if (this.rightIcon) {
        return this.$getStyles.inputPaddingRight + this.myIconSize;
      }

      return this.$getStyles.inputPaddingRight;
    },
    myType() {
      return this.type === "password" && this.passwordVisible ? "text" : this.type;
    },
    textAreaId() {
      if (this.id) {
        return this.id;
      }

      let suffix = this.label || this.placeholder;
      suffix = suffix.replace(" ", "").toLowerCase();
      return `text-area-${suffix}`;
    },
  },
  styleGuide: () => ({
    labelFontSize: { "size.font": "standard" },
    labelFontWeight: { "font.weight": "standard" },
    labelTypeFace: { "font.typeface": "standard" },
    labelFontColor: { "color.font": "standard" },

    labelMarginTop: { "size.spacing": "none" },
    labelMarginBottom: { "size.spacing": "none" },
    labelMarginLeft: { "size.spacing": "none" },
    labelMarginRight: { "size.spacing": "none" },

    labelSpacing: { "size.spacing": "standard" },

    inputPaddingTop: { "size.spacing": "standard" },
    inputPaddingBottom: { "size.spacing": "standard" },
    inputPaddingLeft: { "size.spacing": "standard" },
    inputPaddingRight: { "size.spacing": "standard" },
    inputBackgroundColor: { "color.background": "subtle" },
    inputBorderWidth: { "size.border": "standard" },
    inputBorderColor: { "color.border": "dark" },
    inputBorderRadius: { "size.borderRadius": "large" },
    inputFontColor: { "color.font": "standard" },
    inputPlaceholderColor: { "color.font": "subtle" },
    inputFontSize: { "size.font": "standard" },
    lineHeight: { "size.lineHeight": "standard" },
    inputFontWeight: { "font.weight": "standard" },
    inputTypeFace: { "font.typeface": "standard" },
    inputBorderFocusColor: { "color.border": "dark" },
    inputBorderFocusWidth: { "size.border": "standard" },
    inputBorderFocusRadius: { "size.borderRadius": "large" },

    disabledInputBackgroundColor: { "color.background": "subtle" },
    disabledInputFontColor: { "color.font": "disabled" },
    disabledInputBorderColor: { "color.border": "disabled" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    borderWidth: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "standard" },
  }),
  methods: {
    emitUpdate(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("change", event.target.value);
    },
    emitBlur(event) {
      this.$emit("blur", event);
    },
    emitFocus(event) {
      this.$emit("focus", event);
    },
    emitEnter(event) {
      this.$emit("enter", event);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.i-text-area {
  resize: v-bind(resize);

  &-wrapper {
    gap: v-bind('$getStyles.labelSpacing');

    &.flex-box {
      margin-top: v-bind('$getStyles.marginTop');
      margin-bottom: v-bind('$getStyles.marginBottom');
      margin-left: v-bind('$getStyles.marginLeft');
      margin-right: v-bind('$getStyles.marginRight');
    }
  }

  &-label {
    width: v-bind(myWidth);
    color: v-bind('$getStyles.labelFontColor');
    font-size: v-bind('$getStyles.labelFontSize');
    font-weight: v-bind('$getStyles.labelFontWeight');
    font-family: v-bind('$getStyles.labelTypeFace');

    margin-top: v-bind('$getStyles.labelMarginTop');
    margin-bottom: v-bind('$getStyles.labelMarginBottom');
    margin-left: v-bind('$getStyles.labelMarginLeft');
    margin-right: v-bind('$getStyles.labelMarginRight');
  }

  &-spinner {
    position: absolute;
    right: v-bind(spinnerPadding);
  }

  &-container {
    position: relative;
  }

  border-style: solid;
  box-sizing: border-box;
  outline: none;
  width: v-bind(myWidth);
  color: v-bind('$getStyles.inputFontColor');
  background-color: v-bind('$getStyles.inputBackgroundColor');
  border-width: v-bind('$getStyles.inputBorderWidth');
  border-radius: v-bind('$getStyles.inputBorderRadius');
  border-color: v-bind('$getStyles.inputBorderColor');
  font-size: v-bind('$getStyles.inputFontSize');
  line-height: v-bind('$getStyles.lineHeight');
  font-weight: v-bind('$getStyles.inputFontWeight');
  font-family: v-bind('$getStyles.inputTypeFace');
  padding-top: v-bind('$getStyles.inputPaddingTop');
  padding-bottom: v-bind('$getStyles.inputPaddingBottom');
  padding-left: v-bind(myInputPaddingLeft);
  padding-right: v-bind('$getStyles.inputPaddingRight');

  &.disabled {
    background-color: v-bind('$getStyles.disabledInputBackgroundColor');
    color: v-bind('$getStyles.disabledInputFontColor');
    border-color: v-bind('$getStyles.disabledInputBorderColor');
  }

  ::placeholder {
    color: v-bind('$getStyles.inputPlaceholderColor');
  }
}

textarea:focus {
  border-color: v-bind('$getStyles.inputBorderFocusColor');
  border-width: v-bind('$getStyles.inputBorderFocusWidth');
  border-radius: v-bind('$getStyles.inputBorderFocusRadius');
}
</style>
