import numeral from "numeral";
/**
 * Converts snake_case to TitleCase.
 * Optionally can convert to Title Case (with spaces between words)
 * @param {string} string
 * @param {boolean} spaceBetweenWords
 */
export const snakeToTitle = (string, spaceBetweenWords = false) => {
  return string.split("_")
      .map(substr => substr.charAt(0)
          .toUpperCase() +
        substr.slice(1))
      .join(spaceBetweenWords ? " " : "");
};

export function abbreviateNumber(value) {
  return numeral(value).format("0,00a");
}
